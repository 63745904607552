import { MessageBarType } from '@fluentui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { hideLoader, showLoader } from '../../actions/loaderAction';
import Confirm from '../../components/Confirm';
import NotificationBar from '../../components/NotificationBar';
import Page from '../../components/Page';
import useNotification from '../../hooks/useNotification';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import {
	createUsers,
	deleteUsers,
	getRoles,
	getUsers,
	updateRoles
} from '../../services/users';
import { isNullOrWhitespace } from '../../utils';
import ActionBar from './components/ActionBar';
import AddForm from './components/AddForm';
import TableForm from './components/TableForm';
import { msalConfig } from '../../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

import { loadUserUpstream } from '../../actions/upstreamAction';

function UserRoles() {
	const dispatch = useDispatch();
	const { notify } = useNotification();
	const [openAddUser, setOpenAddUser] = useState(false);
	const [data, setData] = useState([]);
	const [dataCount, setDataCount] = useState([]);
	const [announce, setAnnounce] = useState([]);
	const [allUsers, setAllusers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deletedData, setDeletedData] = useState(null);
	const [updateOpen, setUpdateOpen] = useState(false);
	const [updatedData, setUpdatedData] = useState(null);
	const [account, setAccount] = useState('');
	useEffect(() => {
		document.title="Invoice print tool-User roles"
		getRole();

		const msalInstance = new PublicClientApplication(msalConfig);
		setAccount(msalInstance.getAllAccounts()[0].username);
	}, []);

	const user = useSelector((state) => state.user);
	const subsDimensionID = user.user?.userList?.subsDimensionID;

	const getRole = async () => {
		let resp = await getRoles();
		setRoles(resp.data);
	};

	const getData = async () => {
		dispatch(showLoader());
		await Promise.all([getUsers(subsDimensionID), getRoles()])
			.then((resp) => {
				resp[0].data.map((x) => {
					x.userRoles.map((y) => {
						y.roleID === 1 && (y.upstreamName = 'SAP');
					});
				});
				const userData = resp[0].data.map((x) => {
					return {
						...x.userList,
						roles: x.userRoles
					};
				});
				setData(userData);
				setAllusers(userData);
				setRoles(resp[1].data);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	const deleteData = useCallback(
		(deletedData) => {
			dispatch(showLoader());
			const temp = _.cloneDeep(data);
			deleteUsers({
				userID: deletedData.userID,
				subsDimensionID: deletedData.subsDimensionID,
				userName: deletedData.userAlias
			})
				.then(() => {
					setData(temp.filter((x) => x.userID !== deletedData.userID));
					setDeleteOpen(false);
					notify({
						messageType: MessageBarType.success,
						message: 'User deleted successfully.'
					});
					setTimeout(() =>{
						setAnnounce('User deleted successfully.');
					},2000)
					
					setDataCount(temp.length + " records found");
				})
				.finally(() => {
					dispatch(hideLoader());
				});
		},
		[data]
	);

	const updateData = useCallback(
		(item) => {
			dispatch(showLoader());
			item.roles.forEach((x) => {
				x.roleName = roles.find((r) => r.id === x.roleID)?.name;
			});
			item.roles.forEach((x) => {
				x = _.omit(x, [
					'roleName',
					'roleDescription',
					'isActive',
					'upStreamId'
				]);
			});
			updateRoles({
				subsDimensionID: subsDimensionID,
				userName: account,
				roles: item.roles,
				userID: item.userID
			})
				.then((res) => {
					if (res.data < 0 && res.data != -5) {
						notify({
							messageType: MessageBarType.error,
							message: 'Error occurred.'
						});
						setAnnounce('Error occurred.');
					} else {
						notify({
							messageType: MessageBarType.success,
							message: 'User updated successfully.'
						});
						setAnnounce('User updated successfully.');
					}
					getData();
				})
				.catch((error) => {
					notify({
						messageType: MessageBarType.error,
						message: 'Error occurred.'
					});
					setAnnounce('Error occurred.');
				})
				.finally(() => {
					dispatch(hideLoader());
					setOpenAddUser(false);
					// dispatch(loadUserUpstream());
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				});
		},
		[data]
	);

	const saveUser = useCallback(
		(item) => {
			dispatch(showLoader());
			item.roles.forEach((x) => {
				x.roleName = roles.find((r) => r.id === x.roleID)?.name;
			});
			createUsers({
				subsDimensionID: subsDimensionID,
				userName: account,
				roles: item.roles,
				email: item.email,
				firstName: item.firstName,
				lastName: item.lastName
			})
				.then((res) => {
					if (res.data < 0 && res.data != -5) {
						notify({
							messageType: MessageBarType.error,
							message: 'Error occurred.'
						});
						setAnnounce('Error occurred.');
					} else if (res.data == -5) {
						notify({
							messageType: MessageBarType.error,
							message: 'User already exists'
						});
						setAnnounce('User already exists');
					} else {
						notify({
							messageType: MessageBarType.success,
							message: 'User added successfully.'
						});
						setAnnounce('User added successfully.');
					}
					getData();
				})
				.catch((error) => {
					notify({
						messageType: MessageBarType.error,
						message: 'Error occurred.'
					});
					setAnnounce('Error occurred.');
				})
				.finally(() => {
					dispatch(hideLoader());
					setOpenAddUser(false);
				});
		},
		[data]
	);

	useEffect(() => {
		getData();
	}, [subsDimensionID]);

	return (
		<Page title={'User Roles'}>
			<LiveAnnouncer>
				<LiveMessage message={dataCount} aria-live='polite' />
			</LiveAnnouncer>

			<LiveAnnouncer>
				<LiveMessage message={announce} aria-live='polite' />
			</LiveAnnouncer>
			<ActionBar
				onSearch={(val) => {
					if (isNullOrWhitespace(val)) {
						setData(allUsers);
					} else {
						const temp = allUsers.filter((x) =>
							x.name.toLowerCase().startsWith(val.toLowerCase())
						);
						setData(temp);
						console.log(temp.length);
						setDataCount(temp.length + " records found");
					}
				}}
				onAdd={() => {
					setOpenAddUser(true);
				}}
			/>
			<NotificationBar type={MessageBarType.success} />
			{data && data.length > 0 && (
				<TableForm
					roles={roles}
					data={data}
					onSave={(form) => {
						updatedUser(form);
					}}
					onDelete={(data) => {
						if (
							user?.user?.userRoles?.some((item) => item.roleName == 'Admin')
						) {
							setDeleteOpen(true);
							setDeletedData(data);
						} else {
							notify({
								messageType: MessageBarType.error,
								message: 'User not an admin.'
							});
						}
					}}
					onUpdate={(data) => {
						if (
							user?.user?.userRoles?.some((item) => item.roleName == 'Admin')
						) {
							setUpdateOpen(true);
							setUpdatedData(data);
						} else {
							notify({
								messageType: MessageBarType.error,
								message: 'User not an admin.'
							});
						}
					}}
					subsDimensionID={subsDimensionID}
				/>
			)}
			<AddForm
				roles={roles}
				open={openAddUser}
				onClose={() => {
					setOpenAddUser(false);
				}}
				onSave={(form) => {
					if (user?.user?.userRoles?.some((item) => item.roleName == 'Admin')) {
						saveUser(form);
					} else {
						setOpenAddUser(false);
						notify({
							messageType: MessageBarType.error,
							message: 'User not an admin.'
						});
					}
				}}
				subsDimensionID={subsDimensionID}
			/>
			{deleteOpen && (
				<Confirm
					message='Do you wish to delete user?'
					open={deleteOpen}
					onClose={(confirm) => {
						if (confirm) {
							deleteData(deletedData);
						} else {
							setDeleteOpen(false);
						}
					}}
				/>
			)}
			{updateOpen && (
				<Confirm
					message='Do you wish to update user?'
					open={updateOpen}
					onClose={(confirm) => {
						if (confirm) {
							updateData(updatedData);
							setUpdateOpen(false);
						} else {
							setUpdateOpen(false);
						}
					}}
				/>
			)}
		</Page>
	);
}

export default UserRoles;
