import { MessageBarType } from '@fluentui/react';
import React, { createContext, useCallback, useState } from 'react';

export const NotificationContext = createContext(null);
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [isGlobal, setGlobal] = useState(false);
  const [messageType, setMessageType] = useState(MessageBarType.success);

  const hide = useCallback(() => {
    setShow(false);
  }, []);

  const notify = useCallback((data) => {
    console.log(data)
    let { message, errors, global = false, type = MessageBarType.success } = data;
    if (errors) {
      message = errors.join('\r\n');
    }
    setMessageType(data.messageType);
    setGlobal(global);
    setMessage(message);
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 300000);
  }, []);

  return (
    <Provider value={{ message, isGlobal, messageType, show, hide, notify }}>{children}</Provider>
  );
};

export default NotificationProvider;
