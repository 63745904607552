/**
 * loader actions
 */

import { ACTION_TYPE } from "../constants";

/**
 * show loader
 *
 * @returns {Promise} loaded loader
 */
export const showLoader = () => ({
  type: ACTION_TYPE.SET_LOADER,
  payload: true,
});

/**
 * hide loader
 *
 * @returns {Promise} loaded loader
 */
export const hideLoader = () => ({
  type: ACTION_TYPE.SET_LOADER,
  payload: false,
});
