import React, { useEffect, useState, useMemo } from 'react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';

import { MessageBarType, MessageBar, ComboBox, Stack } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Panel } from '@fluentui/react/lib/Panel';
import { Icon } from '@fluentui/react/lib/Icon';
import { TextField } from '@fluentui/react/lib/TextField';
import {
	DefaultButton,
	PrimaryButton,
	IconButton
} from '@fluentui/react/lib/Button';

import useNotification from '../../hooks/useNotification';
import Page from '../../components/Page';
import Confirm from '../../components/Confirm';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { msalConfig } from '../../authConfig';
import {
	getCustomerName,
	getShipCodeByCustomerName,
	getCustomerData,
	deleteCustomerData,
	newSubBusinessType
} from '../../services/customer';
import {
	getBusinessSubType,
	getBusinessType,
	postCustomerData,
	deleteBusinessType,
	deleteBusinessSubType,
	updateCustomerDataTaiwan,
	updateCustomerData,
	updateSubBusinessType,
	newBusinessType,
	updateBusinessType
} from '../../services/customer';

import 'office-ui-fabric-core/dist/css/fabric.css';
import './styles.scss';
import { set } from 'lodash';

const stackTokens = { childrenGap: 30 };
const stackTokensButton = { childrenGap: 10 };
const horizontalAlignmentOptions = [
	{ key: 'start', text: 'Left' },
	{ key: 'center', text: 'Center' },
	{ key: 'end', text: 'Right' },
	{ key: 'space-around', text: 'Space around' },
	{ key: 'space-between', text: 'Space between' },
	{ key: 'space-evenly', text: 'Space evenly' }
];

function Customers(props) {
	const [currentCustomerTyped, setCurrentCustomerTyped] = useState(null);
	const [currentShipCodeTyped, setCurrentShipCodeTyped] = useState(null);
	const { notify } = useNotification();
	const [field1, setField1] = useState('-');
	const [shipCount, setShipCount] = useState(0);
	const [soldCount, setSoldCount] = useState(0);
	const [soldCountTaiwan, setSoldCountTaiwan] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [showAlertFail, setShowAlertFail] = useState(false);
	const [showAlertSuccess, setShowAlertSuccess] = useState(false);
	const [showAlertFailDelete, setShowAlertFailDelete] = useState(false);
	const [showAlertSuccessDelete, setShowAlertSuccessDelete] = useState(false);
	const [errorMessage, setErrorMessage] = useState('Error ocurred');
	const [successMessage, setSuccessMessage] = useState('Success');
	const [deleteCustomerMessage, setDeleteCustomerMessage] =
		useState('Error occured');

	const [isOpenNew, setIsOpenNew] = useState(false);
	const [isOpenBusiness, setIsOpenBusiness] = useState(false);
	const [isOpenBusinessNew, setIsOpenBusinessNew] = useState(false);
	const [shouldUpdateBsnsType, setShouldUpdateBsnsType] = useState(false);
	const [shouldUpdateBsnsSubType, setShouldUpdateBsnsSubType] = useState(false);
	const [businessTypeAndSubTypeSuccess, setBusinessTypeAndSubTypeSuccess] =
		useState(false);
	const [businessTypeAndSubTypeError, setBusinessTypeAndSubTypeError] =
		useState(false);
	const [editParams, setEditParams] = useState({});
	const [editParamsBusiness, setEditParamsBusiness] = useState({});
	const [showData, setShowData] = useState(false);
	const [inputList, setInputList] = useState([{ Address: '' }]);
	const [inputList3, setInputList3] = useState([{ Address: '' }]);
	const [inputListTaiwan, setInputListTaiwan] = useState([{ Address: '' }]);
	const [inputList2, setInputList2] = useState([
		{ Address: '' },
		{ Address: '' },
		{ Address: '' },
		{ Address: '' },
		{ Address: '' }
	]);
	const [customerNameDropdownOptions, setCustomerNameDropdownOptions] =
		useState([]);
	const [
		customerNameDropdownOptionsCurrent,
		setCustomerNameDropdownOptionsCurrent
	] = useState([]);
	const [shipCodeDropdownOptions, setShipCodeDropdownOptions] = useState([]);
	const [shipCodeDropdownOptionsCurrent, setShipCodeDropdownOptionsCurrent] =
		useState([]);
	const [customerName, setCustomerName] = useState('');
	const [customerCode, setCustomerCode] = useState('');
	const [shipToCodeSelectedKey, setShipToCodeSelectedKey] = useState(null);
	const [customerNameSelectedKey, setCustomerNameSelectedKey] = useState(null);
	const [subTypeSelectedKey, setSubTypeSelectedKey] = useState(null);
	const [bsnsTypeSelectedKey, setBsnsTypeSelectedKey] = useState(null);
	const [shipCode, setShipCode] = useState('');
	const [subTypeNewCode, setSubTypeNewCode] = useState('');
	const [subTypeNewDescription, setSubTypeNewDescription] = useState('');
	const [typeNewCode, setTypeNewCode] = useState('');
	const [typeNewDescription, setTypeNewDescription] = useState('');
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteOpenType, setDeleteOpenType] = useState(false);
	const [deleteOpenSubType, setDeleteOpenSubType] = useState(false);
	const [saveOpen, setSaveOpen] = useState(false);
	const [deleteOpenTaiwan, setDeleteOpenTaiwan] = useState(false);
	const [businessTypeOption, setBusinessTypeOption] = useState([]);
	const [businessSubTypeOption, setBusinessSubTypeOption] = useState([]);
	const [account, setAccount] = useState('');
	const [customerDataValues, setCustomerDataValues] = useState({
		CustomerCode: '',
		ShipToCode: '',
		SoldToName: '',
		ShipToName: '',
		SoldToAddress1: '',
		SoldToAddress2: '',
		SoldToAddress3: '',
		SoldToAddress4: '',
		ShipToAddress1: '',
		ShipToAddress2: '',
		ShipToAddress3: '',
		ShipToAddress4: '',
		ContactName: '',
		BusinessRegisterNumber: '',
		SalespersonCode: '',
		TermsCode: '',
		TaxCode: '',
		BusinessType: '',
		BusinessSubType: ''
	});
	const [customerDataValuesTaiwan, setCustomerDataValuesTaiwan] = useState({
		customerCode: '',
		shipToCode: '',
		shortName: '',
		addressName: '',
		address1: '',
		address2: '',
		address3: '',
		address4: '',
		addressSort1: '',
		attentionName: '',
		attentionPhone: '',
		contactPhone: '',
		contactName: '',
		termsCode: '',
		taxCode: '',
		comment: ''
	});
	const [addCustomerMessage, setAddCustomerMessage] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const [message, setMessage] = useState('');

	const user = useSelector((state) => state.user);
	const subsDimensionID = user.user?.userList?.subsDimensionID;
	const history = useHistory();

	useEffect(() => {
		document.title="Invoice print tool-Customers"
		setDropDownValues();
		const msalInstance = new PublicClientApplication(msalConfig);
		setAccount(msalInstance.getAllAccounts()[0].username);
		// setBusinessTypeOptions()
		// setBusinessSubTypeOptions()
	}, [subsDimensionID]);

	const setBusinessSubTypeOptions = async () => {
		const resp = await getBusinessSubType(subsDimensionID);
		let x = 1;
		let businessSubTypeList = [];
		resp.data.forEach((element) => {
			let businessSubTypeRow = {
				key: x,
				text: element.businessSubTypeCode,
				desc: element.businessSubTypeDescription
			};
			businessSubTypeList.push(businessSubTypeRow);
			x = x + 1;
		});
		setBusinessSubTypeOption(businessSubTypeList);
	};

	const setBusinessTypeOptions = async () => {
		const resp = await getBusinessType(subsDimensionID);
		let x = 1;
		let businessTypeList = [];
		resp.data.forEach((element) => {
			let businessTypeRow = {
				key: x,
				text: element.businessTypeCode,
				desc: element.businessTypeDescription
			};
			businessTypeList.push(businessTypeRow);
			x = x + 1;
		});
		setBusinessTypeOption(businessTypeList);
	};

	const setDropDownValues = async () => {
		const resp = await getCustomerName(subsDimensionID);
		let x = 1;
		let nameList = [];
		const data = resp.data.map((i, index) => ({
			key: index,
			text: `${i.customerName}-${i.customerCode}`,
			customerName: `${i.customerName}`,
			customerCode: `${i.customerCode}`
		}));

		const uniqueNames = data.reduce((acc, current) => {
			const x = acc.find((item) => item.text === current.text);
			if (!x) {
				return acc.concat([current]);
			} else {
				return acc;
			}
		}, []);

		setCustomerNameDropdownOptions(data);
		setCustomerNameDropdownOptionsCurrent(data);
	};

	const getCustomerDataMain = async () => {
		setShipCount(0);
		const resp = await getCustomerData(customerCode, shipCode, subsDimensionID);
		if (subsDimensionID === 1) {
			let k = resp.data;
			let y = 0;
			let soldCount = 0;
			let list3 = [
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' }
			];
			for (var i in k) {
				let key = 'soldToAddress' + (y + 1);
				if (i === key) {
					list3[y]['Address'] = k[i];
					y = y + 1;
					soldCount = soldCount + 1;
				}
			}
			setSoldCountTaiwan(soldCount);
			if (list3[0]['Address']) {
				for (var i in list3) {
					if (list3[i]['Address']?.length < 1) {
						list3.splice(i, 1);
					}
				}
				list3.splice(-1, 1);
				list3.splice(-1, 1);

				setInputListTaiwan(list3);
			} else {
				let list3 = [{ Address: '' }];
				setInputListTaiwan(list3);
			}
			setCustomerDataValuesTaiwan({
				...customerDataValuesTaiwan,
				customerCode: k.customerCode,
				shipToCode: k.shipToCode,
				shortName: k.short_Name,
				addressName: k.contactAddressName,
				address1: k.soldToAddress1,
				address2: k.soldToAddress2,
				address3: k.soldToAddress3,
				address4: k.soldToAddress4,
				addressSort1: k.addressSort,
				attentionName: k.attention_Name,
				attentionPhone: k.attention_Phone,
				contactPhone: k.contact_Phone,
				contactName: k.contactName,
				termsCode: k.termsCode,
				taxCode: k.taxCode,
				comment: k.comment
			});
			setShowData(true);
			setMessage('Loading, Customer found');
		}
		if (subsDimensionID === 2) {
			const k = resp.data;
			let x = 0;
			let shpCount = 0;
			let list = [
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' }
			];
			for (var i in k) {
				let key = 'shipToAddress' + (x + 1);
				if (i === key) {
					list[x]['Address'] = k[i];
					x = x + 1;
					shpCount = shpCount + 1;
				}
			}
			setShipCount(shpCount);
			if (list[0]['Address']) {
				for (var i in list) {
					if (list[i]['Address'] != null) {
						if (list[i]['Address']?.length < 1) {
							list.splice(i, 1);
						}
					}
				}
				list.splice(-1, 1);
				setInputList2(list);
			} else {
				let list = [{ Address: '' }];
				setInputList2(list);
			}

			let y = 0;
			let soldCount = 0;
			let list2 = [
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' },
				{ Address: '' }
			];
			for (var i in k) {
				let key = 'soldToAddress' + (y + 1);
				if (i === key) {
					list2[y]['Address'] = k[i];
					y = y + 1;
					soldCount = soldCount + 1;
				}
			}
			setSoldCount(soldCount);
			if (list2[0]['Address']) {
				for (var i in list) {
					if (list2[i]['Address'] != null) {
						if (list2[i]['Address'].length < 1) {
							list2.splice(i, 1);
						}
					}
				}
				list2.splice(-1, 1);

				setInputList(list2);
			} else {
				let list2 = [{ Address: '' }];
				setInputList(list2);
			}

			setCustomerDataValues({
				...customerDataValues,
				CustomerCode: k.customerCode,
				ShipToCode: k.shipToCode,
				SoldToName: k.soldToName,
				ShipToName: k.shipToName,
				SoldToAddress1: k.soldToAddress1,
				SoldToAddress2: k.soldToAddress2,
				SoldToAddress3: k.soldToAddress3,
				SoldToAddress4: k.soldToAddress4,
				ShipToAddress1: k.shipToAddress1,
				ShipToAddress2: k.shipToAddress2,
				ShipToAddress3: k.shipToAddress3,
				ShipToAddress4: k.shipToAddress4,
				ContactName: k.contactName,
				BusinessRegisterNumber: k.businessRegisterNo,
				SalespersonCode: k.salesPersonCode,
				TermsCode: k.termsCode,
				TaxCode: k.taxCode,
				BusinessType: k.businessType,
				BusinessSubType: k.businessSubType
			});
			setShowData(true);
			setMessage('Loading, Customer found');
		}
	};

	const onClickSubmit = () => {
		if (subsDimensionID === 1) {
		} else {
			if (businessTypeOption.length < 1 || shouldUpdateBsnsType) {
				setBusinessTypeOptions();
			}
			if (businessSubTypeOption.length < 1 || shouldUpdateBsnsSubType) {
				setBusinessSubTypeOptions();
			}
		}

		getCustomerDataMain();
	};
	const onClickClear = () => {
		setMessage('Data cleared');
		setShowData(false);
		setShipCode('');
		setShipToCodeSelectedKey(null);
		setCustomerNameSelectedKey(null);
		setCustomerName('');
	};

	const onClickRedirect = () => {
		setAddCustomerMessage('Add Customer Data');
		setTimeout(() => {
			history.push('/addcustomers');
		}, 100);
	};
	const dismissPanel = () => {
		setIsOpen(false);
	};
	const dismissPanelBusiness = () => {
		setIsOpenBusiness(false);
	};
	const dismissPanelNew = () => {
		setSubTypeNewCode('');
		setSubTypeNewDescription('');
		setIsOpenNew(false);
	};
	const dismissPanelBusinessNew = () => {
		setTypeNewCode('');
		setTypeNewDescription('');
		setIsOpenBusinessNew(false);
	};

	const onChangeFirstTextFieldValue = (event, item) => {
		setField1(item);
	};

	const onChangeContactName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ContactName: value });
	};
	const onChangeShipToAddress1 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ShipToAddress1: value });
	};
	const onChangeShipToAddress2 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ShipToAddress2: value });
	};
	const onChangeShipToAddress3 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ShipToAddress3: value });
	};
	const onChangeSoldToAddress1 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, SoldToAddress1: value });
	};
	const onChangeSoldToAddress2 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, SoldToAddress2: value });
	};
	const onChangeSoldToAddress3 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, SoldToAddress3: value });
	};
	const onChangeSalesPersonCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, SalespersonCode: value });
	};
	const onChangeShipToCodeInForm = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ShipToCode: value });
	};
	const onChangeTaxCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, TaxCode: value });
	};
	const onChangeTermsCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, TermsCode: value });
	};
	const onChangeShipToName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, ShipToName: value });
	};
	const onChangeBusinessRegisterNumber = (event, value) => {
		setCustomerDataValues({
			...customerDataValues,
			BusinessRegisterNumber: value
		});
	};

	const onChangeAddress = (i, e) => {
		const list = [...inputList];
		list[i]['Address'] = e.target.value;
		setInputList(list);
	};

	const onChangeAddress2 = (i, e) => {
		const list = [...inputList2];
		list[i]['Address'] = e.target.value;
		setInputList2(list);
	};

	const onChangeCustomerName = async (event, item) => {
		if (item !== '' && item !== undefined && item !== null) {
			setCustomerName(item.customerName);
			setCustomerCode(item.customerCode);
			setCustomerNameSelectedKey(item.key);
			setShipToCodeSelectedKey(null);
			setShipCode('');
			const resp = await getShipCodeByCustomerName(item.customerName);
			const k = resp;
			let x = 1;
			let codeList = [];
			if (resp.data) {
				k.data.forEach((element) => {
					let codeRow = { key: x, text: element.shipToCode };
					codeList.push(codeRow);
					x = x + 1;
				});
				setShipCodeDropdownOptions(codeList);
				setShipCodeDropdownOptionsCurrent(codeList);
			}
		}
	};

	const onChangeShipToCode = (event, item) => {
		setShipCode(item.text);
		setShipToCodeSelectedKey(item.key);
	};

	const onChangeSoldToName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, SoldToName: value });
	};

	const onChangeCustomerCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, CustomerCode: value });
	};

	const handleRemoveClick = (index) => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};
	const handleRemoveClick2 = (index) => {
		const list = [...inputList2];
		list.splice(index, 1);
		setInputList2(list);
	};
	const handleAddClick = (index) => {
		setInputList([...inputList, { Address: '' }]);
	};
	const handleAddClick2 = (index) => {
		setInputList2([...inputList2, { Address: '' }]);
	};
	const onClickCancel = () => {
		// setMessage('Data cleared');
		setTimeout(() => {
			history.go(0);
		}, 100);
	};
	const onClickSave = () => {
		updateCustomerDataa(customerDataValues);
	};
	const updateCustomerDataa = async (k) => {
		const resp = await updateCustomerData(k, subsDimensionID, account);
		setSuccessMessage('Customer updated successfully');

		if (resp.data === 1) {
			setShowAlertSuccess(true);
			setAlertMessage('Customer updated successfully');
		} else {
			setShowAlertFail(true);
			setAlertMessage('Error occured while updating customer');
		}
		setTimeout(() => {
			setShowAlertSuccess(false);
			setShowAlertFail(false);
			setAlertMessage('');
		}, 7000);
	};
	const deleteCustomerDataa = async () => {
		if (subsDimensionID === 2) {
			const resp = await deleteCustomerData(
				subsDimensionID,
				customerDataValues.CustomerCode,
				customerDataValues.ShipToCode
			);
			setDeleteOpen(false);

			if (resp.data === 1) {
				setShowAlertSuccessDelete(true);
				setAlertMessage('Customer deleted successfully');
			} else {
				setShowAlertFailDelete(true);
				setAlertMessage(
					'Customer deletion is failed, because customer is associated with Invoice'
				);
			}
			setTimeout(() => {
				setShowAlertSuccessDelete(false);
				setShowAlertFailDelete(false);
				setAlertMessage('');
			}, 7000);
		}
		if (subsDimensionID === 1) {
			const resp = await deleteCustomerData(
				subsDimensionID,
				customerDataValuesTaiwan.customerCode,
				customerDataValuesTaiwan.shipToCode
			);

			setDeleteOpenTaiwan(false);

			if (resp.data === 1) {
				setShowAlertSuccessDelete(true);
				setAlertMessage('Customer deleted successfully');
			} else if (resp.data === -1) {
				setDeleteCustomerMessage(
					'Customer deletion is failed, because customer is associated with Invoice'
				);
				setShowAlertFailDelete(true);
				setAlertMessage(
					'Customer deletion is failed, because customer is associated with Invoice'
				);
			} else {
				setDeleteCustomerMessage('Error occured while deleting customer');
				setShowAlertFailDelete(true);
				setAlertMessage('Error occured while deleting customer');
			}

			setTimeout(() => {
				setShowAlertSuccessDelete(false);
				setShowAlertFailDelete(false);
				setAlertMessage('');
			}, 7000);
		}
	};
	const onClickDelete = () => {
		setDeleteOpen(true);
	};
	const onClickRestore = () => {
		getCustomerDataMain();
	};
	const onChangeBusinessType = (event, value) => {
		setEditParamsBusiness(value);
		setCustomerDataValues({ ...customerDataValues, BusinessType: value.text });
	};
	const onChangeBusinessSubType = (event, value) => {
		setEditParams(value);
		setSubTypeSelectedKey(value.key);
		setCustomerDataValues({
			...customerDataValues,
			BusinessSubType: value.text
		});
	};

	//taiwan onChange below

	const onChangeCustomerCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			customerCode: value
		});
	};
	const onChangeShortNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			shortName: value
		});
	};
	const onChangeAddressNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			addressName: value
		});
	};
	const onChangeAddressTaiwan = (i, e) => {
		let k = 'address' + (i + 1);
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			[k]: e.target.value
		});
	};
	const onChangeAddressSort1Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			addressSort1: value
		});
	};
	const onChangeAddress1Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address1: value
		});
	};
	const onChangeAddress2Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address2: value
		});
	};
	const onChangeAddress3Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address3: value
		});
	};
	const onChangeShipToCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			shipToCode: value
		});
	};
	const onChangeAttentionNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			attentionName: value
		});
	};
	const onChangeAttentionPhoneTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			attentionPhone: value
		});
	};
	const onChangeContactNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			contactName: value
		});
	};
	const onChangeContactPhoneTaiwan = (event, value) => {
		if(!isNaN(value)){
			setCustomerDataValuesTaiwan({
				...customerDataValuesTaiwan,
				contactPhone: value
			});
		}
	};
	const onChangeTaxCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			taxCode: value
		});
	};
	const onChangeTermsCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			termsCode: value
		});
	};
	const onChangeCommentTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			comment: value
		});
	};
	const handleRemoveClickTaiwan = (index) => {
		const list = [...inputListTaiwan];
		list.splice(index, 1);
		setInputListTaiwan(list);
	};
	const handleAddClickTaiwan = (index) => {
		setInputListTaiwan([...inputListTaiwan, { Address: '' }]);
	};
	const updateCustomerDataTaiwann = async () => {
		const resp = await updateCustomerDataTaiwan(
			customerDataValuesTaiwan,
			subsDimensionID,
			account
		);
		return resp;
	};
	const onClickSaveTaiwan = async () => {
		let resp = await updateCustomerDataTaiwann();

		if (resp.data === 1) {
			setShowAlertSuccess(true);
			setAlertMessage('Customer updated successfully');
		} else {
			setShowAlertFail(true);
			setAlertMessage('Error occured while updating customer');
		}
		setTimeout(() => {
			setShowAlertSuccess(false);
			setShowAlertFail(false);
			setAlertMessage('');
		}, 7000);
	};
	const onClickRestoreTaiwan = () => {
		getCustomerDataMain();
	};
	const onClickCancelTaiwan = () => {
		setTimeout(() => {
			history.go(0);
		}, 100);
	};
	const onClickDeleteTaiwan = () => {
		setDeleteOpenTaiwan(true);
	};

	const onChangeSubTypeCodeEdit = (event, value) => {
		setEditParams({ ...editParams, text: value });
	};
	const onChangeTypeCodeEdit = (event, value) => {
		setEditParamsBusiness({ ...editParamsBusiness, text: value });
	};
	const onChangeSubTypeDescEdit = (event, value) => {
		setEditParams({ ...editParams, desc: value });
	};
	const onChangeTypeDescEdit = (event, value) => {
		setEditParamsBusiness({ ...editParamsBusiness, desc: value });
	};
	const updateSubBusinessTypee = async (k) => {
		const resp = await updateSubBusinessType(
			editParams,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const updateBusinessTypee = async (k) => {
		const resp = await updateBusinessType(
			editParamsBusiness,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const deleteBusinessTypee = async (k) => {
		const resp = await deleteBusinessType(
			editParamsBusiness,
			subsDimensionID,
			account
		);
		setDeleteOpenType(false);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const deleteBusinessSubTypee = async (k) => {
		const resp = await deleteBusinessSubType(
			editParams,
			subsDimensionID,
			account
		);
		setDeleteOpenSubType(false);
		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const onClickSubTypeEditSave = () => {
		updateSubBusinessTypee();

		setIsOpen(false);
	};
	const onClickTypeEditSave = () => {
		updateBusinessTypee();

		setIsOpenBusiness(false);
	};
	const onClickSubTypeEditCancel = () => {
		setIsOpen(false);
	};
	const onClickTypeEditCancel = () => {
		setIsOpenBusiness(false);
	};
	const newSubBusinessTypee = async (k) => {
		const resp = await newSubBusinessType(
			subTypeNewCode,
			subTypeNewDescription,
			subsDimensionID,
			account
		);

		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const newBusinessTypee = async (k) => {
		const resp = await newBusinessType(
			typeNewCode,
			typeNewDescription,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const onClickSubTypeNewSave = () => {
		newSubBusinessTypee();

		setIsOpenNew(false);
	};
	const onClickTypeNewSave = () => {
		newBusinessTypee();
		setIsOpenBusinessNew(false);
	};
	const onClickSubTypeNewCancel = () => {
		setSubTypeNewDescription('');
		setSubTypeNewCode('');
		setIsOpenNew(false);
	};
	const onClickTypeNewCancel = () => {
		setTypeNewDescription('');
		setTypeNewCode('');
		setIsOpenBusinessNew(false);
	};
	const onChangeSubTypeNewDescription = (k, val) => {
		setSubTypeNewDescription(val);
	};
	const onChangeTypeNewDescription = (k, val) => {
		setTypeNewDescription(val);
	};
	const onChangeSubTypeNewCode = (k, val) => {
		setSubTypeNewCode(val);
	};
	const onChangeTypeNewCode = (k, val) => {
		setTypeNewCode(val);
	};
	const filterCustomerOptions = (option, index, value) => {
		if (value !== currentCustomerTyped && typeof value !== 'undefined') {
			if (value !== '') {
				const filteredOptions = customerNameDropdownOptions.filter((name) =>
					name.text.toLowerCase().includes(value.toLowerCase())
				);
				setCustomerNameDropdownOptionsCurrent(filteredOptions);
			} else {
				setCustomerNameDropdownOptionsCurrent(customerNameDropdownOptions);
			}
			setCurrentCustomerTyped(value);
		}
	};

	const filterShipCodeOptions = (option, index, value) => {
		if (value !== currentShipCodeTyped && typeof value !== 'undefined') {
			if (value !== '') {
				const filteredOptions = shipCodeDropdownOptions.filter((name) =>
					name.text.toLowerCase().startsWith(value.toLowerCase())
				);
				setShipCodeDropdownOptionsCurrent(filteredOptions);
			} else {
				setShipCodeDropdownOptionsCurrent(shipCodeDropdownOptions);
			}
			setCurrentShipCodeTyped(value);
		}
	};

	const menuProps = useConst(() => ({
		shouldFocusOnMount: true,
		items: [
			{
				key: 'newItem',
				text: 'New',
				iconProps: { iconName: 'Add' },
				onClick: () => setIsOpenNew(true)
			},
			{
				key: 'edit',
				text: 'Edit',
				iconProps: { iconName: 'Edit' },
				onClick: () => setIsOpen(true)
			},
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => setDeleteOpenSubType(true)
			}
		]
	}));
	const menuPropsBusiness = useConst(() => ({
		shouldFocusOnMount: true,
		items: [
			{
				key: 'newItem',
				text: 'New',
				iconProps: { iconName: 'Add' },
				onClick: () => setIsOpenBusinessNew(true)
			},
			{
				key: 'edit',
				text: 'Edit',
				iconProps: { iconName: 'Edit' },
				onClick: () => setIsOpenBusiness(true)
			},
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => setDeleteOpenType(true)
			}
		]
	}));

	const shortNameValidate = (value) => {
		const regex = /^[^~@#$&*()[\]{}|\,? <>'\\"/;`%]*$/;
		const test = regex.test(value)
		return test==false ? "Invalid" : "";
	}
	return (
		<Page title={'Customers'}>
			<LiveAnnouncer>
				<LiveMessage message={addCustomerMessage} aria-live='assertive' />
				<DefaultButton className='addCustomerButton' onClick={onClickRedirect}>
					<PlusIcon /> Add Customer
				</DefaultButton>
			</LiveAnnouncer>
			<Panel
				className='businessTypePanel'
				headerText='Business Sub Type'
				isOpen={isOpen}
				onDismiss={dismissPanel}
				closeButtonAriaLabel='Close'
			>
				<Stack tokens={stackTokens}>
					<TextField
						label='Business Sub Type Code'
						value={editParams.text}
						onChange={onChangeSubTypeCodeEdit}
						required
					/>
					<TextField
						label='Description'
						value={editParams.desc}
						onChange={onChangeSubTypeDescEdit}
						required
					/>
				</Stack>
				<hr />
				<Stack horizontal tokens={stackTokens}>
					<PrimaryButton
						text='Save'
						onClick={onClickSubTypeEditSave}
						allowDisabledFocus
					/>
					<DefaultButton
						text='Cancel'
						onClick={onClickSubTypeEditCancel}
						allowDisabledFocus
					/>
				</Stack>
			</Panel>
			<Panel
				className='businessTypePanel'
				headerText='Business Sub Type'
				isOpen={isOpenNew}
				onDismiss={dismissPanelNew}
				closeButtonAriaLabel='Close'
			>
				<Stack tokens={stackTokens}>
					<TextField
						label='Business Sub Type Code'
						value={subTypeNewCode}
						onChange={onChangeSubTypeNewCode}
					/>
					<TextField
						label='Description'
						value={subTypeNewDescription}
						onChange={onChangeSubTypeNewDescription}
					/>
				</Stack>
				<hr />
				<Stack horizontal tokens={stackTokens}>
					<PrimaryButton
						text='Save'
						onClick={onClickSubTypeNewSave}
						allowDisabledFocus
					/>
					<DefaultButton
						text='Cancel'
						onClick={onClickSubTypeNewCancel}
						allowDisabledFocus
					/>
				</Stack>
			</Panel>
			<Panel
				className='businessTypePanel'
				headerText='Business Type'
				isOpen={isOpenBusiness}
				onDismiss={dismissPanelBusiness}
				closeButtonAriaLabel='Close'
			>
				<Stack tokens={stackTokens}>
					<TextField
						label='Business Type Code'
						value={editParamsBusiness.text}
						onChange={onChangeTypeCodeEdit}
						required
					/>
					<TextField
						label='Description'
						value={editParamsBusiness.desc}
						onChange={onChangeTypeDescEdit}
						required
					/>
				</Stack>
				<hr />
				<Stack horizontal tokens={stackTokens}>
					<PrimaryButton
						text='Save'
						onClick={onClickTypeEditSave}
						allowDisabledFocus
					/>
					<DefaultButton
						text='Cancel'
						onClick={onClickTypeEditCancel}
						allowDisabledFocus
					/>
				</Stack>
			</Panel>
			<Panel
				className='businessTypePanel'
				headerText='Business  Type'
				isOpen={isOpenBusinessNew}
				onDismiss={dismissPanelBusinessNew}
				closeButtonAriaLabel='Close'
			>
				<Stack tokens={stackTokens}>
					<TextField
						label='Business  Type Code'
						value={typeNewCode}
						onChange={onChangeTypeNewCode}
					/>
					<TextField
						label='Description'
						value={typeNewDescription}
						onChange={onChangeTypeNewDescription}
					/>
				</Stack>
				<hr />
				<Stack horizontal tokens={stackTokens}>
					<PrimaryButton
						text='Save'
						onClick={onClickTypeNewSave}
						allowDisabledFocus
					/>
					<DefaultButton
						text='Cancel'
						onClick={onClickTypeNewCancel}
						allowDisabledFocus
					/>
				</Stack>
			</Panel>

			<div className='ms-Grid' dir='ltr'>
				<div className='ms-Grid-row headerPadding'>
					<div className='ms-Grid-col ms-lg3 ms-sm11'>
						<ComboBox
							placeholder='Type or Select Customer Name'
							label='Customer Name'
							options={customerNameDropdownOptionsCurrent}
							allowFreeform
							autoComplete='off'
							onChange={onChangeCustomerName}
							onPendingValueChanged={filterCustomerOptions}
							selectedKey={customerNameSelectedKey}
							openOnKeyboardFocus={false}
							//errorMessage={error ? 'Please provide a name' : ''}
							//styles={comboBoxStyles}
							//useComboBoxAsMenuWidth
							required
						/>
						{/* <Dropdown label="Customer Name"
                            onChange={onChangeCustomerName}
                            placeholder="Select an option"
                            options={customerNameDropdownOptions}
                            selectedKey={customerNameSelectedKey}
                        /> */}
					</div>
					<div className='ms-Grid-col ms-lg3 ms-sm11'>
						{/* <Dropdown
                            label="Ship to Code"
                            onChange={onChangeShipToCode}
                            placeholder="Select an option"
                            options={shipCodeDropdownOptions}
                            selectedKey={shipToCodeSelectedKey}
                        /> */}
						<ComboBox
							placeholder='Type or Select Ship To Code'
							label='Ship To Code'
							//multiSelect
							options={shipCodeDropdownOptionsCurrent}
							allowFreeform
							autoComplete='off'
							onChange={onChangeShipToCode}
							onPendingValueChanged={filterShipCodeOptions}
							selectedKey={shipToCodeSelectedKey}
							openOnKeyboardFocus={false}
							//errorMessage={error ? 'Please provide a name' : ''}
							//styles={comboBoxStyles}
							//useComboBoxAsMenuWidth
							disabled={customerName.length < 1}
							required
						/>
					</div>
					<div className='ms-Grid-col  ' style={{ marginTop: '31px' }}>
						<Stack horizontal tokens={{ childrenGap: 13 }}>
							<LiveAnnouncer>
								<LiveMessage message={message} aria-live='polite' />
								<PrimaryButton
									text='Submit'
									disabled={shipCode.length < 1}
									onClick={onClickSubmit}
								/>
								<DefaultButton text='Clear' onClick={onClickClear} />
							</LiveAnnouncer>
						</Stack>
					</div>
				</div>
				<hr></hr>

				{showData && subsDimensionID === 2 ? (
					<div className='ms-Grid contentPadding' dir='ltr'>
						<div className='ms-Grid-row'>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Customer Code'
										value={customerDataValues.CustomerCode}
										disabled='true'
										// onChange={onChangeCustomerCode}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Sold to Name'
										value={customerDataValues.SoldToName}
										onChange={onChangeSoldToName}
										required
									/>
									{/* {inputList.map((x, i) => {
                                        return (
                                            <div>
                                                <Stack >
                                                    <Stack horizontal >
                                                        <TextField
                                                            className="ms-Grid-col ms-lg11 ms-sm11"
                                                            label={"Sold to Address " + (i + 1)}
                                                            value={x.Address}
                                                            onChange={(e) => onChangeAddress(i, e)}
                                                        />
                                                        {inputList.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                                    </Stack>
                                                    {inputList.length - 1 === i && i!==2&& <div className="addAddressPadding"> <IconButton onClick={handleAddClick} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add" style={{ marginTop: "auto" }}> </IconButton><span className="label">Add Address</span></div>}
                                                </Stack>
                                            </div>)
                                    })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 1'}
										value={customerDataValues.SoldToAddress1}
										onChange={onChangeSoldToAddress1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 2'}
										value={customerDataValues.SoldToAddress2}
										onChange={onChangeSoldToAddress2}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 3'}
										value={customerDataValues.SoldToAddress3}
										onChange={onChangeSoldToAddress3}
									/>

									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Name'
										value={customerDataValues.ContactName}
										onChange={onChangeContactName}
									/>
									<Stack
										horizontal
										horizontalAlign='space-between'
										verticalAlign='end'
										tokens={{ childrenGap: 25 }}
										className='ms-Grid-col ms-lg11 ms-sm11'
									>
										<TextField
											label='Salesperson Code'
											value={customerDataValues.SalespersonCode}
											onChange={onChangeSalesPersonCode}
										/>
										<TextField
											label='Terms Code'
											value={customerDataValues.TermsCode}
											onChange={onChangeTermsCode}
										/>
										<TextField
											label='Tax Code'
											value={customerDataValues.TaxCode}
											onChange={onChangeTaxCode}
										/>
									</Stack>
								</Stack>{' '}
							</div>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Code'
										value={customerDataValues.ShipToCode}
										disabled='true'
										// onChange={onChangeShipToCodeInForm}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Name'
										value={customerDataValues.ShipToName}
										onChange={onChangeShipToName}
									/>

									{/* {inputList2.map((x, i) => {
                                        return (
                                            <Stack>
                                                <Stack horizontal>
                                                    <TextField
                                                        className="ms-Grid-col ms-lg11 ms-sm11"
                                                        label={"Ship to Address " + (i + 1)}
                                                        value={x.Address}
                                                        onChange={(e) => onChangeAddress2(i, e)}
                                                    />

                                                    {inputList2.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick2(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                                </Stack>
                                                {inputList2.length - 1 === i && i!==2&& <div className="addAddressPadding"><IconButton onClick={handleAddClick2} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add" label="Add address" style={{ marginTop: "auto" }}></IconButton><span className="label">Add Address</span></div>}
                                            </Stack>)
                                    })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 1'}
										value={customerDataValues.ShipToAddress1}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 2'}
										value={customerDataValues.ShipToAddress2}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress2}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 3'}
										value={customerDataValues.ShipToAddress3}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress3}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Business Register Number'
										value={customerDataValues.BusinessRegisterNumber}
										onChange={onChangeBusinessRegisterNumber}
									/>
									<Stack
										horizontal
										tokens={{ childrenGap: 20 }}
										verticalAlign='end'
										horizontalAlign='space-between'
										wrap
										className='ms-Grid-col ms-lg11 ms-sm11'
									>
										<Stack
											horizontal
											className='ms-lg6 ms-sm6 businessTypeDropdown'
										>
											<Dropdown
												label='Business Type'
												className='ms-lg11 ms-sm11'
												placeholder={customerDataValues.BusinessType}
												onChange={onChangeBusinessType}
												options={businessTypeOption}
											/>
											<IconButton
												menuProps={menuPropsBusiness}
												iconProps={{ iconName: 'More' }}
												title='More'
												ariaLabel='More'
												style={{ marginTop: 'auto' }}
											/>
										</Stack>
										<Stack
											horizontal
											className='ms-lg6 ms-sm6 businessTypeDropdown'
										>
											<Dropdown
												className='ms-lg11 ms-sm11 '
												label='Business Sub Type'
												placeholder={customerDataValues.BusinessSubType}
												options={businessSubTypeOption}
												selectedKey={subTypeSelectedKey}
												onChange={onChangeBusinessSubType}
											/>
											<IconButton
												menuProps={menuProps}
												iconProps={{ iconName: 'More' }}
												title='More'
												ariaLabel='More'
												style={{ marginTop: 'auto' }}
											/>
										</Stack>
									</Stack>
								</Stack>
							</div>
						</div>
						{deleteOpen && (
							<Confirm
								message='Do you wish to delete customer?'
								open={deleteOpen}
								onClose={(confirm) => {
									if (confirm) {
										deleteCustomerDataa();
									} else {
										setDeleteOpen(false);
									}
								}}
							/>
						)}
						{deleteOpenSubType && (
							<Confirm
								message='Do you wish to delete this Business Sub Type?'
								open={deleteOpenSubType}
								onClose={(confirm) => {
									if (confirm) {
										deleteBusinessSubTypee();
									} else {
										setDeleteOpenSubType(false);
									}
								}}
							/>
						)}
						{deleteOpenType && (
							<Confirm
								message='Do you wish to delete this Business Type?'
								open={deleteOpenType}
								onClose={(confirm) => {
									if (confirm) {
										deleteBusinessTypee();
									} else {
										setDeleteOpenType(false);
									}
								}}
							/>
						)}

						<hr></hr>
						<LiveAnnouncer>
							<LiveMessage message={alertMessage} aria-live='polite' />
							{showAlertFail && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.error}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Error occured while updating customer
								</MessageBar>
							)}
							{showAlertSuccess && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.success}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Customer updated successfully
								</MessageBar>
							)}
							{showAlertFailDelete && (
								<MessageBar
									delayedRender={false}
									// onDismiss={setShowAlertFailDelete(false)}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.error}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									{deleteCustomerMessage}
								</MessageBar>
							)}
							{showAlertSuccessDelete && (
								<MessageBar
									delayedRender={false}
									// onDismiss={setShowAlertSuccessDelete(false)}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.success}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Customer deleted successfully
								</MessageBar>
							)}
							{businessTypeAndSubTypeSuccess && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.success}
								>
									Action completed successfully
								</MessageBar>
							)}
							{businessTypeAndSubTypeError && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.error}
								>
									Error occured while performing the action
								</MessageBar>
							)}
						</LiveAnnouncer>
						<Stack
							horizontal
							horizontalAlign='end'
							wrap
							tokens={stackTokensButton}
							className=' pagePadding ms-sm11 ms-lg12'
						>
							<PrimaryButton
								text='Save'
								onClick={onClickSave}
								allowDisabledFocus
								disabled={false}
							/>
							<DefaultButton
								text='Cancel'
								onClick={onClickCancel}
								allowDisabledFocus
							/>
							<DefaultButton
								text='Delete'
								onClick={onClickDelete}
								allowDisabledFocus
							/>
							<DefaultButton
								text='Restore'
								onClick={onClickRestore}
								allowDisabledFocus
							/>
						</Stack>
					</div>
				) : null}
				{showData && subsDimensionID === 1 ? (
					<div className='ms-Grid contentPadding' dir='ltr'>
						<div className='ms-Grid-row'>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Customer Code'
										value={customerDataValuesTaiwan.customerCode}
										disabled='true'
										// onChange={onChangeCustomerCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Short Name'
										value={customerDataValuesTaiwan.shortName}
										onChange={onChangeShortNameTaiwan}
										onGetErrorMessage={shortNameValidate}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address Name'
										value={customerDataValuesTaiwan.addressName}
										onChange={onChangeAddressNameTaiwan}
										required
									/>
									{/* {inputListTaiwan.map((x, i) => {
                                        let val = "address" + (i + 1)
                                        return (
                                            <div key={i}>
                                                <Stack >
                                                    <Stack horizontal >
                                                        <TextField
                                                            className="ms-Grid-col ms-lg11 ms-sm11"
                                                            label={"Address " + (i + 1)}
                                                            value={customerDataValuesTaiwan[val]}
                                                            onChange={(e) => onChangeAddressTaiwan(i, e)}
                                                        />
                                                        {inputListTaiwan.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClickTaiwan(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                                    </Stack>
                                                    {inputListTaiwan.length - 1 === i && i!==2&&<div className="addAddressPadding"> <IconButton onClick={handleAddClickTaiwan} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Sold to' address" style={{ marginTop: "auto" }}> </IconButton><span className="label">Add Address</span></div>}
                                                </Stack>
                                            </div>)
                                    })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 1'
										value={customerDataValuesTaiwan.address1}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress1Taiwan}
										//required={i+1==1}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 2'
										value={customerDataValuesTaiwan.address2}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress2Taiwan}
										//required={i+1==1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 3'
										value={customerDataValuesTaiwan.address3}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress3Taiwan}
										//required={i+1==1}
									/>

									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address Sort'
										value={customerDataValuesTaiwan.addressSort1}
										onChange={onChangeAddressSort1Taiwan}
										required
									/>
									{/* <Stack horizontal horizontalAlign={horizontalAlignmentOptions} tokens={{ childrenGap: 25 }} className="ms-Grid-col ms-lg11 ms-sm11">
                                <TextField
                                    label="Salesperson Code"
                                    value={customerDataValuesTaiwan.salespersonCode}
                                    onChange={onChangeSalesPersonCode}
                                />
                                <TextField
                                    label="Terms Code"
                                    value={customerDataValuesTaiwan.termsCode}
                                    onChange={onChangeTermsCode}
                                />
                                <TextField
                                    label="Tax Code"
                                    value={customerDataValuesTaiwan.taxCode}
                                    onChange={onChangeTaxCode}
                                />
                            </Stack> */}
								</Stack>{' '}
							</div>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Code'
										value={customerDataValuesTaiwan.shipToCode}
										//  onChange={onChangeShipToCodeTaiwan}
										disabled='true'
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Attention Name'
										value={customerDataValuesTaiwan.attentionName}
										onChange={onChangeAttentionNameTaiwan}
									/>
									{/* {inputList2.map((x, i) => {
                                let val="shipToAddress"+(i+1)
                                return (
                                    <Stack key={i}>
                                        <Stack horizontal>
                                            <TextField
                                                className="ms-Grid-col ms-lg11 ms-sm11"
                                                label={"Ship to Address " + (i + 1)}
                                                value={customerDataValuesTaiwan[val]}
                                                onChange={(e) => onChangeAddress2(i, e)}
                                            /> {inputList2.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick2(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                        </Stack>
                                        {inputList2.length - 1 === i && <div className="addAddressPadding"><IconButton onClick={handleAddClick2} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Ship to' address" label="Add address" style={{ marginTop: "auto" }}></IconButton><span className="label">Add Address</span></div>}
                                    </Stack>)
                            })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Attention Phone'
										value={customerDataValuesTaiwan.attentionPhone}
										onChange={onChangeAttentionPhoneTaiwan}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Name'
										value={customerDataValuesTaiwan.contactName}
										onChange={onChangeContactNameTaiwan}
									/>
									<TextField
										// type='number'
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Phone'
										aria-label='contact phone'
										value={customerDataValuesTaiwan.contactPhone}
										onChange={onChangeContactPhoneTaiwan}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Tax Code'
										value={customerDataValuesTaiwan.taxCode}
										onChange={onChangeTaxCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Terms Code'
										value={customerDataValuesTaiwan.termsCode}
										onChange={onChangeTermsCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Comment'
										value={customerDataValuesTaiwan.comment}
										onChange={onChangeCommentTaiwan}
										required
									/>
								</Stack>
							</div>
						</div>
						{deleteOpenTaiwan && (
							<Confirm
								message='Do you wish to delete customer?'
								open={deleteOpenTaiwan}
								onClose={(confirm) => {
									if (confirm) {
										deleteCustomerDataa();
									} else {
										setDeleteOpenTaiwan(false);
									}
								}}
							/>
						)}
						<hr></hr>
						<LiveAnnouncer>
							<LiveMessage message={alertMessage} aria-live='polite' />
							{showAlertFail && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.error}
								>
									Error occured while updating customer
								</MessageBar>
							)}
							{showAlertSuccess && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.success}
								>
									Customer updated successfully
								</MessageBar>
							)}
							{showAlertFailDelete && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.error}
								>
									{deleteCustomerMessage}
								</MessageBar>
							)}
							{showAlertSuccessDelete && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.success}
								>
									Customer deleted successfully
								</MessageBar>
							)}
						</LiveAnnouncer>
						<Stack
							horizontal
							horizontalAlign='end'
							wrap
							tokens={stackTokensButton}
							className=' pagePadding ms-sm11 ms-lg12'
						>
							<PrimaryButton
								text='Save'
								onClick={onClickSaveTaiwan}
								allowDisabledFocus
								disabled={
									customerDataValuesTaiwan?.shipToCode?.length < 1 ||
									customerDataValuesTaiwan?.customerCode?.length < 1 ||
									customerDataValuesTaiwan?.taxCode?.length < 1 ||
									customerDataValuesTaiwan?.termsCode?.length < 1 ||
									customerDataValuesTaiwan?.comment?.length < 1 ||
									customerDataValuesTaiwan?.address1?.length < 1 ||
									customerDataValuesTaiwan?.addressSort1?.length < 1 ||
									customerDataValuesTaiwan?.shortName?.length < 1 ||
									customerDataValuesTaiwan?.addressName?.length < 1
								}
							/>
							<DefaultButton
								text='Cancel'
								onClick={onClickCancelTaiwan}
								allowDisabledFocus
							/>
							<DefaultButton
								text='Delete'
								onClick={onClickDeleteTaiwan}
								allowDisabledFocus
							/>
							<DefaultButton
								text='Restore'
								onClick={onClickRestoreTaiwan}
								allowDisabledFocus
							/>
						</Stack>
					</div>
				) : null}
			</div>
		</Page>
	);
}

export default Customers;
