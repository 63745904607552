import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import FluentAccordion from '../../components/FluentAccordion';
import Page from '../../components/Page';
import { getGuiNumbers, getGuiYears } from '../../services/guiNumber';
import AccordionHeader from './components/AccordionHeader';
import ActionBar from './components/ActionBar';
import Roles from './components/Roles';
import Header from './components/Header';
import { MessageBar, MessageBarType } from '@fluentui/react';
import NotificationBar from '../../components/NotificationBar';
import { NEW_GUI_DATA } from '../../constants';
import useNotification from '../../hooks/useNotification';
import { useSelector } from 'react-redux';

function GUINumbers() {
	const { notify } = useNotification();

	const [data, setData] = useState(null);
	const [years, setYears] = useState([]);
	const [year, setYear] = useState(new Date().getFullYear());
	const [isNew, setIsNew] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [addNewBtnMessage, setAddNewBtnMessage] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [isModified, setIsModified] = useState(false);

	const user = useSelector((state) => state.user);
	const upstream = useSelector((state) => state.upstream);
	const currentUpstream = upstream?.upstreams?.find(
		(x) => x.selected
	)?.upstreamName;

	useEffect(() => {
		const subDimensionID = user?.user?.userList?.subsDimensionID;

		getGuiYears(subDimensionID)
			.then((resp) => {
				const years = resp?.data.map((x) => ({
					key: x.guiYear.toString(),
					text: x.guiYear.toString()
				}));
				setYears(years);

				if (resp?.data.length > 0) {
					setYear(resp?.data[0].guiYear);
				}
			})
			.catch((e) => console.log(e));
	}, [user]);

	useEffect(() => {
		document.title="Invoice print tool-Gui-Numbers"
		const userAlias = user?.user?.userList?.userAlias;
		const subDimensionID = user?.user?.userList?.subsDimensionID;
		if (year || isModified) {
			getGuiNumbers(
				userAlias,
				true,
				subDimensionID,
				year,
				currentUpstream
			).then((resp) => {
				const response = resp.data;
				const guibyMonths = response.reduce((acc, value) => {
					if (
						currentUpstream === 'SAP' ||
						(currentUpstream !== 'SAP' && !value.isOOW)
					) {
						if (!acc[value.guiSegmentRowNum]) {
							acc[value.guiSegmentRowNum] = [];
						}
						acc[value.guiSegmentRowNum].push(value);
					}
					return acc;
				}, {});

				const data = [];
				const months = [
					null,
					'Jan-Feb',
					'Mar-Apr',
					'May-Jun',
					'Jul-Aug',
					'Sep-Oct',
					'Nov-Dec'
				];
				if (guibyMonths) {
					_.forOwn(guibyMonths, (val, key) => {
						data.push({
							key: months[key],
							value: val
						});
					});
				}
				setData(data);
			});
		}
	}, [year, user, isModified]);

	const handleAddItem = () => {
		setIsNew(true);
		const tempYears = _.cloneDeep(years);
		const year = parseInt(tempYears[0].key) + 1;
		tempYears.unshift({ key: `${year}`, text: `${year}` });
		setYears(tempYears);
		setYear(year);
		// setData(NEW_GUI_DATA);
		setAddNewBtnMessage(`Added year successfully ${year}`);
	};

	const saveMessageStyles = {
		root: {
			marginBottom: '10px'
		}
	};
	return (
		<Page title={'GUI Numbers'}>
			<ActionBar
				years={years}
				year={year}
				onChange={(option) => {
					setYear(option.key);
				}}
				onAdd={() => {
					handleAddItem();
				}}
				addNewBtnMessage={addNewBtnMessage}
			/>
			<NotificationBar type={MessageBarType.success} />
			{data && data.length > 0 && (
				<div>
					<Header
						year={year}
						expanded={expanded}
						onExpandClick={() => setExpanded(!expanded)}
					/>
					{data.map((val, ind) => (
						<FluentAccordion
							key={ind}
							collapse={!expanded}
							year={year}
							header={<AccordionHeader header={val.key} />}
						>
							<Roles
								isNew={isNew}
								data={val.value}
								header={val.key}
								setIsNew={setIsNew}
								setShowMessage={setShowMessage}
								user={user}
								isModified={isModified}
								setIsModified={setIsModified}
							/>
							{showMessage ? (
								<MessageBar
									isMultiline={false}
									messageBarType={MessageBarType.success}
									onDismiss={() => setShowMessage(false)}
									styles={saveMessageStyles}
								>
									GUI Numbers updated successfully.
								</MessageBar>
							) : (
								''
							)}
						</FluentAccordion>
					))}
				</div>
			)}
		</Page>
	);
}

export default GUINumbers;
