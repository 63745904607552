import { Label, Link, Stack } from '@fluentui/react';
import classNames from 'classnames';
import React from 'react';

import s from './styles.module.scss';

function Header({ year, expanded, onExpandClick, className }) {
	return (
		<Stack
			horizontal={true}
			verticalFill={true}
			horizontalAlign='space-between'
			className={classNames(s.root, className)}
		>
			<h2 className={classNames(s.label, 'ms-lg2', 'ms-sm5')}>
				Year : {year}
			</h2>
			<Link
				aria-label={
					expanded
						? 'expanded, Clickable collapse all'
						: 'collapsed, Clickable expand all'
				}
				className={s.link}
				onClick={() => onExpandClick()}
			>
				{expanded ? 'Collapse' : 'Expand'} All
			</Link>
		</Stack>
	);
}

export default Header;
