import { apiInstance } from './requestInterceptor';
import axios from 'axios';
import { refreshAccessToken } from '../utils';

export function getGuiYears(subDimensionId) {
  return apiInstance.get(
		`/GUI/GetGUIyear?SubDimensionID=${subDimensionId}`
	);  
}

export async function getGuiNumbers(userName, taxFlag, subsDimensionID, guiYear, selectedUpstream) {
  const token = await refreshAccessToken();
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/GUI/GetGUIDetails`,
    // 'https://iptapiuat.azurewebsites.net/api/v1.0/GUI/GetGUIDetails',
    headers: {
      upstream: selectedUpstream,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      userName, taxFlag, subsDimensionID, guiYear
    }
  };
  return axios(config);
}

export async function saveGUIDetails(details, selectedUpstream) {
  const token = await refreshAccessToken();
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/GUI/GUIOperations`,
    // 'https://iptapiuat.azurewebsites.net/api/v1.0/GUI/GUIOperations',
    headers: {
      upstream: selectedUpstream,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: details
  };
  return axios(config);
}
