import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useParams,
	useHistory,
	Redirect
} from 'react-router-dom';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate
} from '@azure/msal-react';
import PageLayout from './components/PageLayout/index';
import './styles/App.css';
import { AppRoute } from './router';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import NotificationProvider from './providers/notificationProvider';
import NotificationSnack from './components/NotificationSnack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { storeTenant } from './actions/upstreamAction';
import { ThemeProvider, loadTheme } from '@fluentui/react';
import { CoherenceNeutralHeaderPalette } from '@coherence-design-system/styles';
import { CoherenceTheme } from '@coherence-design-system/styles';
/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
initializeIcons();
loadTheme(CoherenceTheme);

const MainContent = () => {
	const param = useParams();
	const history = useHistory();
	const [showLoader, setShowLoader] = useState(false);
	const loader = useSelector((state) => state.loader);

	useEffect(() => {
		setShowLoader(loader && loader.counter > 0);
	}, [loader]);

	return (
		<div className='App'>
			<AuthenticatedTemplate>
				<NotificationProvider>
					{showLoader && (
						<Spinner className='spinner' size={SpinnerSize.large} />
					)}
					<NotificationSnack />
					<AppRoute />
				</NotificationProvider>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<div className='card-title card-div'>
					Please sign-in to see your profile information.
				</div>
			</UnauthenticatedTemplate>
		</div>
	);
};

export default function App() {
	return (
		<ThemeProvider {...CoherenceNeutralHeaderPalette}>
			<Router>
				<Provider store={store}>
					<PageLayout>
						<MainContent />
					</PageLayout>
				</Provider>
			</Router>
		</ThemeProvider>
	);
}
