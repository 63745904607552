import { DefaultButton, SearchBox, Separator, Stack } from "@fluentui/react";
import React from "react";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import s from "./styles.module.scss";

function ActionBar({ onSearch, onAdd }) {
  return (
    <Stack className={s.root} horizontal={false} verticalFill>
      <Stack
        className={s.actionContainer}
        horizontal
        horizontalAlign={"space-between"}
      >
        <SearchBox
          role="search"
          className={s.search}
          placeholder="Search Username"
          onChange={(e) => {
            if (e) {
              onSearch(e.target.value);
            }
          }}
          onClear={() => onSearch("")}
        />
        <DefaultButton className={s.button} onClick={onAdd}>
          <PlusIcon /> New User
        </DefaultButton>
      </Stack>
      <Separator className={s.separator} />
    </Stack>
  );
}

export default ActionBar;
