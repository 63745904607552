import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom';
import Customers from './pages/Customers';
import Documents from './pages/Documents';
import GUINumbers from './pages/GUINumbers';
import { RaiseTicketPage } from './components/About/RaiseTicket';
import { AddCustomerData } from './pages/Customers/AddCustomer';
import Log from './pages/Log';
import UserRoles from './pages/UserRoles';

export const AppRoute = () => {
	const [myRoles, setMyRoles] = useState([]);
	const upstream = useSelector((state) => state.upstream);
	const user = useSelector((state) => state.user);
	const subDimensionID = user?.user?.userList?.subsDimensionID;
	const currentUpstream = upstream?.upstreams?.find(
		(x) => x.selected
	)?.upstreamName;

	useEffect(() => {
		const roleBucket = [];
		user?.user?.userRoles?.map((item) => roleBucket.push(item.roleName));
		setMyRoles(roleBucket);
	}, [user]);

	const routes = () => {
		return (
			<>
				{myRoles.length == 1 && myRoles.includes('Admin') ? (
					<Route exact path='/'>
						<UserRoles />
					</Route>
				) : (
					''
				)}
				{myRoles.includes('Admin') ? (
					<Route exact path='/user-roles'>
						<UserRoles />
					</Route>
				) : (
					''
				)}
				{myRoles.includes('Operation') ? (
					<Route exact path='/logs'>
						<Log />
					</Route>
				) : (
					''
				)}
				{myRoles.includes('Operation') ||
				myRoles.includes('Finance') ||
				myRoles.includes('OOW') ? (
					<Route exact path={['/', '/documents']}>
						<Documents />
					</Route>
				) : (
					''
				)}
				{myRoles.includes('Operation') && currentUpstream == 'SAP' ? (
					<>
						<Route
							exact
							path='/addcustomers'
							component={AddCustomerData}
						></Route>
						<Route exact path='/customers'>
							<Customers />
						</Route>
					</>
				) : (
					''
				)}
				{myRoles.includes('Finance') && subDimensionID == 1 ? (
					<Route exact path='/gui-numbers'>
						<GUINumbers />
					</Route>
				) : (
					''
				)}
			</>
		);
	};

	return (
		<>
			{user.user ? (
				<Switch>
					<Route exact path='/raiseticket' component={RaiseTicketPage}></Route>
					{routes()}
				</Switch>
			) : (
				''
			)}
		</>
	);
};
