import React from "react";
import { Panel } from "@fluentui/react";

import s from "./styles.module.scss";
import classNames from "classnames";

function LeftPanel({ open, hasCloseIcon, onClose, className, children }) {
  return (
    <Panel
      isOpen={open}
      isLightDismiss={!hasCloseIcon}
      onLightDismissClick={() => (!hasCloseIcon ? onClose() : null)}
      closeButtonAriaLabel="Close"
      className={classNames(s.root, className)}
      hasCloseButton={hasCloseIcon}
      onDismiss={() => (hasCloseIcon ? onClose() : null)}
      isFooterAtBottom={false}
      styles={{
        content: {
          padding: 0,
        },
        commands: {
          padding: 0,
        },
        footer: {
          display: "none",
        },
      }}
    >
      {children}
    </Panel>
  );
}

export default LeftPanel;
