import React from 'react';

import {
	CoherencePageSize,
	CoherencePagination
} from '@coherence-design-system/controls';

const TableFooter = ({
	maxPage,
	currentPage,
	pageSize,
	itemCount,
	onPageChange,
	onPageSizeChange
}) => {
	const paginationStyles = {
		root: {
			display: 'flex'
		}
	};

	const paginationProps = {
		pageCount: maxPage,
		selectedPage: currentPage,
		previousPageAriaLabel: 'Prev button click to go to previous page',
		nextPageAriaLabel: 'Next button click to go to next page',
		inputFieldAriaLabel: 'page number',
		onPageChange: onPageChange
	};

	const paginationPageSizeProps = {
		pageSize: pageSize,
		pageSizeList: [
			{ key: 10, text: '10' },
			{ key: 25, text: '25' },
			{ key: 50, text: '50' }
		],

		comboBoxAriaLabel: 'Display items per page',
		onPageSizeChange: onPageSizeChange
	};

	return (
		<div className='table-footer'>
			<div className='results-found'>
				<span className='results-found-value'>{itemCount}</span>
				Results found
			</div>
			{maxPage > 1 ? (
				<CoherencePagination styles={paginationStyles} {...paginationProps} />
			) : (
				''
			)}
			<div className='page-size'>
				<CoherencePageSize {...paginationPageSizeProps} />
			</div>
		</div>
	);
};

export default TableFooter;
