import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Stack, TooltipHost, Link } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { TextField } from '@fluentui/react/lib/TextField';
import {
	CoherencePageSize,
	CoherencePagination
} from '@coherence-design-system/controls';
import { DatePicker, defaultDatePickerStrings } from '@fluentui/react';
import Page from '../../components/Page';
import 'office-ui-fabric-core/dist/css/fabric.css';
import './stylesLog.scss';
import { CoherenceDataGrid } from '@coherence-design-system/controls';
import { mergeStyleSets } from '@fluentui/react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import dayjs from 'dayjs';
import { getRealLogsData, getRealPrintLogsData } from '../../services/logs';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderAction';
import { useSelector } from 'react-redux';

const Styles = mergeStyleSets({
	spacer: {
		// height: '300px',
		marginLeft: '-18px',
		overflowY: 'auto'
	},
	importStatus: {
		fontSize: '14px',
		color: 'black',
		marginLeft: '5px'
	},
	numColumns: {
		fontSize: '14px',
		color: 'black'
	}
});

const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

const logTypeDropdownOptions = [
	{ key: 'ele1', text: 'Print Log', ariaLabel: 'Print Log' },
	{ key: 'ele2', text: 'Import Log', ariaLabel: 'Import Log' }
];

function Log(props) {
	const _columns1 = [
		{
			key: 'column1',
			name: 'Number',
			fieldName: 'documentNumber',
			type: 'string',
			isResizable: true,
			sortAscendingAriaLabel: 'Number column Sorted to ascending',
			sortDescendingAriaLabel: 'Number column Sorted to descending'
		},
		{
			key: 'column2',
			name: 'Type',
			fieldName: 'documentType',
			type: 'string',
			isResizable: true,
			sortAscendingAriaLabel: 'Type column Sorted to ascending',
			sortDescendingAriaLabel: 'Type column Sorted to descending'
		},
		{
			key: 'column3',
			name: 'Print Date',
			fieldName: 'printDate',
			type: 'dayjs',
			isResizable: true,
			sortAscendingAriaLabel: 'Print Date column Sorted to ascending',
			sortDescendingAriaLabel: 'Print Date column Sorted to descending',
			onRender: (item) => {
				if (item.printDate) {
					return (
						<span className={Styles.numColumns}>
							{item.printDate.format('DD MMM YYYY')}
						</span>
					);
				}
			}
		},
		{
			key: 'column4H',
			name: 'Print By',
			fieldName: 'userName',
			type: 'string',
			isResizable: true,
			minColumnWidth: 150,
			sortAscendingAriaLabel: 'Print By column Sorted to ascending',
			sortDescendingAriaLabel: 'Print By column Sorted to descending'
		},
		{
			key: 'column5',
			name: 'Status',
			fieldName: 'printStatus',
			type: 'string',
			isResizable: true,
			sortAscendingAriaLabel: 'Status column Sorted to ascending',
			sortDescendingAriaLabel: 'Status column Sorted to descending'
		}
	];

	const _columns2 = [
		{
			key: 'column1',
			name: 'Number',
			fieldName: 'documentNumber',
			type: 'string',
			sortAscendingAriaLabel: 'Number column Sorted to ascending',
			sortDescendingAriaLabel: 'Number column Sorted to descending',
			isResizable: true
		},
		{
			key: 'column2',
			name: 'Type',
			fieldName: 'documentType',
			type: 'string',
			sortAscendingAriaLabel: 'Type column Sorted to ascending',
			sortDescendingAriaLabel: 'Type column Sorted to descending',
			isResizable: true
		},
		{
			key: 'column3I',
			name: 'Status',
			fieldName: 'importStatus',
			type: 'string',
			sortAscendingAriaLabel: 'Status column Sorted to ascending',
			sortDescendingAriaLabel: 'Status column Sorted to descending',
			isResizable: true,
			minWidth: 300,
			onRender: (item) => (
				<TooltipHost content={item.importStatus}>
					{item.importStatus === 'Completed' ? (
						<div>
							<Icon className='IconCompletedColor' iconName='Completed' />
							<span className={Styles.importStatus}>{item.importStatus}</span>
						</div>
					) : (
						<div>
							<Icon className='IconPendingColor' iconName='IncidentTriangle' />
							<span className={Styles.importStatus}>{item.importStatus}</span>
						</div>
					)}
				</TooltipHost>
			)
		},
		{
			key: 'column4',
			name: 'Validation Result',
			fieldName: 'validationResult',
			type: 'string',
			sortAscendingAriaLabel: 'Validation Result column Sorted to ascending',
			sortDescendingAriaLabel: 'Validation Result column Sorted to descending',
			isResizable: true,
			minColumnWidth: 200
		},
		{
			key: 'column5R',
			name: 'Time Stamp',
			fieldName: 'importDate',
			type: 'dayjs',
			sortAscendingAriaLabel: 'Time Stamp column Sorted to ascending',
			sortDescendingAriaLabel: 'Time Stamp column Sorted to descending',
			isResizable: true,
			onRender: (item) => {
				if (item.importDate) {
					return (
						<span className={Styles.numColumns}>
							{item.importDate.format('DD MMM YYYY')}
						</span>
					);
				}
			}
		},
		{
			key: 'column6H',
			name: 'File Name',
			fieldName: 'fileName',
			type: 'string',
			sortAscendingAriaLabel: 'File Name column Sorted to ascending',
			sortDescendingAriaLabel: 'File Name column Sorted to descending',
			isResizable: true
		},
		{
			key: 'column7',
			name: 'Is Import',
			fieldName: 'isImport',
			type: 'string',
			sortAscendingAriaLabel: 'Is Import column Sorted to ascending',
			sortDescendingAriaLabel: 'Is Import column Sorted to descending',
			isResizable: true,
			minColumnWidth: 75
		},
		{
			key: 'column8',
			name: 'Row Number',
			fieldName: 'rowNumber',
			type: 'number',
			sortAscendingAriaLabel: 'Row Number column Sorted to ascending',
			sortDescendingAriaLabel: 'Row Number column Sorted to descending',
			isResizable: true
		}
	];

	const displayItemsOptions = [
		{ key: 5, text: '5' },
		{ key: 6, text: '6' },
		{ key: 7, text: '7' },
		{ key: 8, text: '8' },
		{ key: 9, text: '9' },
		{ key: 10, text: '10' }
	];

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [_allItems, set_allItems] = useState([]);
	const [maxPage, setMaxPage] = useState('');
	const [_columns, set_columns] = useState([]);
	const [pageData, setPageData] = useState([]);
	const [logType, setLogType] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [statusDropDownOptions, setStatusDropDownOptions] = useState([]);
	const [status, setStatus] = useState('');
	const [statusSelectedKey, setStatusSelectedKey] = useState(null);
	const [logTypeSelectedKey, setLogTypeSelectedKey] = useState(null);
	const [logRequired, setLogRequired] = useState(false);
	const [statusRequired, setStatusRequired] = useState(false);
	const [showData, setShowData] = useState(false);
	const [logParams, setLogParams] = useState({
		DocumentStatus: '',
		DocumentDateFrom: null,
		DocumentDateTo: null,
		DocumentNumberFrom: '',
		DocumentNumberTo: '',
		PageNumber: '1',
		PageSize: '5'
	});
	const [documentNumberFrom, setDocumentNumberFrom] = useState('');
	const [documentNumberTo, setDocumentNumberTo] = useState('');
	const [message, setMessage] = useState('');

	const user = useSelector((state) => state.user);
	const subDimensionID = user.user?.userList?.subsDimensionID;

	const dispatch = useDispatch();

	useEffect(() => {
		document.title="Invoice print tool-Log";
		document.getElementsByClassName('msDatePickerDisabled')[0].setAttribute('title','Calendar icon');
		document.getElementsByClassName('msDatePickerDisabled')[1].setAttribute('title','Calendar icon');
		if (logType == 'Import Log') {
			
			setStatusDropDownOptions([
				{ key: 'Completed', text: 'Completed' },
				{ key: 'Pending', text: 'Pending' }
			]);
		} else if (logType == 'Print Log') {
			setStatusDropDownOptions([
				{ key: 'Printed', text: 'Printed' },
				{ key: 'Unprinted', text: 'Unprinted' }
			]);
		} else {
			setStatusDropDownOptions([]);
		}
	}, [logType]);

	const onClickClear = () => {
		setMessage('Data cleared');
		let k = logParams;
		k.DocumentStatus = '';
		k.DocumentDateFrom = null;
		k.DocumentDateTo = null;
		k.DocumentNumberFrom = '';
		k.DocumentNumberTo = '';
		setLogParams(k);
		setLogTypeSelectedKey(null);
		setStatusSelectedKey(null);
		setLogType('');
		setStatus('');
		setDocumentNumberFrom('');
		setDocumentNumberTo('');
	};

	const onClickSubmit = () => {
		if (logType.length === 0 || logTypeSelectedKey === null)
			setLogRequired(true);
		else setLogRequired(false);
		if (status.length === 0 || statusSelectedKey === null) {
			setStatusRequired(true);
			setShowData(false);
		} else setStatusRequired(false);
		if (
			logType.length === 0 ||
			status.length === 0 ||
			statusSelectedKey === null ||
			logTypeSelectedKey === null
		) {
			setShowData(false);
		} else {
			if (logType === 'Print Log') {
				setCurrentPage(1);
				set_columns(_columns1);
				setShowData(true);
				getPrintLogsApi();
			} else if (logType === 'Import Log') {
				setCurrentPage(1);
				set_columns(_columns2);
				setShowData(true);
				getLogsApi();
			}
		}
	};

	useEffect(() => {
		let k = logParams;
		k.PageSize = pageSize;
		k.PageNumber = currentPage;
		setLogParams(k);
		if (logType == 'Import Log') {
			getLogsApi();
		} else if (logType == 'Print Log') {
			getPrintLogsApi();
		}
	}, [currentPage, pageSize]);

	const onPageChange = (startIdx, endIdx, newPageNumber) => {
		if (newPageNumber !== currentPage) {
			setCurrentPage(newPageNumber);
		}
	};

	const onPageSizeChange = (newPageSize) => {
		setPageSize(newPageSize);
		setCurrentPage(1);
	};

	const paginationProps = {
		pageCount: maxPage,
		selectedPage: currentPage,
		previousPageAriaLabel: 'Prev button click to go to previous page',
		nextPageAriaLabel: 'Next button click to go to next page',
		inputFieldAriaLabel: 'page number',
		onPageChange: onPageChange
	};

	const paginationPageSizeProps = {
		pageSize: pageSize,
		pageSizeList: displayItemsOptions,
		comboBoxAriaLabel: 'Display items per page',
		onPageSizeChange: onPageSizeChange
	};

	const onChangeStatus = (event, item) => {
		setStatusSelectedKey(item.key);
		let k = logParams;
		k.DocumentStatus = item.text;
		setLogParams(k);
		setStatus(item);
	};

	const onChangeLogType = (event, item) => {
		setLogType(item.text);
		setLogTypeSelectedKey(item.key);
		setStatusSelectedKey(null);
	};

	const onChangeInvoiceFrom = (event, item) => {
		setDocumentNumberFrom(item);
	};
	const onChangeInvoiceTo = (event, item) => {
		setDocumentNumberTo(item);
	};

	const onChangeStartDate = (event) => {
		let k = logParams;
		k.DocumentDateFrom = event;
	};
	const onChangeEndDate = (event) => {
		let k = logParams;
		k.DocumentDateTo = event;
	};

	const onFormatDateData = (date) => {
		const d = dayjs(date);
		return (
			d.get('year').toString() +
			'-' +
			(d.get('month') + 1).toString() +
			'-' +
			d.get('date').toString()
		);
	};

	const onFormatDateField = (date) => {
		return !date
			? ''
			: date.getDate() +
					' ' +
					months[date.getMonth()] +
					' ' +
					date.getFullYear();
	};

	const getLogsApi = async () => {
		dispatch(showLoader());

		const documentDateFrom = logParams.DocumentDateFrom
			? onFormatDateData(logParams.DocumentDateFrom)
			: '';
		const documentDateTo = logParams.DocumentDateTo
			? onFormatDateData(logParams.DocumentDateTo)
			: '';
		let k = logParams;
		k.DocumentNumberFrom = documentNumberFrom;
		k.DocumentNumberTo = documentNumberTo;
		setLogParams(k);

		const resp = await getRealLogsData(
			k,
			documentDateFrom,
			documentDateTo,
			subDimensionID
		);
		if (resp.data[0]) {
			resp.data.forEach((data) => {
				data.date === null
					? (data.importDate = 'Invalid Date')
					: (data.importDate = dayjs(data.importDate));
				data.isImport ? (data.isImport = 'True') : (data.isImport = 'False');
			});
			setPageData(resp.data);
			setMaxPage(Math.ceil(resp.data[0].importLogCount / pageSize));
			setMessage(`Loading, ${resp.data[0].importLogCount} Import logs found`);
			setTotalCount(resp.data[0].totalCount);
		} else {
			setPageData([]);
			setMaxPage(0);
			setTotalCount(0);
		}
		dispatch(hideLoader());
	};

	const getPrintLogsApi = async () => {
		dispatch(showLoader());

		const documentDateFrom = logParams.DocumentDateFrom
			? onFormatDateData(logParams.DocumentDateFrom)
			: '';
		const documentDateTo = logParams.DocumentDateTo
			? onFormatDateData(logParams.DocumentDateTo)
			: '';
		let k = logParams;
		k.DocumentNumberFrom = documentNumberFrom;
		k.DocumentNumberTo = documentNumberTo;
		setLogParams(k);

		const resp = await getRealPrintLogsData(
			k,
			documentDateFrom,
			documentDateTo,
			subDimensionID
		);
		if (resp.data[0]) {
			resp.data.forEach((data) => {
				data.printDate === null
					? (data.printDate = 'Invalid Date')
					: (data.printDate = dayjs(data.printDate));
			});
			setPageData(resp.data);
			setMaxPage(Math.ceil(resp.data[0].printLogCount / pageSize));
			setMessage(`Loading, ${resp.data[0].printLogCount} Print logs found`);
			setTotalCount(resp.data[0].totalCount);
		} else {
			setPageData([]);
			setMaxPage(0);
			setTotalCount(0);
		}
		dispatch(hideLoader());
	};

	const dropdownStyles = { dropdown: { width: 140 } };
	const datePickerStyles = { root: { width: 130 } };
	const textStyles = { root: { width: 137 } };

	const paginationStyles = {
		root: {
			display: 'flex'
		}
	};

	return (
		<Page title={'Logs'}>
			<Stack horizontal horizontalAlign='space-between' wrap>
				<Dropdown
					label='Log Type'
					onChange={onChangeLogType}
					required
					placeholder='Select an option'
					aria-label={true}
					selectedKey={logTypeSelectedKey}
					options={logTypeDropdownOptions}
					errorMessage={logRequired ? 'Please provide a name' : ''}
					styles={dropdownStyles}
				/>
				<Dropdown
					label='Status'
					required
					onChange={onChangeStatus}
					placeholder='Status'
					selectedKey={statusSelectedKey}
					options={statusDropDownOptions}
					errorMessage={statusRequired ? 'Please provide a status' : ''}
					styles={dropdownStyles}
				/>
				<DatePicker
					label='Start Date'
					placeholder='Select a date'
					ariaLabel='Select a date'
					strings={defaultDatePickerStrings}
					styles={datePickerStyles}
					onSelectDate={onChangeStartDate}
					value={logParams.DocumentDateFrom}
					formatDate={onFormatDateField}
				/>
				<DatePicker
					label='End Date'
					placeholder='Select a date'
					ariaLabel='Select a date'
					strings={defaultDatePickerStrings}
					styles={datePickerStyles}
					onSelectDate={onChangeEndDate}
					value={logParams.DocumentDateTo}
					formatDate={onFormatDateField}
				/>
				<TextField
					label='Invoice From'
					onChange={onChangeInvoiceFrom}
					styles={textStyles}
					value={documentNumberFrom}
				/>
				<TextField
					label='Invoice To'
					onChange={onChangeInvoiceTo}
					styles={textStyles}
					value={documentNumberTo}
				/>
			</Stack>
			<div className='form-btns'>
				{/* <LiveAnnouncer>
					<LiveMessage message={message} aria-live='polite' /> */}
					<PrimaryButton
						className='form-btn'
						text='Submit'
						allowDisabledFocus
						onClick={onClickSubmit}
					/>
					<DefaultButton
						className='form-btn'
						text='Clear'
						allowDisabledFocus
						onClick={onClickClear}
					/>
				{/* </LiveAnnouncer> */}
			</div>
			<hr className='line' />

			{pageData && showData ? (
				<div>
					{totalCount !== 0 ? (
						<div className={Styles.spacer}>
							<CoherenceDataGrid
								listConfig={_columns}
								data={pageData}
								isSortable={true}
							/>
						</div>
					) : (
						''
					)}
					<LiveAnnouncer>
						<LiveMessage message={pageData.length * maxPage + ' Results found'} aria-live='polite' />
					</LiveAnnouncer>
					<div className='table-footer-log'>
						<div className='results-found-log'>
							<span className='results-found-value-log'>
								{pageData.length * maxPage}
							</span>
							Results found
						</div>
						{maxPage > 1 ? (
							<CoherencePagination
								styles={paginationStyles}
								{...paginationProps}
							/>
						) : (
							''
						)}
						<div className='page-size-log'>
							<CoherencePageSize {...paginationPageSizeProps} />
						</div>
					</div>
				</div>
			) : (
				''
			)}
			{showData && !pageData ? (
				<div className='results-found-log'>
					<span className='results-found-value-log'>{0}</span>
					Results found
				</div>
			) : (
				''
			)}
		</Page>
	);
}

export default Log;
