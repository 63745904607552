import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { apiInstance } from "./requestInterceptor";
import { refreshAccessToken } from '../utils';
import axios from 'axios';

export function getAllUpstreams(subsDimentionId) {
  return apiInstance.get(`/User/AllUpstreams?subsDimentionId=${subsDimentionId}`);
}

export async function getUpstream() {
  //TODO: Remove the nex 2 line when auth enabled. Also remove the query string from call.
  const token = await refreshAccessToken();
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getAllAccounts()[0];
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/User/Upstreams?email=${account.username}`,
    // `https://iptapiuat.azurewebsites.net/api/v1.0/User/Upstreams?email=${account.username}`,
    headers: {
      upstream: 'SAP',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getRoles(){
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getAllAccounts()[0];
  let email= account.username
let user = email.substring(0,email.indexOf('@'))
return apiInstance.get(`/User/GetUserWithRoles?userAlias=${user}`)
}

export function saveUpstream(upstream) {
  return apiInstance.post("/upstream", {
    upstream: upstream,
  });
}
