import React, { useState, useMemo, useEffect } from 'react';
import { useConst } from '@fluentui/react-hooks';
import { MessageBarType, MessageBar } from '@fluentui/react';
import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import 'office-ui-fabric-core/dist/css/fabric.css';
import { Panel } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react';
import {
	DefaultButton,
	PrimaryButton,
	IconButton
} from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import {
	getBusinessSubType,
	getBusinessType,
	createCustomerData,
	createCustomerDataTaiwan
} from '../../services/customer';
import { useSelector } from 'react-redux';
import {
	saveSubBusinessType,
	updateSubBusinessType,
	newBusinessType,
	updateBusinessType,
	deleteBusinessType,
	deleteBusinessSubType,
	newSubBusinessType
} from '../../services/customer';
import { msalConfig } from '../../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import Confirm from '../../components/Confirm';

import { LiveAnnouncer, LiveMessage } from 'react-aria-live';

const horizontalAlignmentOptions = [
	{ key: 'start', text: 'Left' },
	{ key: 'center', text: 'Center' },
	{ key: 'end', text: 'Right' },
	{ key: 'space-around', text: 'Space around' },
	{ key: 'space-between', text: 'Space between' },
	{ key: 'space-evenly', text: 'Space evenly' }
];

const stackTokens = { childrenGap: 30 };
const stackTokensButton = { childrenGap: 10 };

export function AddCustomerData(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenNew, setIsOpenNew] = useState(false);
	const [isOpenBusinessNew, setIsOpenBusinessNew] = useState(false);
	const [isOpenBusiness, setIsOpenBusiness] = useState(false);
	const [showAlertFail, setShowAlertFail] = useState(false);
	const [showAlertSuccess, setShowAlertSuccess] = useState(false);
	const [inputList, setInputList] = useState([{ Address: '' }]);
	const [inputList2, setInputList2] = useState([{ Address: '' }]);
	const [inputListTaiwan, setInputListTaiwan] = useState([{ Address: '' }]);
	const [editParams, setEditParams] = useState({});
	const [editParamsBusiness, setEditParamsBusiness] = useState({});
	const [businessTypeOption, setBusinessTypeOption] = useState([]);
	const [businessSubTypeOption, setBusinessSubTypeOption] = useState([]);
	const [deleteOpenType, setDeleteOpenType] = useState(false);
	const [deleteOpenSubType, setDeleteOpenSubType] = useState(false);
	const [businessTypeAndSubTypeSuccess, setBusinessTypeAndSubTypeSuccess] =
		useState(false);
	const [businessTypeAndSubTypeError, setBusinessTypeAndSubTypeError] =
		useState(false);
	const [subTypeNewCode, setSubTypeNewCode] = useState('');
	const [subTypeNewDescription, setSubTypeNewDescription] = useState('');
	const [typeNewCode, setTypeNewCode] = useState('');
	const [isDataSaved, setIsDataSaved] = useState(false);
	const [typeNewDescription, setTypeNewDescription] = useState('');
	const [businessSubTypeFromPanelParams, setBusinessSubTypeFromPanelParams] =
		useState({
			subsDimensionID: 0,
			userName: 'cdfg',
			bussinessSubTypeCode: '',
			bussinessSubTypeDescription: ''
		});
	const [panelMode, setPanelMode] = useState('');
	const [lol, setLol] = useState('hahahahah');
	//const [account, setAccount] = useState("")
	const [subTypeSelectedKey, setSubTypeSelectedKey] = useState(null);
	const [customerDataValues, setCustomerDataValues] = useState({
		customerCode: '',
		shipToCode: '',
		soldToName: '',
		shipToName: '',
		soldToAddress1: '',
		soldToAddress2: '',
		soldToAddress3: '',
		soldToAddress4: '',
		shipToAddress1: '',
		shipToAddress2: '',
		shipToAddress3: '',
		shipToAddress4: '',
		contactName: '',
		businessRegisterNo: '',
		salespersonCode: '',
		termsCode: '',
		taxCode: '',
		businessType: '',
		businessSubType: ''
	});
	const [customerDataValuesTaiwan, setCustomerDataValuesTaiwan] = useState({
		customerCode: '',
		shipToCode: '',
		shortName: '',
		addressName: '',
		address1: '',
		address2: '',
		address3: '',
		address4: '',
		addressSort1: '',
		attentionName: '',
		attentionPhone: '',
		contactPhone: '',
		contactName: '',
		termsCode: '',
		taxCode: '',
		comment: ''
	});
	const [alertMessage, setAlertMessage] = useState('');

	const user = useSelector((state) => state.user);

	const subsDimensionID = user.user?.userList?.subsDimensionID;
	const msalInstance = new PublicClientApplication(msalConfig);
	const account = msalInstance.getAllAccounts()[0].username;

	useEffect(() => {
		if (subsDimensionID === 2) {
			document.title="Invoice print tool-Add Customer Data"
			setBusinessTypeOptions();
			setBusinessSubTypeOptions();
			// const msalInstance = new PublicClientApplication(msalConfig);
			// setAccount(msalInstance.getAllAccounts()[0].username)
			setCustomerDataValues({
				...customerDataValues,
				subsDimensionID: subsDimensionID
			});
			setBusinessSubTypeFromPanelParams({
				...businessSubTypeFromPanelParams,
				subsDimensionID: subsDimensionID
			});
		}
		if (subsDimensionID === 1) {
		}
	}, [subsDimensionID]);
	const dismissPanel = () => {
		setIsOpen(false);
	};
	const dismissPanelBusiness = () => {
		setIsOpenBusiness(false);
	};
	const dismissPanelNew = () => {
		setSubTypeNewCode('');
		setSubTypeNewDescription('');
		setIsOpenNew(false);
	};
	const dismissPanelBusinessNew = () => {
		setTypeNewCode('');
		setTypeNewDescription('');
		setIsOpenBusinessNew(false);
	};
	const onClickSubTypeNewSave = () => {
		newSubBusinessTypee();

		setIsOpenNew(false);
	};
	const onClickTypeNewSave = () => {
		newBusinessTypee();
		setIsOpenBusinessNew(false);
	};
	const onClickSubTypeNewCancel = () => {
		setSubTypeNewDescription('');
		setSubTypeNewCode('');
		setIsOpenNew(false);
	};
	const onClickTypeNewCancel = () => {
		setTypeNewDescription('');
		setTypeNewCode('');
		setIsOpenBusinessNew(false);
	};
	const onClickSubTypeEditSave = () => {
		updateSubBusinessTypee();

		setIsOpen(false);
	};
	const onClickTypeEditSave = () => {
		updateBusinessTypee();

		setIsOpenBusiness(false);
	};
	const onClickSubTypeEditCancel = () => {
		setIsOpen(false);
	};
	const onClickTypeEditCancel = () => {
		setIsOpenBusiness(false);
	};
	const onChangeSubTypeCodeEdit = (event, value) => {
		setEditParams({ ...editParams, text: value });
	};
	const onChangeTypeCodeEdit = (event, value) => {
		setEditParamsBusiness({ ...editParamsBusiness, text: value });
	};
	const onChangeSubTypeDescEdit = (event, value) => {
		setEditParams({ ...editParams, desc: value });
	};
	const onChangeTypeDescEdit = (event, value) => {
		setEditParamsBusiness({ ...editParamsBusiness, desc: value });
	};
	const onChangeSubTypeNewDescription = (k, val) => {
		setSubTypeNewDescription(val);
	};
	const onChangeTypeNewDescription = (k, val) => {
		setTypeNewDescription(val);
	};
	const onChangeSubTypeNewCode = (k, val) => {
		setSubTypeNewCode(val);
	};
	const onChangeTypeNewCode = (k, val) => {
		setTypeNewCode(val);
	};
	const newBusinessTypee = async (k) => {
		const resp = await newBusinessType(
			typeNewCode,
			typeNewDescription,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const newSubBusinessTypee = async (k) => {
		const resp = await newSubBusinessType(
			subTypeNewCode,
			subTypeNewDescription,
			subsDimensionID,
			account
		);

		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const updateSubBusinessTypee = async (k) => {
		const resp = await updateSubBusinessType(
			editParams,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const updateBusinessTypee = async (k) => {
		const resp = await updateBusinessType(
			editParamsBusiness,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const deleteBusinessTypee = async (k) => {
		const resp = await deleteBusinessType(
			editParamsBusiness,
			subsDimensionID,
			account
		);
		setDeleteOpenType(false);
		if (resp.data === 1) {
			setBusinessTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const deleteBusinessSubTypee = async (k) => {
		const resp = await deleteBusinessSubType(
			editParams,
			subsDimensionID,
			account
		);
		setDeleteOpenSubType(false);
		if (resp.data === 1) {
			setBusinessSubTypeOptions();
			setBusinessTypeAndSubTypeSuccess(true);
			setAlertMessage('Action completed successfully');
		} else {
			setBusinessTypeAndSubTypeError(true);
			setAlertMessage('Error occured while performing the action');
		}
		setTimeout(() => {
			setBusinessTypeAndSubTypeSuccess(false);
			setBusinessTypeAndSubTypeError(false);
			setAlertMessage('');
		}, 7000);
	};
	const setBusinessTypeOptions = async () => {
		const resp = await getBusinessType(subsDimensionID);
		let x = 1;
		let businessTypeList = [];
		resp.data.forEach((element) => {
			let businessTypeRow = {
				key: x,
				text: element.businessTypeCode,
				desc: element.businessTypeDescription
			};
			businessTypeList.push(businessTypeRow);
			x = x + 1;
		});
		setBusinessTypeOption(businessTypeList);
	};
	const setBusinessSubTypeOptions = async () => {
		const resp = await getBusinessSubType(subsDimensionID);
		let x = 1;
		let businessSubTypeList = [];
		resp.data.forEach((element) => {
			let businessSubTypeRow = {
				key: x,
				text: element.businessSubTypeCode,
				desc: element.businessSubTypeDescription
			};
			businessSubTypeList.push(businessSubTypeRow);
			x = x + 1;
		});
		setBusinessSubTypeOption(businessSubTypeList);
	};

	const onChangeAddress = (i, e) => {
		let k = 'soldToAddress' + (i + 1);
		setCustomerDataValues({ ...customerDataValues, [k]: e.target.value });
	};
	const onChangeAddress2 = (i, e) => {
		// const list = [...inputList2]

		// list[i]["Address"] = e.target.value
		// setInputList2(list)
		let k = 'shipToAddress' + (i + 1);
		setCustomerDataValues({ ...customerDataValues, [k]: e.target.value });
	};
	const handleRemoveClick = (index) => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};
	const handleRemoveClick2 = (index) => {
		const list = [...inputList2];
		list.splice(index, 1);
		setInputList2(list);
	};
	const handleAddClick = (index) => {
		setInputList([...inputList, { Address: '' }]);
	};
	const handleAddClick2 = (index) => {
		setInputList2([...inputList2, { Address: '' }]);
	};

	const goBack = () => {
		props.history.push('/customers');
	};
	const createCustomerDataa = async () => {
		let resp = await createCustomerData(
			customerDataValues,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setShowAlertSuccess(true);
			setAlertMessage('Customer added successfully');
		} else {
			setShowAlertFail(true);
			setAlertMessage('Error occured while adding customer');
		}
		setTimeout(() => {
			setShowAlertSuccess(false);
			setShowAlertFail(false);
			setAlertMessage('');
		}, 7000);
	};
	const createCustomerDataTaiwann = async () => {
		let resp = await createCustomerDataTaiwan(
			customerDataValuesTaiwan,
			subsDimensionID,
			account
		);
		if (resp.data === 1) {
			setShowAlertSuccess(true);
			setAlertMessage('Customer added successfully');
		} else {
			setShowAlertFail(true);
			setAlertMessage('Error occured while adding customer');
		}
		setTimeout(() => {
			setShowAlertSuccess(false);
			setShowAlertFail(false);
			setAlertMessage('');
		}, 7000);
	};
	const onClickSave = () => {
		createCustomerDataa();
	};
	const onClickSaveTaiwan = () => {
		createCustomerDataTaiwann();
		setIsDataSaved(true);
	};

	const onChangeCustomerCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, customerCode: value });
	};
	const onChangeSoldToName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, soldToName: value });
	};
	const onChangeTaxCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, taxCode: value });
	};
	const onChangeTermsCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, termsCode: value });
	};
	const onChangeContactName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, contactName: value });
	};
	const onChangeSalesPersonCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, salespersonCode: value });
	};
	const onChangeShipToCode = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, shipToCode: value });
	};
	const onChangeShipToName = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, shipToName: value });
	};
	const onChangeBusinessRegisterNumber = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, businessRegisterNo: value });
	};
	const onChangeShipToAddress1 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, shipToAddress1: value });
	};
	const onChangeShipToAddress2 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, shipToAddress2: value });
	};
	const onChangeShipToAddress3 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, shipToAddress3: value });
	};
	const onChangeSoldToAddress1 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, soldToAddress1: value });
	};
	const onChangeSoldToAddress2 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, soldToAddress2: value });
	};
	const onChangeSoldToAddress3 = (event, value) => {
		setCustomerDataValues({ ...customerDataValues, soldToAddress3: value });
	};
	const onChangeBusinessType = (event, value) => {
		setEditParamsBusiness(value);
		setCustomerDataValues({ ...customerDataValues, businessType: value.text });
	};
	const onChangeBusinessSubType = (event, value) => {
		setEditParams(value);

		setSubTypeSelectedKey(value.key);
		setCustomerDataValues({
			...customerDataValues,
			businessSubType: value.text
		});
	};

	//taiwan onChange below

	const onChangeCustomerCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			customerCode: value
		});
	};
	const onChangeShortNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			shortName: value
		});
	};
	const onChangeAddressNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			addressName: value
		});
	};
	const onChangeAddressTaiwan = (i, e) => {
		let k = 'address' + (i + 1);
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			[k]: e.target.value
		});
	};
	const onChangeAddressSort1Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			addressSort1: value
		});
	};
	const onChangeAddress1Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address1: value
		});
	};
	const onChangeAddress2Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address2: value
		});
	};
	const onChangeAddress3Taiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			address3: value
		});
	};
	const onChangeShipToCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			shipToCode: value
		});
	};
	const onChangeAttentionNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			attentionName: value
		});
	};
	const onChangeAttentionPhoneTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			attentionPhone: value
		});
	};
	const onChangeContactNameTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			contactName: value
		});
	};
	const onChangeContactPhoneTaiwan = (event, value) => {
		if(!isNaN(value)){
			setCustomerDataValuesTaiwan({
				...customerDataValuesTaiwan,
				contactPhone: value
			});
		}
	};
	const onChangeTaxCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			taxCode: value
		});
	};
	const onChangeTermsCodeTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			termsCode: value
		});
	};
	const onChangeCommentTaiwan = (event, value) => {
		setCustomerDataValuesTaiwan({
			...customerDataValuesTaiwan,
			comment: value
		});
	};
	const handleRemoveClickTaiwan = (index) => {
		const list = [...inputListTaiwan];
		list.splice(index, 1);
		setInputListTaiwan(list);
	};
	const handleAddClickTaiwan = (index) => {
		setInputListTaiwan([...inputListTaiwan, { Address: '' }]);
	};

	const menuProps = useConst(() => ({
		shouldFocusOnMount: true,
		items: [
			{
				key: 'newItem',
				text: 'New',
				iconProps: { iconName: 'Add' },
				onClick: () => setIsOpenNew(true)
			},
			{
				key: 'edit',
				text: 'Edit',
				iconProps: { iconName: 'Edit' },
				onClick: () => setIsOpen(true)
			},
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => setDeleteOpenSubType(true)
			}
		]
	}));
	const menuPropsBusiness = useConst(() => ({
		shouldFocusOnMount: true,
		items: [
			{
				key: 'newItem',
				text: 'New',
				iconProps: { iconName: 'Add' },
				onClick: () => setIsOpenBusinessNew(true)
			},
			{
				key: 'edit',
				text: 'Edit',
				iconProps: { iconName: 'Edit' },
				onClick: () => setIsOpenBusiness(true)
			},
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => setDeleteOpenType(true)
			}
		]
	}));

	return (
		<div>
			{subsDimensionID === 2 && (
				<div className='ms-Grid' dir='ltr' role='main'>
					<div className='ms-Grid-row'>
						<h2 className='headerLabel'>Add Customer Data</h2>
						<Panel
							className='businessTypePanel'
							headerText='Business Sub Type'
							isOpen={isOpen}
							onDismiss={dismissPanel}
							closeButtonAriaLabel='Close'
						>
							<Stack className='contentHolder' tokens={stackTokens}>
								<TextField
									label='Business Sub Type Code'
									value={editParams.text}
									onChange={onChangeSubTypeCodeEdit}
									required
								/>
								<TextField
									label='Description'
									value={editParams.desc}
									onChange={onChangeSubTypeDescEdit}
									required
								/>
							</Stack>
							<hr />
							<Stack horizontal tokens={stackTokens}>
								<PrimaryButton
									text='Save'
									onClick={onClickSubTypeEditSave}
									allowDisabledFocus
								/>
								<DefaultButton
									text='Cancel'
									onClick={onClickSubTypeEditCancel}
									allowDisabledFocus
								/>
							</Stack>
						</Panel>
						<Panel
							className='businessTypePanel'
							headerText='Business Sub Type'
							isOpen={isOpenNew}
							onDismiss={dismissPanelNew}
							closeButtonAriaLabel='Close'
						>
							<Stack className='contentHolder' tokens={stackTokens}>
								<TextField
									label='Business Sub Type Code'
									value={subTypeNewCode}
									onChange={onChangeSubTypeNewCode}
									required
								/>
								<TextField
									label='Description'
									value={subTypeNewDescription}
									onChange={onChangeSubTypeNewDescription}
									required
								/>
							</Stack>
							<hr />
							<Stack horizontal tokens={stackTokens}>
								<PrimaryButton
									text='Save'
									onClick={onClickSubTypeNewSave}
									allowDisabledFocus
								/>
								<DefaultButton
									text='Cancel'
									onClick={onClickSubTypeNewCancel}
									allowDisabledFocus
								/>
							</Stack>
						</Panel>
						<Panel
							className='businessTypePanel'
							headerText='Business Type'
							isOpen={isOpenBusiness}
							onDismiss={dismissPanelBusiness}
							closeButtonAriaLabel='Close'
						>
							<Stack className='contentHolder' tokens={stackTokens}>
								<TextField
									label='Business Type Code'
									value={editParamsBusiness.text}
									onChange={onChangeTypeCodeEdit}
									required
								/>
								<TextField
									label='Description'
									value={editParamsBusiness.desc}
									onChange={onChangeTypeDescEdit}
									required
								/>
							</Stack>
							<hr />
							<Stack horizontal tokens={stackTokens}>
								<PrimaryButton
									text='Save'
									onClick={onClickTypeEditSave}
									allowDisabledFocus
								/>
								<DefaultButton
									text='Cancel'
									onClick={onClickTypeEditCancel}
									allowDisabledFocus
								/>
							</Stack>
						</Panel>
						<Panel
							className='businessTypePanel'
							headerText='Business  Type'
							isOpen={isOpenBusinessNew}
							onDismiss={dismissPanelBusinessNew}
							closeButtonAriaLabel='Close'
						>
							<Stack className='contentHolder' tokens={stackTokens}>
								<TextField
									label='Business  Type Code'
									value={typeNewCode}
									onChange={onChangeTypeNewCode}
									required
								/>
								<TextField
									label='Description'
									value={typeNewDescription}
									onChange={onChangeTypeNewDescription}
									required
								/>
							</Stack>
							<hr />
							<Stack horizontal tokens={stackTokens}>
								<PrimaryButton
									text='Save'
									onClick={onClickTypeNewSave}
									allowDisabledFocus
								/>
								<DefaultButton
									text='Cancel'
									onClick={onClickTypeNewCancel}
									allowDisabledFocus
								/>
							</Stack>
						</Panel>
					</div>
					<div className='ms-Grid contentPadding' dir='ltr'>
						<div className='ms-Grid-row'>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Customer Code'
										value={customerDataValues.customerCode}
										onChange={onChangeCustomerCode}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Sold to Name'
										value={customerDataValues.soldToName}
										onChange={onChangeSoldToName}
										required
									/>
									{/* {inputList.map((x, i) => {
                                let val = "soldToAddress" + (i + 1)
                                return (
                                    <div key={i}>
                                        <Stack >
                                            <Stack horizontal >
                                                <TextField
                                                    className="ms-Grid-col ms-lg11 ms-sm11"
                                                    label={"Sold to Address " + (i + 1)}
                                                    value={customerDataValues[val]}
                                                    onChange={(e) => onChangeAddress(i, e)}
                                                />
                                                {inputList.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                            </Stack>
                                            {inputList.length - 1 === i && i!==2&& <div className="addAddressPadding"> <IconButton onClick={handleAddClick} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Sold to' address" style={{ marginTop: "auto" }}> </IconButton><span className="label">Add Address</span></div>}
                                        </Stack>
                                    </div>)
                            })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 1'}
										value={customerDataValues.soldToAddress1}
										onChange={onChangeSoldToAddress1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 2'}
										value={customerDataValues.soldToAddress2}
										onChange={onChangeSoldToAddress2}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Sold to Address 3'}
										value={customerDataValues.soldToAddress3}
										onChange={onChangeSoldToAddress3}
									/>

									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Name'
										value={customerDataValues.contactName}
										onChange={onChangeContactName}
									/>
									<Stack
										horizontal
										horizontalAlign={horizontalAlignmentOptions}
										verticalAlign='end'
										tokens={{ childrenGap: 25 }}
										className='ms-Grid-col ms-lg11 ms-sm11'
									>
										<TextField
											label='Salesperson Code'
											value={customerDataValues.salespersonCode}
											onChange={onChangeSalesPersonCode}
										/>
										<TextField
											label='Terms Code'
											value={customerDataValues.termsCode}
											onChange={onChangeTermsCode}
										/>
										<TextField
											label='Tax Code'
											value={customerDataValues.taxCode}
											onChange={onChangeTaxCode}
										/>
									</Stack>
								</Stack>
							</div>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Code'
										value={customerDataValues.shipToCode}
										onChange={onChangeShipToCode}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Name'
										value={customerDataValues.shipToName}
										onChange={onChangeShipToName}
									/>
									{/* {inputList2.map((x, i) => {
                                let val = "shipToAddress" + (i + 1)
                                return (
                                    <Stack key={i}>
                                        <Stack horizontal>
                                            <TextField
                                                className="ms-Grid-col ms-lg11 ms-sm11"
                                                label={"Ship to Address " + (i + 1)}
                                                value={customerDataValues[val]}
                                                onChange={(e) => onChangeAddress2(i, e)}
                                            /> {inputList2.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick2(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                        </Stack>
                                        {inputList2.length - 1 === i && i!==2&& <div className="addAddressPadding"><IconButton onClick={handleAddClick2} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Ship to' address" label="Add address" style={{ marginTop: "auto" }}></IconButton><span className="label">Add Address</span></div>}
                                    </Stack>)
                            })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 1'}
										value={customerDataValues.shipToAddress1}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 2'}
										value={customerDataValues.shipToAddress2}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress2}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label={'Ship to Address 3'}
										value={customerDataValues.shipToAddress3}
										//onChange={(e) => onChangeAddress2(i, e)}
										onChange={onChangeShipToAddress3}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Business Register Number'
										value={customerDataValues.businessRegisterNo}
										onChange={onChangeBusinessRegisterNumber}
									/>
									<Stack
										horizontal
										tokens={{ childrenGap: 20 }}
										verticalAlign='end'
										wrap
										className='ms-Grid-col ms-lg11 ms-sm11'
									>
										<Stack
											horizontal
											className='ms-lg6 ms-sm6 businessTypeDropdown'
										>
											<Dropdown
												label='Business Type'
												className='ms-lg11 ms-sm11'
												onChange={onChangeBusinessType}
												placeholder='Select an option'
												options={businessTypeOption}
											/>
											<IconButton
												menuProps={menuPropsBusiness}
												iconProps={{ iconName: 'More' }}
												title='More'
												ariaLabel='More'
												style={{ marginTop: 'auto' }}
											/>
											{/* <IconButton menuProps={menuProps} iconProps={{ iconName: 'More' }} title="More" ariaLabel="More" style={{ marginTop: "auto" }} /> */}
										</Stack>
										<Stack
											horizontal
											className='ms-lg6 ms-sm6 businessTypeDropdown'
										>
											<Dropdown
												className='ms-lg11 ms-sm11'
												label='Business Sub Type'
												onChange={onChangeBusinessSubType}
												placeholder='Select an option'
												selectedKey={subTypeSelectedKey}
												options={businessSubTypeOption}
											/>
											<IconButton
												menuProps={menuProps}
												iconProps={{ iconName: 'More' }}
												title='More'
												ariaLabel='More'
												style={{ marginTop: 'auto' }}
											/>
											{/* <IconButton menuProps={menuProps} iconProps={{ iconName: 'More' }} title="More" ariaLabel="More" style={{ marginTop: "auto" }} /> */}
										</Stack>
									</Stack>
								</Stack>
							</div>
						</div>
						<hr></hr>
						{deleteOpenSubType && (
							<Confirm
								message='Do you wish to delete this Business Sub Type?'
								open={deleteOpenSubType}
								onClose={(confirm) => {
									if (confirm) {
										deleteBusinessSubTypee();
									} else {
										setDeleteOpenSubType(false);
									}
								}}
							/>
						)}
						{deleteOpenType && (
							<Confirm
								message='Do you wish to delete this Business Type?'
								open={deleteOpenType}
								onClose={(confirm) => {
									if (confirm) {
										deleteBusinessTypee();
									} else {
										setDeleteOpenType(false);
									}
								}}
							/>
						)}
						<LiveAnnouncer>
							<LiveMessage message={alertMessage} aria-live='polite' />
							{showAlertFail && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.error}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Error occured while adding customer
								</MessageBar>
							)}
							{showAlertSuccess && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.success}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Customer added successfully
								</MessageBar>
							)}
							{businessTypeAndSubTypeSuccess && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.success}
								>
									Action completed successfully
								</MessageBar>
							)}
							{businessTypeAndSubTypeError && (
								<MessageBar
									delayedRender={false}
									messageBarType={MessageBarType.error}
								>
									Error occured while performing the action
								</MessageBar>
							)}
						</LiveAnnouncer>
						<Stack
							horizontal
							horizontalAlign='end'
							tokens={stackTokensButton}
							className=' footerPadding ms-sm11 ms-lg12'
						>
							<PrimaryButton
								text='Save'
								onClick={onClickSave}
								allowDisabledFocus
								disabled={
									customerDataValues.shipToCode.length < 1 ||
									customerDataValues.soldToName.length < 1 ||
									customerDataValues.customerCode.length < 1
								}
							/>
							<DefaultButton
								text='Cancel'
								onClick={goBack}
								allowDisabledFocus
							/>
						</Stack>
					</div>
				</div>
			)}
			{subsDimensionID === 1 && (
				<div className='ms-Grid' dir='ltr' role='main'>
					<div className='ms-Grid-row'>
						<h2 className='headerLabel'>Add Customer Data</h2>
					</div>
					<div className='ms-Grid contentPadding' dir='ltr'>
						<div className='ms-Grid-row'>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Customer Code'
										value={customerDataValuesTaiwan.customerCode}
										onChange={onChangeCustomerCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='ShortName'
										value={customerDataValuesTaiwan.shortName}
										onChange={onChangeShortNameTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address Name'
										value={customerDataValuesTaiwan.soldToName}
										onChange={onChangeAddressNameTaiwan}
										required
									/>
									{/* {inputListTaiwan.map((x, i) => {
                                let val = "address" + (i + 1)
                                return (
                                    <div key={i}>
                                        <Stack >
                                            <Stack horizontal >
                                                <TextField
                                                    className="ms-Grid-col ms-lg11 ms-sm11"
                                                    label={"Address " + (i + 1)}
                                                    value={customerDataValuesTaiwan[val]}
                                                    onChange={(e) => onChangeAddressTaiwan(i, e)}
                                                    required={i+1==1}
                                                />
                                                {inputListTaiwan.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClickTaiwan(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                            </Stack>
                                            {inputListTaiwan.length - 1 === i && i!==2&& <div className="addAddressPadding"> <IconButton onClick={handleAddClickTaiwan} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Sold to' address" style={{ marginTop: "auto" }}> </IconButton><span className="label">Add Address</span></div>}
                                        </Stack>
                                    </div>)
                            })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 1'
										value={customerDataValuesTaiwan.address1}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress1Taiwan}
										//required={i+1==1}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 2'
										value={customerDataValuesTaiwan.address2}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress2Taiwan}
										//required={i+1==1}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address 3'
										value={customerDataValuesTaiwan.address3}
										// onChange={(e) => onChangeAddressTaiwan(i, e)}
										onChange={onChangeAddress3Taiwan}
										//required={i+1==1}
									/>

									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Address Sort'
										value={customerDataValuesTaiwan.addressSort1}
										onChange={onChangeAddressSort1Taiwan}
										required
									/>
									{/* <Stack horizontal horizontalAlign={horizontalAlignmentOptions} tokens={{ childrenGap: 25 }} className="ms-Grid-col ms-lg11 ms-sm11">
                                <TextField
                                    label="Salesperson Code"
                                    value={customerDataValuesTaiwan.salespersonCode}
                                    onChange={onChangeSalesPersonCode}
                                />
                                <TextField
                                    label="Terms Code"
                                    value={customerDataValuesTaiwan.termsCode}
                                    onChange={onChangeTermsCode}
                                />
                                <TextField
                                    label="Tax Code"
                                    value={customerDataValuesTaiwan.taxCode}
                                    onChange={onChangeTaxCode}
                                />
                            </Stack> */}
								</Stack>
							</div>
							<div className='ms-Grid-col ms-lg5 ms-sm12'>
								<Stack tokens={{ childrenGap: 24 }}>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Ship to Code'
										value={customerDataValuesTaiwan.shipToCode}
										onChange={onChangeShipToCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Attention Name'
										value={customerDataValuesTaiwan.attentionName}
										onChange={onChangeAttentionNameTaiwan}
									/>
									{/* {inputList2.map((x, i) => {
                                let val="shipToAddress"+(i+1)
                                return (
                                    <Stack key={i}>
                                        <Stack horizontal>
                                            <TextField
                                                className="ms-Grid-col ms-lg11 ms-sm11"
                                                label={"Ship to Address " + (i + 1)}
                                                value={customerDataValuesTaiwan[val]}
                                                onChange={(e) => onChangeAddress2(i, e)}
                                            /> {inputList2.length !== 1 && <IconButton className="ms-Grid-col ms-lg1 ms-sm1" onClick={(i) => { handleRemoveClick2(i) }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" style={{ marginTop: "auto" }}></IconButton>}
                                        </Stack>
                                        {inputList2.length - 1 === i && <div className="addAddressPadding"><IconButton onClick={handleAddClick2} iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add 'Ship to' address" label="Add address" style={{ marginTop: "auto" }}></IconButton><span className="label">Add Address</span></div>}
                                    </Stack>)
                            })} */}
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Attention Phone'
										value={customerDataValuesTaiwan.attentionPhone}
										onChange={onChangeAttentionPhoneTaiwan}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Name'
										value={customerDataValuesTaiwan.contactName}
										onChange={onChangeContactNameTaiwan}
									/>
									<TextField
										// type='number'
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Contact Phone'
										aria-label='Contact phone. Only numbers are allowed to enter'
										value={customerDataValuesTaiwan.contactPhone}
										onChange={onChangeContactPhoneTaiwan}
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Tax Code'
										value={customerDataValuesTaiwan.taxCode}
										onChange={onChangeTaxCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Terms Code'
										value={customerDataValuesTaiwan.termsCode}
										onChange={onChangeTermsCodeTaiwan}
										required
									/>
									<TextField
										className='ms-Grid-col ms-lg11 ms-sm11'
										label='Comment'
										value={customerDataValuesTaiwan.comment}
										onChange={onChangeCommentTaiwan}
										required
									/>
								</Stack>
							</div>
						</div>
						<hr></hr>
						<LiveAnnouncer>
							<LiveMessage message={alertMessage} aria-live='polite' />
							{showAlertFail && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.error}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Error occured while adding customer
								</MessageBar>
							)}
							{showAlertSuccess && (
								<MessageBar
									delayedRender={false}
									// Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
									messageBarType={MessageBarType.success}
									// Or you could set the role manually, IF an alert role is appropriate for the message
									// role="alert"
								>
									Customer added successfully
								</MessageBar>
							)}
						</LiveAnnouncer>
						<Stack
							horizontal
							horizontalAlign='end'
							tokens={stackTokensButton}
							className=' footerPadding ms-sm11 ms-lg12'
						>
							<PrimaryButton
								text='Save'
								onClick={onClickSaveTaiwan}
								allowDisabledFocus
								disabled={
									customerDataValuesTaiwan.shipToCode.length < 1 ||
									customerDataValuesTaiwan.customerCode.length < 1 ||
									customerDataValuesTaiwan.taxCode.length < 1 ||
									customerDataValuesTaiwan.termsCode.length < 1 ||
									customerDataValuesTaiwan.comment.length < 1 ||
									customerDataValuesTaiwan.address1.length < 1 ||
									customerDataValuesTaiwan.addressSort1.length < 1 ||
									customerDataValuesTaiwan.shortName.length < 1 ||
									customerDataValuesTaiwan.addressName.length < 1 ||
									isDataSaved
								}
							/>
							<DefaultButton
								text='Cancel'
								onClick={goBack}
								allowDisabledFocus
							/>
						</Stack>
					</div>
				</div>
			)}
		</div>
	);
}
