import { MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';
import useNotification from '../../hooks/useNotification';

import s from './styles.module.scss';

function NotificationBar() {
	const { message, isGlobal, messageType, show, hide } = useNotification();
	return (
		<div className={s.root}>
			{!isGlobal && show && (
				<MessageBar
					aria-label={`${messageType} ${message}`}
					messageBarType={messageType}
					isMultiline={false}
					onDismiss={() => {
						hide();
					}}
					dismissButtonAriaLabel='Close'
				>
					{message}
				</MessageBar>
			)}
		</div>
	);
}

export default NotificationBar;
