import { param } from "jquery";
import { apiInstance } from "./requestInterceptor";

export function getCustomerData(customerCode,shipCode,subID){
    return apiInstance.get("/Customer/GetCustomer?customerCode="+customerCode+"&ShipToCode="+shipCode+"&SubsDimensionID="+subID)
}
export function getCustomerName(subId){
    return apiInstance.get("/Customer/GetCustomerbysubDimension?subsDimensionID="+subId)
}
export function getShipCodeByCustomerName(item){
    return apiInstance.get("/Customer/GetShiptoCodeByCustomerName?customername="+item)
}

export function getBusinessType(subId){
    return apiInstance.get("/Customer/GetBusinesstypedetails?subsDimensionID="+subId)
}


export function getBusinessSubType(subId){
    return apiInstance.get("/Customer/GetBusinessSubtypedetails?subsDimensionID="+subId)
}

// export function postCustomerData(params){
//     var json = params
//    json.userName="demo"
                                                                                                                 
//     return apiInstance.post("/Customer/ManageCustomerData?Action=CREATE",json)
// }

export function updateCustomerData(params,subsDimensionID,account){
    let json = {
        "customerCode": params.CustomerCode,
        "soldToName": params.SoldToName,
        "soldToAddress1": params.SoldToAddress1,
        "soldToAddress2": params.SoldToAddress2,
        "soldToAddress3": params.SoldToAddress3,
        "soldToAddress4": params.SoldToAddress4,
        "contactAddressName": "",
        "shipToCode": params.ShipToCode,
        "shipToName": params.ShipToName,
        "shipToAddress1": params.ShipToAddress1,
        "shipToAddress2": params.ShipToAddress2,
        "shipToAddress3": params.ShipToAddress3,
        "termsCode": params.TermsCode,
        "taxCode": params.TaxCode,
        "businessRegisterNo": params.BusinessRegisterNumber,
        "businessType": params.BusinessType,
        "businessSubType": params.BusinessSubType,
        "salesPersonCode": params.SalespersonCode,
        "addressSort": "",
        "comment": "",
        "creditLimit": "",
        "tlxtwx": "",
        "userName": account,
        "contactName": params.ContactName,
        "contact_Phone": "",
        "attention_Name": "",
        "attention_Phone": "",
        "short_Name": "",
        "subsDimensionID": subsDimensionID,
        "isActive": true
      }
                                                                                          
    return apiInstance.post("/Customer/ManageCustomerData?Action=UPDATE",json)
}
export function updateCustomerDataTaiwan(params,subsDimensionID,account){
    let json = {
        "customerCode": params.customerCode,
        "soldToName": "",
        "soldToAddress1": params.address1,
        "soldToAddress2": params.address2,
        "soldToAddress3": params.address3,
        "soldToAddress4": params.address4,
        "contactAddressName": params.addressName,
        "shipToCode": params.shipToCode,
        "shipToName": "",
        "shipToAddress1": "",
        "shipToAddress2": "",
        "shipToAddress3": "",
        "termsCode": params.termsCode,
        "taxCode": params.taxCode,
        "businessRegisterNo": "",
        "businessType": "",
        "businessSubType": "",
        "salesPersonCode": "",
        "addressSort":params.addressSort1,
        "comment": params.comment,
        "creditLimit": "",
        "tlxtwx": "",
        "userName": account,
        "contactName": params.contactName,
        "contact_Phone":params.contactPhone,
        "attention_Name": params.attentionName,
        "attention_Phone": params.attentionPhone,
        "short_Name": params.shortName,
        "subsDimensionID": subsDimensionID,
        "isActive": true
      }
                                                                                          
    return apiInstance.post("/Customer/ManageCustomerData?Action=UPDATE",json)
}

export function createCustomerData(params,subsDimensionID,account){
    params.subsDimensionID=subsDimensionID
    params.userName=account                                                                                 
    return apiInstance.post("/Customer/ManageCustomerData?Action=CREATE",params)
}
export function createCustomerDataTaiwan(params,subsDimensionID,account){
    params.subsDimensionID=subsDimensionID
    params.userName=account   
    let keys={}   
  
        keys.customerCode=params.customerCode
        keys.soldToName=""
        keys.soldToAddress1=params.address1
        keys.soldToAddress2=params.address2
        keys.soldToAddress3=params.address3
        keys.soldToAddress4=params.address4
        keys.contactAddressName=params.addressName
        keys.shipToCode=params.shipToCode
        keys.shipToName=""
        keys.shipToAddress1=""
       keys. shipToAddress2=""
        keys.shipToAddress3=""
        keys.termsCode=params.termsCode
        keys.taxCode=params.taxCode
        keys.businessRegisterNo=""
        keys.businessType=""
        keys.businessSubType=""
        keys.salesPersonCode=""
        keys.addressSort=params.addressSort1
        keys.comment=params.comment
        keys.creditLimit=""
        keys.tlxtwx=""
        keys.userName=account
        keys.contactName=params.contactName
        keys.contact_Phone=params.contactPhone
        keys.attention_Name=params.attentionName
        keys.attention_Phone=params.attentionPhone
        keys.short_Name=params.shortName
        keys.subsDimensionID=subsDimensionID
        keys.isActive= true
                                                                               
    return apiInstance.post("/Customer/ManageCustomerData?Action=CREATE",keys)
}

export function saveSubBusinessType(params){
    var json = params
   return apiInstance.post("/Customer/ManageBusinessSubType?Action=CREATE",json)
}

// export function saveSubBusinessType(params){
//     var json = params
//    return apiInstance.post("/Customer/ManageBusinessSubType?Action=CREATE",json)
// }
export function deleteCustomerData(subDimen,customerCode,shipToCode){
   return apiInstance.delete("/Customer/DeleteCustomerData?subsDimensionID="+subDimen+"&customercode="+customerCode+"&Shiptocode="+shipToCode+"&Action=DELETE")
}

export function updateSubBusinessType(params,subID,account){
    let k ={
    }
    k.businessSubTypeCode=params.text
    k.businessSubTypeDescription= params.desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessSubType?Action=UPDATE",k)
}
export function newSubBusinessType(code,desc,subID,account){
    let k ={
    }
    k.businessSubTypeCode=code
    k.businessSubTypeDescription= desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessSubType?Action=CREATE",k)
}
export function updateBusinessType(params,subID,account){
    let k ={
    }
    k.businessTypeCode=params.text
    k.businessTypeDescription= params.desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessType?Action=UPDATE",k)
}
export function newBusinessType(code,desc,subID,account){
    let k ={
    }
    k.businessTypeCode=code
    k.businessTypeDescription= desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessType?Action=CREATE",k)
}
export function deleteBusinessType(params,subID,account){
    let k ={
    }
    k.businessTypeCode=params.text
    k.businessTypeDescription= params.desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessType?Action=DELETE",k)
}
export function deleteBusinessSubType(params,subID,account){
    let k ={
    }
    k.businessSubTypeCode=params.text
    k.businessSubTypeDescription= params.desc
    k.subsDimensionID=subID
    k.userName=account
   return apiInstance.post("/Customer/ManageBusinessSubType?Action=DELETE",k)
}