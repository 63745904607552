/**
 * Reducer for Upstream
 */

import { ACTION_TYPE, LOADER_INITIAL_STATE } from "../constants";

const loaderReducer = (state = LOADER_INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_LOADER:
      return {
        ...state,
        counter: action.payload
          ? state.counter + 1
          : state.counter > 0
          ? state.counter - 1
          : 0,
      };
    default:
      return state;
  }
};

export default loaderReducer;
