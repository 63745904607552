import { DefaultButton, Separator, Stack } from "@fluentui/react";
import classNames from "classnames";
import React from "react";

import s from "./styles.module.scss";

function FormGroupButton({disabled, handleSave, handleCancel, className, position="end"}) {
  return (
    <div className={classNames(s.root, className)}>
      <Separator className={s.separator} />
      <Stack className={s.buttons} horizontal horizontalAlign={position}>
        <DefaultButton className={s.save} disabled={disabled} onClick={handleSave}>Save</DefaultButton>
        <DefaultButton className={s.cancel} onClick={handleCancel}>Cancel</DefaultButton>
      </Stack>
    </div>
  );
}
export default FormGroupButton;
