import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	PrimaryButton
} from '@fluentui/react';
import React from 'react';

import s from './styles.module.scss';

const modalPropsStyles = { main: { maxWidth: 380 } };

const dialogContentProps = {
	type: DialogType.normal,
	title: 'Confirm'
};

const modalProps = { styles: modalPropsStyles };

function Confirm({ message, onClose, open }) {
	return (
		<Dialog
			hidden={!open}
			modalProps={{
				...modalProps,
				subText: message
			}}
			className={s.root}
			dialogContentProps={dialogContentProps}
		>
			{message}
			<DialogFooter>
				<PrimaryButton
					onClick={() => {
						onClose(true);
					}}
					text='Yes'
				/>
				<DefaultButton
					onClick={() => {
						onClose(false);
					}}
					text='No'
				/>
			</DialogFooter>
		</Dialog>
	);
}

export default Confirm;
