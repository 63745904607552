import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import useNotification from '../../../hooks/useNotification';

import {
	exportToExcel,
	markAsVoid,
	generatePDF,
	printPDFInvoice
} from '../../../services/documents';

import Overlay from './Overlay';
import { ReactComponent as FileOffIcon } from '../../../assets/icons/fileoff.svg';
import { ReactComponent as CustomizeIcon } from '../../../assets/icons/customize.svg';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { MessageBarType } from '@fluentui/react';

import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../actions/loaderAction';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { Toggle } from '@fluentui/react/lib/Toggle';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';


const TableFunctions = ({
	submitData,
	setSubmitBtnClicked,
	submitBtnClicked,
	selectionDetails,
	include,
	exclude,
	handleToggle,
	toggled,
	originalColumns,
	currentColumns,
	setCurrentColumns,
	handleAddColumn,
	subDimensionID,
	documentTypeID,
	userDetails,
	setNotificationMessage,
	oowOnly
}) => {
	const [filtering, setFiltering] = useState(false);

	const dispatch = useDispatch();
	const { instance, accounts } = useMsal();
	const userAlias = accounts[0].username.match(/[^@]*/i)[0];
	const { notify } = useNotification();

	registerIcons({
		icons: {
			Void: <FileOffIcon />,
			Customize: <CustomizeIcon />
		}
	});

	const upstream = useSelector((state) => state.upstream);
	const currentUpstream = upstream?.upstreams?.find(
		(x) => x.selected
	).upstreamName;

	const containsRole = (role) => {
		return userDetails.userRoles.some((i) => i.roleName === role);
	};

	const handleFiltering = () => {
		setFiltering(!filtering);
	};

	const handlePDF = () => {
		getPDFBlobDetails();
	};

	const onFormatDateData = (date) => {
		const d = dayjs(date);
		return (
			d.get('year').toString() +
			'-' +
			(d.get('month') + 1).toString() +
			'-' +
			d.get('date').toString()
		);
	};

	const getPDFBlobDetails = async () => {
		if (selectionDetails.length > 0) {
			dispatch(showLoader());

			const excludeInvoices = exclude
				.filter((item, index) => exclude.indexOf(item) === index)
				.join(',');

			const documentNumbers = include.join(',');
			const customerCodes = submitData.CustomerCodes;
			const dateFrom = submitData.DocumentDateFrom
				? onFormatDateData(submitData.DocumentDateFrom)
				: '';
			const dateTo = submitData.DocumentDateTo
				? onFormatDateData(submitData.DocumentDateTo)
				: '';

			var params = {
				subsDimesionID: subDimensionID,
				documentTypeID: documentTypeID,
				documentStatus: submitData.DocumentStatus,
				printStatus: submitData.PrintStatus,
				documentNumber: documentNumbers,
				excludedDocumentNumber: excludeInvoices,
				customerCode: customerCodes,
				documentDateFrom: dateFrom,
				documentDateTo: dateTo,
				documentNumberFrom: submitData.DocumentNumberFrom,
				documentNumberTo: submitData.DocumentNumberTo,
				guiNumberFrom: '',
				guiNumberTo: '',
				userName: userAlias,
				isOOW: toggled
			};
			await generatePDF(params)
				.then((res) => {
					const data = res.data;
					if (data.status === 'success') {
						const dataDetails = JSON.parse(data.data);

						dataDetails.map((item) => handlePrintPDF(item));
						if (submitData.PrintStatus == 'Unprinted') {
							setSubmitBtnClicked(!submitBtnClicked);
						}
					} else {
						notify({
							messageType: MessageBarType.error,
							message: 'Error occurred while generating pdf.'
						});
						setNotificationMessage('Error occurred while generating pdf.');
						setTimeout(() => {
							setNotificationMessage('');
						}, 300);
					}
				})
				.finally(() => {
					dispatch(hideLoader());
				});
		} else {
			notify({
				messageType: MessageBarType.error,
				message: 'No instances selected.'
			});
			setNotificationMessage('No instances selected.');
			setTimeout(() => {
				setNotificationMessage('');
			}, 300);
		}
	};

	const handlePrintPDF = async (params) => {
		await printPDFInvoice(params, currentUpstream)
			.then((res) => {
				const blob = new Blob([res.data], { type: 'application/pdf' });

				FileSaver.saveAs(blob, params.FilePath);

				notify({
					messageType: MessageBarType.success,
					message: 'Document printed successfully.'
				});
				setNotificationMessage('Document printed successfully.');
				setTimeout(() => {
					setNotificationMessage('');
				}, 300);
			})
			.catch((err) => {
				notify({
					messageType: MessageBarType.error,
					message: 'Error occurred.'
				});
				setNotificationMessage('Error occurred.');
				setTimeout(() => {
					setNotificationMessage('');
				}, 300);
			});
	};

	const handleExport = async () => {
		if (include.length > 0) {
			dispatch(showLoader());
			const excludeInvoices = exclude
				.filter((item, index) => exclude.indexOf(item) === index)
				.join(',');
			const documentNumbers = include.join(',');

			const customerCodes = submitData.CustomerCodes;

			const dateFrom = submitData.DocumentDateFrom
				? onFormatDateData(submitData.DocumentDateFrom)
				: '';
			const dateTo = submitData.DocumentDateTo
				? onFormatDateData(submitData.DocumentDateTo)
				: '';

			var params = {
				subsDimesionID: subDimensionID,
				documentTypeID: documentTypeID,
				documentStatus: submitData.DocumentStatus,
				printStatus: submitData.PrintStatus,
				documentNumber: documentNumbers,
				excludedDocumentNumber: excludeInvoices,
				customerCode: customerCodes,
				documentDateFrom: dateFrom,
				documentDateTo: dateTo,
				documentNumberFrom: submitData.DocumentNumberFrom,
				documentNumberTo: submitData.DocumentNumberTo,
				guiNumberFrom: '',
				guiNumberTo: '',
				isOOW: toggled
			};
			await exportToExcel(params, currentUpstream)
				.then((res) => {
					const blob = new Blob([res.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					});
					let fileName = '';
					if (params.subsDimesionID == 1) {
						fileName = `${currentUpstream}_TW_OutputExcel.xlsx`;
					} else {
						fileName = `${currentUpstream}_KR_OutputExcel.xlsx`;
					}
					FileSaver.saveAs(blob, fileName);

					notify({
						messageType: MessageBarType.success,
						message: 'Document exported successfully.'
					});
					setNotificationMessage('Document exported successfully.');
					setTimeout(() => {
						setNotificationMessage('');
					}, 300);
				})
				.catch((err) => {
					notify({
						messageType: MessageBarType.error,
						message: 'Error occurred.'
					});
					setNotificationMessage('Error occurred.');
					setTimeout(() => {
						setNotificationMessage('');
					}, 300);
				})
				.finally(() => {
					dispatch(hideLoader());
				});
		} else {
			notify({
				messageType: MessageBarType.error,
				message: 'No instances selected.'
			});
			setNotificationMessage('No instances selected.');
			setTimeout(() => {
				setNotificationMessage('');
			}, 300);
		}
	};

	const handleMarkAsVoid = async () => {
		if (selectionDetails.length > 0) {
			if (selectionDetails[0].documentStatus !== 'VOID') {
				dispatch(showLoader());
				const items = selectionDetails.map((element) => ({
					subsDimesionID: subDimensionID,
					documentTypeID: documentTypeID,
					importStatus: element.documentStatus,
					documentNumber: element.documentNumber,
					userName: userAlias,
					voidReason: 'selected'
				}));
				await markAsVoid(items)
					.then((res) => {
						if (res.data === 1) {
							notify({
								messageType: MessageBarType.success,
								message: 'Document status updated successfully.'
							});
							setNotificationMessage('Document status updated successfully.');
							setTimeout(() => {
								setNotificationMessage('');
							}, 300);
						} else {
							notify({
								messageType: MessageBarType.error,
								message: 'Error occurred.'
							});
							setNotificationMessage('Error occurred.');
							setTimeout(() => {
								setNotificationMessage('');
							}, 300);
						}
					})
					.finally(() => {
						dispatch(hideLoader());
					});
			} else {
				notify({
					messageType: MessageBarType.error,
					message: 'Document status is already void.'
				});
				setNotificationMessage('Document status is already void.');
				setTimeout(() => {
					setNotificationMessage('');
				}, 300);
			}
		} else {
			notify({
				messageType: MessageBarType.error,
				message: 'No instances selected.'
			});
			setNotificationMessage('No instances selected.');
			setTimeout(() => {
				setNotificationMessage('');
			}, 300);
		}
	};

	const printIcon = { iconName: 'Print' };
	const exportIcon = { iconName: 'Export' };
	const voidIcon = { iconName: 'Void' };
	const customizeIcon = { iconName: 'Customize' };

	const toggleStyles = {
		root: { marginTop: 10, marginLeft: 8, width: 144 }
	};

	const voidStyles = {
		icon: {
			marginBottom: '10px'
		}
	};

	const customizeStyles = {
		icon: {
			marginBottom: '7px'
		}
	};

	const commandBarStyles = {
		root: { padding: 0 }
	};

	const toggle = (
		<Toggle
			className='toggle'
			onText='OOW invoices'
			offText='OOW invoices'
			checked={toggled}
			disabled={oowOnly() ? true : !containsRole('OOW')}
			onChange={handleToggle}
			styles={toggleStyles}
		/>
	);

	const items = [
		{
			key: 'exportItem',
			text: 'Export',
			iconProps: exportIcon,
			onClick: handleExport,
			ariaLabel:'Export button'
		},
		{
			key: 'printItem',
			text: 'Print to PDF',
			iconProps: printIcon,
			onClick: handlePDF,
			ariaLabel:'Print to PDF button'
		},
		{
			key: 'void',
			text: 'Mark as Void',
			buttonStyles: voidStyles,
			iconProps: voidIcon,
			onClick: handleMarkAsVoid,
			ariaLabel:'Mark as Void button'
		},
		{
			key: 'oow',
			text: `OOW invoices ${toggled ? '(On)' : '(Off)'}`,
			buttonStyles: toggleStyles,
			onClick: handleToggle,
			disabled: oowOnly() ? true : !containsRole('OOW'),
			commandBarButtonAs: () => toggle
		}
	];

	const farItems = [
		{
			key: 'customize',
			text: 'Customize',
			buttonStyles: customizeStyles,
			iconProps: customizeIcon,
			onClick: handleFiltering,
			ariaLabel:'Customize button'
		}
	];

	useEffect(() => {
		document.title="Invoice print tool-Document"
		document.getElementsByClassName('ms-CommandBar')[0].setAttribute('aria-busy','true');
		setTimeout(()=>{
			console.log(document.getElementsByClassName('ms-DetailsHeader'));
			document.getElementsByClassName('ms-DetailsHeader')[0].setAttribute('aria-busy','true');
		},3000);
		// 
	});

	return (
		<div className='table-functions'>
			<CommandBar
				items={items}
				farItems={farItems}
				ariaLabel='Inbox actions'
				styles={commandBarStyles}
			/>
			<Overlay
				isOpen={filtering}
				originalColumns={originalColumns}
				currentColumns={currentColumns}
				setFiltering={setFiltering}
				setCurrentColumns={setCurrentColumns}
				handleAddColumn={handleAddColumn}
			/>
		</div>
	);
};

export default TableFunctions;
