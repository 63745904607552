import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import About from '../About';
import { useDispatch, useSelector } from 'react-redux';

import {
	loadUpstream,
	loadUserUpstream,
	storeUpstream
} from '../../actions/upstreamAction';
import { hideLoader, showLoader } from '../../actions/loaderAction';
import { storeUser } from '../../actions/userAction';

import { CoherenceHeader } from '@coherence-design-system/controls';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';

function TopBar() {
	const dispatch = useDispatch();
	const history = useHistory();
	const upstream = useSelector((state) => state.upstream);
	const user = useSelector((state) => state.user);

	const [openAbout, setOpenAbout] = useState(false);
	const [selectedKey, setSelectedKey] = useState();
	const [close, setClose] = useState(null);
	const [message, setMessage] = useState('');

	const stackTokens = { childrenGap: 30 };
	const buttonStack = { childrenGap: 12 };

	const { accounts, instance } = useMsal();
	const userAlias = accounts[0]?.username.match(/[^@]*/i)[0];

	const subDimensionName =
		user.user?.userList?.subsDimensionID === 1
			? 'Taiwan'
			: user.user?.userList?.subsDimensionID === 2
			? 'Korea'
			: '';

	const onChange = (ev, option) => {
		setSelectedKey(option.key);
	};

	const handleSave = () => {
		const upstreamName = upstream.upstreams.find(
			(x) => x.upStreamId == selectedKey
		)?.upstreamName;
		dispatch(storeUpstream(selectedKey));
		setMessage(`Upstream changed to ${upstreamName}, reloading page`);
		setTimeout(() => {
			history.go(0);
		}, 100);
	};

	const handleCancel = () => {
		// setClose(true);
		setTimeout(() => {
			history.go(0);
		}, 100);
	};

	const onDismissOpenedPanel = () => {
		setSelectedKey(upstream.upstreams.find((x) => x.selected)?.upStreamId);
		setClose(null);
	};

	const handleLogout = (logoutType) => {
		sessionStorage.removeItem('USER_UPSTREAM');
		if (logoutType === 'popup') {
			instance.logoutPopup({
				postLogoutRedirectUri: '/',
				mainWindowRedirectUri: '/'
			});
		} else if (logoutType === 'redirect') {
			instance.logoutRedirect({
				postLogoutRedirectUri: '/'
			});
		}
	};

	const settingsBody = (
		<Stack tokens={stackTokens}>
			<LiveAnnouncer>
				<LiveMessage message={message} aria-live='polite' />
				<ChoiceGroup
					selectedKey={selectedKey}
					options={upstream?.upstreams
						?.sort((a, b) => a.upStreamId - b.upStreamId)
						.map((x) => ({
							key: x.upStreamId,
							text: x.upstreamName == 'MCS' ? 'MCS SCB' : x.upstreamName
						}))}
					onChange={onChange}
					label='Select Upstream'
				/>
				{upstream?.upstreams.length > 1 ? (
					<Stack tokens={buttonStack} horizontal>
						<PrimaryButton text='Save' onClick={handleSave} />
						<DefaultButton text='Cancel' onClick={handleCancel} />
					</Stack>
				) : (
					''
				)}
			</LiveAnnouncer>
		</Stack>
	);

	const aboutBody = (
		<About
			open={openAbout}
			onClose={() => {
				setOpenAbout(false);
			}}
		/>
	);

	const farRightSettings = {
		settingsSettings: {
			buttonSettings: {
				title: 'Settings',
				ariaLabel: 'Settings'
			},
			panelSettings: {
				titleText: 'Upstream',
				body: settingsBody,
				closeButtonAriaLabel:'close'
			}
		},
		helpSettings: {
			buttonSettings: {
				title: 'About',
				ariaLabel: 'About'
			},
			panelSettings: {
				titleText: 'Invoice Print Tool',
				body: aboutBody,
				closeButtonAriaLabel:'close'
			}
		},
		profileSettings: {
			buttonSettings: {
				title: 'Profile',
				ariaLabel: 'Profile'
			},
			panelSettings: {
				fullName: accounts[0]?.name,
				emailAddress: accounts[0]?.username,
				viewAccountLink: 'https://myaccount.microsoft.com/?ref=MeControl',
				logOutLink: '#',
				onLogOut: () => handleLogout('redirect'),
				closeButtonAriaLabel:'close'
			}
		}
	};

	useEffect(() => {
		if (upstream?.upstreams?.length > 0) {
			setSelectedKey(upstream.upstreams.find((x) => x.selected)?.upStreamId);
			dispatch(hideLoader());
		}
	}, [upstream]);

	useEffect(() => {
		dispatch(storeUser(userAlias));
		dispatch(loadUpstream);
		if (!sessionStorage.getItem('USER_UPSTREAM')) {
			dispatch(showLoader());
			dispatch(loadUserUpstream());
		}
	}, []);

	return (
		<CoherenceHeader
			headerLabel={'header'}
			appNameSettings={{
				label: `${subDimensionName} - Invoice Print Tool`
			}}
			farRightSettings={farRightSettings}
			dismissOpenedPanel={close}
			onDismissOpenedPanel={onDismissOpenedPanel}
		/>
	);
}

export default TopBar;
