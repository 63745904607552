import { Link, Text } from '@fluentui/react';
import Page from '../Page';

import screenshot1 from '../../assets/img/screenshot_1.png';
import screenshot2 from '../../assets/img/screenshot_2.png';
import screenshot3 from '../../assets/img/screenshot_3.png';
import screenshot4 from '../../assets/img/screenshot_4.png';
import screenshot5 from '../../assets/img/screenshot_5.png';

import s from './styles.module.scss';

export function RaiseTicketPage(props) {
	return (
		<Page title='Raise a Ticket'>
			<ul>
				<li>
					<Text>
						If you have a screenshot, please send it together with your order
						details (time of submission, the products you were tryiung to order
						and what happened before you encountered the error) to{' '}
						<Link href='https://microsoft.service-now.com/navpage.do' underline>
							CSE Web
						</Link>{' '}
						who will then redirect the issue to the technical team.{' '}
					</Text>
				</li>
				<li>
					<Text>Follow these steps to create a support ticket</Text>
					<ol>
						<li>
							<Text>
								Go to{' '}
								<Link
									href='https://microsoft.service-now.com/navpage.do'
									underline
								>
									CSE Web
								</Link>{' '}
							</Text>
							<img className={s.screenshot} src={screenshot1} />
						</li>
						<li>
							<Text>
								Click on Get Support and navigate to Create a Support Ticket
							</Text>
							<img className={s.screenshot} src={screenshot2} />
						</li>
						<li>
							<Text>
								Please check the "Open on behalf of this user" field. Select
								preferred contact method.
							</Text>
						</li>
						<li>
							<Text>
								Choose users to be notified (if any). Select current working
								environment & affected device as "Device Info Not Available".
							</Text>
							<img className={s.screenshot} src={screenshot3} />
						</li>
						<li>
							<Text>
								Choose category of Issue as Application & Service. Select the
								impacted application as Korea Taiwan Invoice Printing.
							</Text>
							<img className={s.screenshot} src={screenshot4} />
						</li>
						<li>
							<Text>
								Enter the problem you are facing under "Short description" and
								"Please describe your issue below" fields.
							</Text>
							<img className={s.screenshot} src={screenshot5} />
						</li>
						<li>
							<Text>Attach screenshots (if any) related to issue.</Text>
						</li>
						<li>
							<Text>Click on submit</Text>
						</li>
					</ol>
				</li>
			</ul>
		</Page>
	);
}
