import axiosInstance from "./mockApi";
import { apiInstance } from "./requestInterceptor";

export function getLogsData() {
  return axiosInstance.get("/log-data");
}

export function getImportLogsData() {
  return axiosInstance.get("/import-log-data");
}

export function getRealLogsData(params, documentDateFrom, documentDateTo, subDimensionID){  
  return apiInstance.get(`ImportLog/ GetImportLogDetails?DocumentStatus=`+params.DocumentStatus+"&DocumentDateFrom="+documentDateFrom+"&DocumentDateTo="+documentDateTo+"&DocumentNumberFrom="+params.DocumentNumberFrom+"&DocumentNumberTo="+params.DocumentNumberTo+"&SubsDimesionID="+subDimensionID+"&PageNumber="+params.PageNumber+"&PageSize="+params.PageSize)
}

export function getRealPrintLogsData(params, documentDateFrom, documentDateTo,subDimensionID){
  return apiInstance.get(`PrintLog/GetPrinrLogDetails?UserName=GDPRDR&PrintStatus=`+params.DocumentStatus+`&DocumentDateFrom=`+documentDateFrom+`&DocumentDateTo=`+documentDateTo+`&DocumentNumberFrom=`+params.DocumentNumberFrom+`&DocumentNumberTo=`+params.DocumentNumberTo+`&SubsDimesionID=`+subDimensionID+`&PageNumber=`+params.PageNumber+`&PageSize=`+params.PageSize)
}
