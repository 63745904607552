/**
 * upstream actions
 */

import { ACTION_TYPE } from "../constants";
import { getAllUpstreams, getUpstream, saveUpstream } from "../services/upstream";


/**
 * Load all upstream
 *
 * @returns {Promise} loaded upstream
 */
 export const loadUpstream = (subsDimentionId) => ({
  type: ACTION_TYPE.LOAD_ALL_UPSTREAM,
  payload: async () => {
    const res = await getAllUpstreams(subsDimentionId);
    return res.data;
  }
});

/**
 * Load user upstream
 *
 * @returns {Promise} loaded upstream
 */
export const loadUserUpstream = () => ({
  type: ACTION_TYPE.LOAD_UPSTREAM,
  payload: async () => {
    const res = await getUpstream();
    return res.data;
  }
});

/**
 * Save upstream
 *
 * @param {string} upstream
 *
 * @returns {Promise} saved upstream
 */
export const storeUpstream = (upstream) => ({
  type: ACTION_TYPE.STORE_UPSTREAM,
  payload: upstream
});

/**
 * Save tenaant
 *
 * @param {string} tenaant
 *
 * @returns {Promise} tenaant
 */
 export const storeTenant = (tenant) => ({
  type: ACTION_TYPE.STORE_TENANT,
  payload: tenant
});
