import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { mergeStyleSets } from '@fluentui/react';
import { CoherenceDataGrid } from '@coherence-design-system/controls';
import { SelectionMode } from '@fluentui/react';
import { Selection } from '@fluentui/react/lib/DetailsList';
import dayjs from 'dayjs';
import TableFunctions from './TableFunctions';
import TableFooter from './TableFooter';
import {
	getKoreaInvoiceData,
	getFilteredDocuments_OOW
} from '../../../services/documents';
import { hideLoader, showLoader } from '../../../actions/loaderAction';

const DocumentsTableCredit = ({
	documentTypeID,
	submitData,
	setItemCountMessage,
	subDimensionID,
	submitBtnClicked,
	setSubmitBtnClicked,
	toggled,
	setToggled,
	userDetails,
	setNotificationMessage,
	oowOnly,
	allExcludeList,
	setAllExcludeList,
	currentPageExcludeList,
	setCurrentPageExcludeList
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [originalColumns, setOriginalColumns] = useState([]);
	const [currentColumns, setCurrentColumns] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [pageData, setPageData] = useState([]);
	const [maxPage, setMaxPage] = useState('');
	const [selectionDetails, setSelectionDetails] = useState([]);
	const [selectAll, setSelectAll] = useState(true);
	const [includeList, setIncludeList] = useState([]);
	const [firstCall, setFirstCall] = useState(true);

	const [selection, setSelection] = useState(
		new Selection({
			onSelectionChanged: () => {
				setSelectionDetails(getSelectionDetails());
			}
		})
	);

	function getSelectionDetails() {
		const a = selection.getSelection();
		return a;
	}

	useEffect(() => {
		if (selection.isAllSelected()) {
			setIncludeList(['All']);

			const containsOnPage = currentPageExcludeList.every((i) => {
				return selection.getItems().some((j) => j.documentNumber == i);
			});
			if (containsOnPage) {
				setAllExcludeList(
					allExcludeList.filter((i) => !currentPageExcludeList.includes(i))
				);
			}
			setCurrentPageExcludeList([]);
			setSelectAll(true);
		} else {
			// adding to all selected ones or not selected ones
			if (selectionDetails.length !== 0) {
				if (!includeList.includes('All')) {
					setIncludeList(selectionDetails.map((item) => item.documentNumber));
					setCurrentPageExcludeList([]);
				} else {
					const exclude = selection
						.getItems()
						.filter((i) => !selectionDetails.includes(i))
						.map((i) => i.documentNumber);

					const containsOnPage = currentPageExcludeList.every((i) => {
						return selection.getItems().some((j) => j.documentNumber == i);
					});

					if (
						currentPageExcludeList.length >= exclude.length &&
						containsOnPage
					) {
						setAllExcludeList(
							allExcludeList.filter((i) => !currentPageExcludeList.includes(i))
						);
					}
					setCurrentPageExcludeList(exclude);
				}
			} else {
				setCurrentPageExcludeList([]);
				setAllExcludeList([]);
				if (!firstCall) {
					setSelectAll(false);
				} else {
					setFirstCall(false);
				}
				setIncludeList(selectionDetails.map((item) => item.documentNumber));
			}
		}
	}, [selectionDetails]);

	const dispatch = useDispatch();

	const Styles = mergeStyleSets({
		spacer: {
			marginLeft: '-18px',
			overflowY: 'auto'
		},
		numColumns: {
			fontSize: '14px',
			color: 'black'
		}
	});

	const columns = [
		{
			key: 'column1',
			name: 'Document Num.',
			fieldName: 'documentNumber',
			type: 'string',
			isRowHeader: true,
			isResizable: true,
			minColumnWidth: 110,
			sortAscendingAriaLabel: 'Document Num. column Sorted to ascending',
			sortDescendingAriaLabel: 'Document Num. column Sorted to descending'
		},
		{
			key: 'column2',
			name: 'Customer Code',
			fieldName: 'customerCode',
			type: 'number',
			isResizable: true,
			minColumnWidth: 100,
			sortAscendingAriaLabel: 'Customer Code column Sorted to ascending',
			sortDescendingAriaLabel: 'Customer Code column Sorted to descending',
			onRender: (item) => {
				return <span className={Styles.numColumns}>{item.customerCode}</span>;
			}
		},
		{
			key: 'column3',
			name: 'Ship to Code',
			fieldName: 'shipToCustomerCode',
			type: 'number',
			isResizable: true,
			minColumnWidth: 100,
			sortAscendingAriaLabel: 'Ship to Code column Sorted to ascending',
			sortDescendingAriaLabel: 'Ship to Code column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>{item.shipToCustomerCode}</span>
				);
			}
		},
		{
			key: 'column4',
			name: 'Customer Name',
			fieldName: 'customerName',
			type: 'string',
			minColumnWidth: 170,
			isResizable: true,
			sortAscendingAriaLabel: 'Customer Name column Sorted to ascending',
			sortDescendingAriaLabel: 'Customer Name column Sorted to descending'
		},
		{
			key: 'column5',
			name: 'Document Date',
			fieldName: 'documentDate',
			type: 'dayjs',
			isResizable: true,
			sortAscendingAriaLabel: 'Document Date column Sorted to ascending',
			sortDescendingAriaLabel: 'Document Date column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.documentDate.format('DD MMM YYYY')}
					</span>
				);
			}
		},
		{
			key: 'column6',
			name: 'Extended Amount',
			fieldName: 'extendedPrice',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Extended Amount column Sorted to ascending',
			sortDescendingAriaLabel: 'Extended Amount column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.extendedPrice.toLocaleString()}
					</span>
				);
			}
		},
		{
			key: 'column7',
			name: 'Gross Amount',
			fieldName: 'grossAmount',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Gross Amount column Sorted to ascending',
			sortDescendingAriaLabel: 'Gross Amount column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.grossAmount.toLocaleString()}
					</span>
				);
			}
		},
		{
			key: 'column8',
			name: 'Tax Amount',
			fieldName: 'taxAmount',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Tax Amount column Sorted to ascending',
			sortDescendingAriaLabel: 'Tax Amount column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.taxAmount.toLocaleString()}
					</span>
				);
			}
		},
		{
			key: 'column9',
			name: 'Sales Order Num.',
			fieldName: 'salesOrderNumber',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Sales Order Num. column Sorted to ascending',
			sortDescendingAriaLabel: 'Sales Order Num. column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>{item.salesOrderNumber}</span>
				);
			}
		},
		{
			key: 'column10',
			name: 'Document Status',
			fieldName: 'documentStatus',
			type: 'string',
			sResizable: true,
			sortAscendingAriaLabel: 'Document Status column Sorted to ascending',
			sortDescendingAriaLabel: 'Document Status column Sorted to descending'
		},
		{
			key: 'column11',
			name: 'Print Status',
			fieldName: 'printStatus',
			type: 'string',
			isResizable: true,
			minColumnWidth: 80,
			sortAscendingAriaLabel: 'Print Status column Sorted to ascending',
			sortDescendingAriaLabel: 'Print Status column Sorted to descending'
		},
		{
			key: 'column12',
			name: 'Commit Usage',
			fieldName: 'commitUsage',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Commit Usage column Sorted to ascending',
			sortDescendingAriaLabel: 'Commit Usage column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.commitUsage.toLocaleString()}
					</span>
				);
			}
		},
		{
			key: 'column13',
			name: 'Net Amount',
			fieldName: 'netAmount',
			type: 'number',
			isResizable: true,
			sortAscendingAriaLabel: 'Net Amount column Sorted to ascending',
			sortDescendingAriaLabel: 'Net Amount column Sorted to descending',
			onRender: (item) => {
				return (
					<span className={Styles.numColumns}>
						{item.netAmount.toLocaleString()}
					</span>
				);
			}
		}
	];

	const parseNumber = (number) => {
		if (number) {
			return parseFloat(number.toString().replace(/,/g, ''));
		} else {
			return number;
		}
	};

	const onFormatDateData = (date) => {
		const d = dayjs(date);
		return (
			d.get('year').toString() +
			'-' +
			(d.get('month') + 1).toString() +
			'-' +
			d.get('date').toString()
		);
	};

	const getOOWApi = async (
		params,
		pageSize,
		currentPage,
		subDimensionID,
		documentTypeID
	) => {
		const documentDateFrom = params.DocumentDateFrom
			? onFormatDateData(params.DocumentDateFrom)
			: '';
		const documentDateTo = params.DocumentDateTo
			? onFormatDateData(params.DocumentDateTo)
			: '';

		const resp = await getFilteredDocuments_OOW(
			params,
			documentDateFrom,
			documentDateTo,
			pageSize,
			currentPage,
			subDimensionID,
			documentTypeID
		);

		setAllExcludeList([...allExcludeList, ...currentPageExcludeList]);
		if (resp.data.length > 0) {
			resp.data.forEach((data, i) => {
				data.key = i;
				data.documentDate === null
					? (data.documentDate = 'Invalid Date')
					: (data.documentDate = dayjs(data.documentDate));
				data.customerCode = Number(data.customerCode);
				data.shipToCustomerCode = Number(data.shipToCustomerCode);
				data.extendedPrice = Number(parseNumber(data.extendedPrice));
				data.commitUsage = Number(parseNumber(data.commitUsage));
				data.netAmount = Number(parseNumber(data.netAmount));
			});

			setPageData(resp.data);
			setMaxPage(Math.ceil(resp.data[0].totalCount / pageSize));
			setTotalCount(resp.data[0].totalCount);
			if (selectAll) {
				selection.setAllSelected(true);
				if (allExcludeList.length != 0) {
					resp.data.forEach((item) => {
						if (allExcludeList.includes(item.documentNumber)) {
							selection.setIndexSelected(resp.data.indexOf(item), false, false);
						}
					});
				}
			} else {
				selection.setAllSelected(false);
			}

			setItemCountMessage(
				`Loading, ${resp.data[0].totalCount} credit memos found`
			);
			currentColumns.length === 0
				? (setCurrentColumns(columns), setOriginalColumns(columns))
				: '';
		} else {
			setItemCountMessage(`Loading, 0 credit memos found`);
			setTotalCount(0);
			setMaxPage(0);
		}
	};

	const getInvoiceApi = async (
		params,
		pageSize,
		currentPage,
		documentTypeID
	) => {
		const documentDateFrom = params.DocumentDateFrom
			? onFormatDateData(params.DocumentDateFrom)
			: '';
		const documentDateTo = params.DocumentDateTo
			? onFormatDateData(params.DocumentDateTo)
			: '';

		const resp = await getKoreaInvoiceData(
			params,
			documentDateFrom,
			documentDateTo,
			pageSize,
			currentPage,
			documentTypeID
		);

		setAllExcludeList([...allExcludeList, ...currentPageExcludeList]);
		if (resp.data.length > 0) {
			resp.data.forEach((data, i) => {
				data.key = i;
				data.documentDate === null
					? (data.documentDate = 'Invalid Date')
					: (data.documentDate = dayjs(data.documentDate));
				// data.customerCode = Number(data.customerCode);
				// data.shipToCustomerCode = Number(data.shipToCustomerCode);
				data.extendedPrice = Number(parseNumber(data.extendedPrice));
				data.grossAmount = Number(parseNumber(data.grossAmount));
				data.taxAmount = Number(parseNumber(data.taxAmount));
				data.commitUsage = Number(parseNumber(data.commitUsage));
				data.netAmount = Number(parseNumber(data.netAmount));
			});

			setPageData(resp.data);
			setMaxPage(Math.ceil(resp.data[0].totalCount / pageSize));
			setTotalCount(resp.data[0].totalCount);
			if (selectAll) {
				selection.setAllSelected(true);
				if (allExcludeList.length != 0) {
					resp.data.forEach((item) => {
						if (allExcludeList.includes(item.documentNumber)) {
							selection.setIndexSelected(resp.data.indexOf(item), false, false);
						}
					});
				}
			} else {
				selection.setAllSelected(false);
			}

			setItemCountMessage(
				`Loading, ${resp.data[0].totalCount} credit memos found`
			);
			currentColumns.length === 0
				? (setCurrentColumns(columns), setOriginalColumns(columns))
				: '';
		} else {
			setItemCountMessage(`Loading, 0 credit memos found`);
			setTotalCount(0);
			setMaxPage(0);
		}
	};

	useEffect(() => {
		fetchData(
			submitData,
			pageSize,
			currentPage,
			subDimensionID,
			documentTypeID
		);
	}, [currentPage]);

	useEffect(() => {
		fetchData(submitData, pageSize, 1, subDimensionID, documentTypeID);
		setCurrentPage(1);
	}, [submitBtnClicked, toggled, pageSize]);

	const fetchData = async (
		params,
		pageSize,
		currentPage,
		subDimensionID,
		documentTypeID
	) => {
		dispatch(showLoader());
		if (toggled) {
			await getOOWApi(
				params,
				pageSize,
				currentPage,
				subDimensionID,
				documentTypeID
			);
		} else {
			await getInvoiceApi(params, pageSize, currentPage, documentTypeID);
		}
		dispatch(hideLoader());
	};

	const handleToggle = () => {
		setToggled(!toggled);
		setCurrentPageExcludeList([]);
		setAllExcludeList([]);
		setSelectAll(true);
	};

	const onPageChange = (startIdx, endIdx, newPageNumber) => {
		if (newPageNumber !== currentPage) {
			setCurrentPage(newPageNumber);
		}
	};

	const onPageSizeChange = (newPageSize) => {
		setPageSize(newPageSize);
	};

	function handleAddColumn(index, column) {
		const newAr = [...currentColumns];
		newAr.splice(index, 0, column);
		setCurrentColumns(newAr);
	}

	function getKey(item, index) {
		return item.key;
	}

	return (
		<>
			<TableFunctions
				submitData={submitData}
				submitBtnClicked={submitBtnClicked}
				setSubmitBtnClicked={setSubmitBtnClicked}
				selectionDetails={selectionDetails}
				include={includeList}
				exclude={allExcludeList.concat(currentPageExcludeList)}
				handleToggle={handleToggle}
				toggled={toggled}
				originalColumns={originalColumns}
				currentColumns={currentColumns}
				setCurrentColumns={setCurrentColumns}
				handleAddColumn={handleAddColumn}
				subDimensionID={subDimensionID}
				documentTypeID={documentTypeID}
				userDetails={userDetails}
				setNotificationMessage={setNotificationMessage}
				oowOnly={oowOnly}
			/>

			{totalCount !== 0 ? (
				<div className={Styles.spacer}>
					<CoherenceDataGrid
						listConfig={currentColumns}
						data={pageData}
						selectionMode={SelectionMode.multiple}
						isSortable={true}
						selection={selection}
						getKey={getKey}
						setKey='set'
						checkButtonAriaLabel={'checkbox'}
						ariaLabelForSelectAllCheckbox={'select all checkbox'}
					/>
				</div>
			) : (
				''
			)}
			<TableFooter
				maxPage={maxPage}
				currentPage={currentPage}
				pageSize={pageSize}
				itemCount={totalCount}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
			/>
		</>
	);
};

export default DocumentsTableCredit;
