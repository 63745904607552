import React, { useEffect, useState } from 'react';
import { AnimationClassNames, DefaultPalette } from '@fluentui/style-utilities';
import { IconButton, Stack } from '@fluentui/react';

import s from './styles.module.scss';

const accordionHeader = {
	root: {
		cursor: 'pointer',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: '#8c8c8c'
	}
};

const accordionConent = {
	root: {
		padding: 10
	}
};

function FluentAccordion({ header, children, collapse = false, year }) {
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		setShowContent(!collapse);
	}, [collapse]);

	return (
		<Stack horizontal={false} className={s.root}>
			<Stack.Item styles={accordionHeader}>
				<Stack horizontal={true} onClick={() => setShowContent(!showContent)}>
					<Stack.Item>
						<IconButton
							title={
								showContent
									? `year: ${year} region, ${header.props.header} expanded`
									: `year: ${year} region, ${header.props.header} collapsed`
							}
							ariaLabel={
								showContent
									? `year: ${year} region, ${header.props.header} expanded`
									: `year: ${year} region, ${header.props.header} collapsed`
							}
							className={s.chevron}
							iconProps={{
								iconName: showContent ? 'ChevronDown' : 'ChevronRight'
							}}
						/>
					</Stack.Item>
					<Stack.Item align='center'>{header}</Stack.Item>
				</Stack>
			</Stack.Item>
			{showContent && (
				<Stack.Item
					className={AnimationClassNames.slideDownIn20}
					styles={accordionConent}
				>
					{children}
				</Stack.Item>
			)}
		</Stack>
	);
}

export default FluentAccordion;
