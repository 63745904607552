/* global process */
/**
 * Configure Redux Store
 */
 import { createStore, applyMiddleware } from "redux";
 import thunk from "redux-thunk";
 import { createPromise } from "redux-promise-middleware";
 import rootReducer from "./reducers";
 
 const middlewares = [
   // if payload of action is promise it would split action into 3 states
   createPromise({
     promiseTypeSuffixes: ["PENDING", "SUCCESS", "ERROR"],
   }),
   thunk,
 ];
 
 const store = createStore(rootReducer, applyMiddleware(...middlewares));
 
 export default store;