import { Layer, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect } from "react";
import useNotification from "../../hooks/useNotification";

import s from "./styles.module.scss";

function NotificationSnack() {
  const { message, isGlobal, messageType, show, hide } = useNotification();

  useEffect(() => {
    let timeout = null;
    if (show) {
      timeout = setTimeout(() => hide(), 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [show]);

  return (
    isGlobal &&
    show && (
      <Layer className={s.root}>
        <MessageBar
          messageBarType={messageType}
          isMultiline={false}
          onDismiss={() => {
            hide();
          }}
          dismissButtonAriaLabel="Close"
        >
          {message}
        </MessageBar>
      </Layer>
    )
  );
}

export default NotificationSnack;
