import axios from "axios";
import { getUserUpstream, refreshAccessToken } from "../utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const reqInterceptor = async (req) => {
  const token = await refreshAccessToken();
  const userUpstreams = JSON.parse(
    await sessionStorage.getItem("USER_UPSTREAM")
  );
  const selectedUpstream =
    (!!userUpstreams && userUpstreams.length > 0) ||
    req.url.includes("/User/Upstreams")
      ? userUpstreams?.find((x) => x.selected)?.upstreamName
      : await getUserUpstream();
  req.headers.Authorization = `Bearer ${token}`;
  const upstream = selectedUpstream == "SAP" || req.url.includes("/User/") ? "SAP" : selectedUpstream?.toLowerCase();
  if (selectedUpstream && selectedUpstream.length > 0) {
    req.headers.common = {
      ...req.headers.common,
      upstream: upstream || "",
    };
  }
  return req;
};

const resInterceptor = (res) => {
  return res;
};

axiosInstance.interceptors.request.use(reqInterceptor, (error) =>
  Promise.reject(error)
);
axiosInstance.interceptors.response.use(resInterceptor, (err) => {
  try {
    if (err?.response?.status === 401) {
    msalInstance.logoutRedirect();
  } else if (
    err?.response?.data?.errors &&
    err?.response?.data?.errors.length > 0
  ) {
    const message = err?.response?.data?.errors?.join(" ");
    throw Error(message);
  } else {
    throw Error("Error");
  }
} catch(e) {
  console.log(e);
}
});

export const apiInstance = axiosInstance;
