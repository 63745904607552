import React, { useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import Button from 'react-bootstrap/Button';

import './styles.css';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
	const [showContextualMenu, setShowContextualMenu] = useState(false);
	const [expandAria, setexpandAria] = useState('Expand sign in menu');
	const [anncMessage, setanncMessage] = useState('');
	const { instance } = useMsal();

	const signRef = useRef(null);

	const handleLogin = (loginType) => {
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest).catch((e) => {
				console.log(e);
			});
		} else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest).catch((e) => {
				console.log(e);
			});
		}
	};

	const onShowContextualMenu = () => {
		setShowContextualMenu(!showContextualMenu);
		setanncMessage('Sign in menu expanded');
		// setTimeout(() => {
		// 	setexpandAria('Collapse sign in menu');
		// }, 500);
	};

	const onHideContextualMenu = () => {
		setShowContextualMenu(false);
		setexpandAria('Expand sign in menu');
		setanncMessage('Sign in menu collapsed');
		
	};

	const menuItems = [
		{
			key: 'popup',
			text: 'Sign in using Popup',
			onClick: () => handleLogin('popup')
		},
		{
			key: 'redirect',
			text: 'Sign in using Redirect',
			onClick: () => handleLogin('redirect')
		}
	];

	return (
		<LiveAnnouncer>
			<LiveMessage message={anncMessage} aria-live='polite' />
			<Button
				aria-label={expandAria}
				variant='secondary'
				className='but'
				onClick={onShowContextualMenu}
				ref={signRef}
			>
				Sign In
			</Button>
			{/* <div ref={signRef}>
				<DefaultButton
					text='Sign In'
					onClick={onShowContextualMenu}
					ref={signRef}
                    styles={buttonStyles}
				/>
			</div> */}

			<ContextualMenu
				items={menuItems}
				hidden={!showContextualMenu}
				target={signRef}
				onItemClick={onHideContextualMenu}
				onDismiss={onHideContextualMenu}
			/>
		</LiveAnnouncer>
	);
};
