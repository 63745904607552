
import { ACTION_TYPE } from "../constants";
import { getUserWithRoles } from "../services/users";

export const storeUser = (userAlias) => ({
    

        type: ACTION_TYPE.STORE_USER,
        payload: async () => {
            const res = await getUserWithRoles(userAlias);
            return res.data[0];
        }
        
})
