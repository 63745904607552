import React, { useState, useEffect } from 'react';

import { Checkbox } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Callout, mergeStyleSets, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const Overlay = ({
	isOpen,
	originalColumns,
	setFiltering,
	currentColumns,
	setCurrentColumns,
	handleAddColumn
}) => {
	const [savedColumns, setSavedColumns] = useState([]);

	useEffect(() => {
		setSavedColumns(currentColumns);
	}, [isOpen]);

	const handleClose = () => {
		setFiltering(false);
		setCurrentColumns(savedColumns);
	};

	const handleApply = () => {
		setFiltering(false);
	};

	function checkColumnVisibility(col) {
		return currentColumns.some((column) => column.key === col.key);
	}

	const handleCheck = (e, value, column) => {
		if (!value) {
			setCurrentColumns(currentColumns.filter((c) => c.key !== column.key));
		} else {
			const index = originalColumns.findIndex((c) => c.key === column.key);
			handleAddColumn(index, column);
		}
	};

	// const overlayStyles = {
	// 	root: {
	// 		// justifyContent: 'end',
	// 		// alignItems: 'start',
	// 		// right: '28px',
	// 		// top: '400px',
	// 		// width: '24%',
	// 		// height: '90%'
	// 	}
	// };

	// const modelProps = {
	// 	isBlocking: true,
	// 	// isModeless: true,

	// 	styles: overlayStyles
	// };

	const styles = mergeStyleSets({
		callout: {
			justifyContent: 'end',
			width: 250
		},
		title: {
			marginTop: 20
		},
		text: {
			float: 'right'
		}
	});

	const buttonId = useId('callout-button');

	return (
		<>
			{isOpen && (
				<>
					<span className={styles.text} id={buttonId}></span>
					<Callout
						role='dialog'
						className={styles.callout}
						ariaLabel='Customize Columns'
						onDismiss={handleClose}
						isBeakVisible={false}
						target={`#${buttonId}`}
						directionalHint={DirectionalHint.topRightEdge}
						preventDismissOnScroll={true}
						setInitialFocus
					>
						<h6 className={styles.title}>Customize Columns</h6>
						<div className='header-items'>
							{originalColumns?.map((column) => (
								<div key={column.key} className='header-item'>
									<Checkbox
										className='checkbox'
										checked={checkColumnVisibility(column)}
										onChange={(e, item) => handleCheck(e, item, column)}
										label={column.name}
									/>
								</div>
							))}
						</div>
						<hr className='overlayLine' />
						<div className='overlay-btns'>
							<PrimaryButton
								className='overlay-btn'
								text='Apply'
								onClick={handleApply}
							/>
							<DefaultButton
								id="cancelBtnCust"
								className='overlay-btn'
								text='Cancel'
								onClick={handleClose}
								onKeyDown={(e) => {if(e.which === 9){document.getElementById("cancelBtnCust").click()}}}
							/>
						</div>
					</Callout>
				</>
			)}
		</>
		// <Dialog hidden={!isOpen} onDismiss={handleClose} modalProps={modelProps}>
		// 	<h6>Customize Columns</h6>
		// 	<div className='header-items'>
		// 		{originalColumns?.map((column) => (
		// 			<div key={column.key} className='header-item'>
		// 				<Checkbox
		// 					className='checkbox'
		// 					checked={checkColumnVisibility(column)}
		// 					onChange={(e, item) => handleCheck(e, item, column)}
		// 					label={column.name}
		// 				/>
		// 			</div>
		// 		))}
		// 	</div>
		// 	<hr className='line' />
		// 	<div className='overlay-btns'>
		// 		<PrimaryButton
		// 			className='overlay-btn'
		// 			text='Apply'
		// 			onClick={handleApply}
		// 		/>
		// 		<DefaultButton
		// 			className='overlay-btn'
		// 			text='Cancel'
		// 			onClick={handleClose}
		// 		/>
		// 	</div>
		// </Dialog>
	);
};

export default Overlay;
