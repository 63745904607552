export const ROUTES = {
  documents: "/documents",
  customers: "/customers",
  logs: "/logs",
  guiNumbers: "/gui-numbers",
  userRoles: "/user-roles",
};

export const SUB_ROLES = [
  { key: "SAP", text: "SAP" },
  { key: "Premier", text: "Premier" },
  { key: "MCS", text: "MCS" },
];

export const NEW_GUI_DATA = [
  {
    key: "Jan-Feb",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
  {
    key: "Mar-Apr",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
  {
    key: "May-Jun",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
  {
    key: "Jul-Aug",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
  {
    key: "Sep-Oct",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
  {
    key: "Nov-Dec",
    value: {
      SAP: {
        name: "",
        from: null,
        to: null,
      },
      OOW: {
        name: "",
        from: null,
        to: null,
      },
      MCS: {
        name: "",
        from: null,
        to: null,
      },
      Pre: {
        name: "",
        from: null,
        to: null,
      },
    },
  },
];

export const UPSTREAM_INITIAL_STATE = {
  upstreams: JSON.parse(sessionStorage.getItem("USER_UPSTREAM")) || [],
  tenant: null,
};

export const LOADER_INITIAL_STATE = {
  counter: 0,
};

export const USER_INITIAL_STATE = {
  user: null,
};

/**
 * All action types
 */
export const ACTION_TYPE = {
  /**
   * loader
   */

  SET_LOADER: "SET_LOADER",

  /*
    Upstream
   */
  LOAD_ALL_UPSTREAM: "LOAD_ALL_UPSTREAM",
  LOAD_ALL_UPSTREAM_SUCCESS: "LOAD_ALL_UPSTREAM_SUCCESS",
  LOAD_ALL_UPSTREAM_PENDING: "LOAD_ALL_UPSTREAM_PENDING",
  LOAD_UPSTREAM: "LOAD_UPSTREAM",
  LOAD_UPSTREAM_SUCCESS: "LOAD_UPSTREAM_SUCCESS",
  LOAD_UPSTREAM_PENDING: "LOAD_UPSTREAM_PENDING",
  LOAD_UPSTREAM_ERROR: "LOAD_UPSTREAM_ERROR",
  STORE_UPSTREAM: "STORE_UPSTREAM",
  STORE_UPSTREAM_SUCCESS: "STORE_UPSTREAM_SUCCESS",
  STORE_UPSTREAM_PENDING: "STORE_UPSTREAM_PENDING",
  STORE_UPSTREAM_ERROR: "STORE_UPSTREAM_ERROR",

  /** Tenant */
  STORE_TENANT: "STORE_TENANT",
  STORE_TENANT_SUCCESS: "STORE_TENANT_SUCCESS",
  STORE_TENANT_PENDING: "STORE_TENANT_PENDING",
  STORE_TENANT_ERROR: "STORE_TENANT_ERROR",

  GET_TENANT: "GET_TENANT",
  GET_TENANT_SUCCESS: "GET_TENANT_SUCCESS",
  GET_TENANT_PENDING: "GET_TENANT_PENDING",
  GET_TENANT_ERROR: "GET_TENANT_ERROR",


  /** User */
  STORE_USER: "STORE_USER",
  STORE_USER_SUCCESS: "STORE_USER_SUCCESS",
  STORE_USER_PENDING: "STORE_USER_PENDING",
  STORE_USER_ERROR: "STORE_USER_ERROR",
};
