import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';

import { Pivot, PivotItem } from '@fluentui/react';

import Page from '../../components/Page';
import DocumentsForm from './components/DocumentsForm';
import DocumentsTable from './components/DocumentsTable';
import DocumentsTableCredit from './components/DocumentsTableCredit';
import DocumentsFormCredit from './components/DocumentsFormCredit';

import NotificationBar from '../../components/NotificationBar';
import { MessageBarType } from '@fluentui/react';

import './styles.css';
import { useEffect } from 'react';

function Documents() {
	const [submitData, setSubmitData] = useState({});
	const [itemCountMessage, setItemCountMessage] = useState('');
	const [itemCountMessageCredit, setItemCountMessageCredit] = useState('');
	const [notificationMessage, setNotificationMessage] = useState('');
	const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
	const [submitBtnClickedCredit, setSubmitBtnClickedCredit] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [showTableCredit, setShowTableCredit] = useState(false);
	const [toggled, setToggled] = useState(false);
	const [toggledCredit, setToggledCredit] = useState(false);
	const [currentPageExcludeList, setCurrentPageExcludeList] = useState([]);
	const [currentPageExcludeListCredit, setCurrentPageExcludeListCredit] =
		useState([]);
	const [allExcludeList, setAllExcludeList] = useState([]);
	const [allExcludeListCredit, setAllExcludeListCredit] = useState([]);

	const [documentTypeID, setDocumentTypeID] = useState();

	const user = useSelector((state) => state.user);
	const userRoles = user.user?.userRoles?.map((item) => item.roleName);
	const subDimensionID = user.user?.userList?.subsDimensionID;

	useEffect(() => {
		document.title="Invoice print tool-Documents"
		if (user.user) {
			setDocumentTypeID(subDimensionID === 1 ? 1 : 3);
			setToggled(oowOnly);
			setToggledCredit(oowOnly);
		}
	}, [user]);

	const oowOnly = () => {
		return (
			userRoles?.includes('OOW') &&
			!userRoles?.includes('Finance') &&
			!userRoles?.includes('Operation')
		);
	};

	return (
		<Page title={subDimensionID == 1 ? 'Invoices' : 'Invoices and Credit Memo'}>
			{user.user ? (
				<div className='documents-layout'>
					<LiveAnnouncer>
						<LiveMessage message={notificationMessage} aria-live='polite' />
						<NotificationBar type={MessageBarType.success} />
					</LiveAnnouncer>
					<Pivot>
						<PivotItem
							headerText='Invoice'
							headerButtonProps={{
								'data-order': 1,
								'data-title': 'Invoice Title'
							}}
						>
							{subDimensionID === 1 ? (
								<DocumentsForm
									documentTypeID={1}
									setDocumentTypeID={setDocumentTypeID}
									submitData={submitData}
									setSubmitData={setSubmitData}
									itemCountMessage={itemCountMessage}
									subDimensionID={subDimensionID}
									submitBtnClicked={submitBtnClicked}
									setSubmitBtnClicked={setSubmitBtnClicked}
									setShowTable={setShowTable}
									setAllExcludeList={setAllExcludeList}
									setCurrentPageExcludeList={setCurrentPageExcludeList}
								/>
							) : (
								<DocumentsForm
									documentTypeID={3}
									setDocumentTypeID={setDocumentTypeID}
									submitData={submitData}
									setSubmitData={setSubmitData}
									itemCountMessage={itemCountMessage}
									subDimensionID={subDimensionID}
									submitBtnClicked={submitBtnClicked}
									setSubmitBtnClicked={setSubmitBtnClicked}
									setShowTable={setShowTable}
									setAllExcludeList={setAllExcludeList}
									setCurrentPageExcludeList={setCurrentPageExcludeList}
								/>
							)}
						</PivotItem>
						{subDimensionID === 2 ? (
							<PivotItem headerText='Credit Memo'>
								<DocumentsFormCredit
									documentTypeID={4}
									setDocumentTypeID={setDocumentTypeID}
									submitData={submitData}
									setSubmitData={setSubmitData}
									itemCountMessage={itemCountMessageCredit}
									subDimensionID={subDimensionID}
									submitBtnClicked={submitBtnClickedCredit}
									setSubmitBtnClicked={setSubmitBtnClickedCredit}
									setShowTable={setShowTableCredit}
									setAllExcludeList={setAllExcludeListCredit}
									setCurrentPageExcludeList={setCurrentPageExcludeListCredit}
								/>
							</PivotItem>
						) : (
							''
						)}
					</Pivot>

					<hr className='horizontal-line' />
					{showTable ? (
						<div
							style={{
								display:
									documentTypeID === 3 || documentTypeID === 1
										? 'block'
										: 'none'
							}}
						>
							<DocumentsTable
								documentTypeID={documentTypeID}
								submitData={submitData}
								setItemCountMessage={setItemCountMessage}
								subDimensionID={subDimensionID}
								submitBtnClicked={submitBtnClicked}
								setSubmitBtnClicked={setSubmitBtnClicked}
								toggled={toggled}
								setToggled={setToggled}
								userDetails={user.user}
								setNotificationMessage={setNotificationMessage}
								oowOnly={oowOnly}
								allExcludeList={allExcludeList}
								setAllExcludeList={setAllExcludeList}
								currentPageExcludeList={currentPageExcludeList}
								setCurrentPageExcludeList={setCurrentPageExcludeList}
							/>
						</div>
					) : (
						''
					)}
					{showTableCredit ? (
						<div style={{ display: documentTypeID === 4 ? 'block' : 'none' }}>
							<DocumentsTableCredit
								documentTypeID={documentTypeID}
								submitData={submitData}
								setItemCountMessage={setItemCountMessageCredit}
								subDimensionID={subDimensionID}
								submitBtnClicked={submitBtnClickedCredit}
								setSubmitBtnClicked={setSubmitBtnClickedCredit}
								toggled={toggledCredit}
								setToggled={setToggledCredit}
								userDetails={user.user}
								setNotificationMessage={setNotificationMessage}
								oowOnly={oowOnly}
								allExcludeList={allExcludeListCredit}
								setAllExcludeList={setAllExcludeListCredit}
								currentPageExcludeList={currentPageExcludeListCredit}
								setCurrentPageExcludeList={setCurrentPageExcludeListCredit}
							/>
						</div>
					) : (
						''
					)}
				</div>
			) : (
				''
			)}
		</Page>
	);
}

export default Documents;
