import { Label, Stack } from '@fluentui/react';
import classNames from 'classnames';
import React from 'react';

import s from './styles.module.scss';

function Page({ title, children, className }) {
	return (
		<main id='main' tabIndex={-1}>
			<Stack
				aria-label={`${title} heading level 1`}
				horizontal={false}
				verticalAlign
				className={classNames(s.root, className)}
				role='main'
			>
				<h1 className={s.label}>{title}</h1>
				{children}
			</Stack>
		</main>
	);
}

export default Page;
