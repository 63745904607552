import { apiInstance } from './requestInterceptor';
import axios from 'axios';
import { refreshAccessToken } from '../utils';

export function getFilteredDocuments_OOW(params, documentDateFrom, documentDateTo, pageSize,currentPage, subDimensionID, documentTypeID){
  return apiInstance.get(`/Invoice/GetFilteredDocuments_OOW?pageNumber=`+currentPage+`&pageSize=`+pageSize+`&subsDimensionID=`+subDimensionID+`&documentTypeID=`+documentTypeID+`&documentStatus=`+params.DocumentStatus+`&printStatus=`+params.PrintStatus+`&customerCode=`+params.CustomerCodes+`&documentDateFrom=`+documentDateFrom+`&documentDateTo=`+documentDateTo+`&documentNumberFrom=`+params.DocumentNumberFrom+`&documentNumberTo=`+params.DocumentNumberTo)
}

export function getKoreaInvoiceData(params,documentDateFrom, documentDateTo, pageSize,currentPage, documentTypeID){
  return apiInstance.get(`/Invoice/GetKoreaInvoiceData?pageNumber=`+currentPage+`&pageSize=`+pageSize+`&subsDimensionID=2&documentTypeID=`+documentTypeID+`&documentStatus=`+params.DocumentStatus+`&printStatus=`+params.PrintStatus+`&customerCode=`+params.CustomerCodes+`&documentDateFrom=`+documentDateFrom+`&documentDateTo=`+documentDateTo+`&documentNumberFrom=`+params.DocumentNumberFrom+`&documentNumberTo=`+params.DocumentNumberTo)
}

export function getTaiwanInvoiceData(params,documentDateFrom, documentDateTo, pageSize,currentPage, documentTypeID) {
  return apiInstance.get(`/Invoice/GetTaiwanInvoiceData?pageNumber=`+currentPage+`&pageSize=`+pageSize+`&subsDimensionID=1&documentTypeID=`+documentTypeID+`&documentStatus=`+params.DocumentStatus+`&printStatus=`+params.PrintStatus+`&customerCode=`+params.CustomerCodes+`&documentDateFrom=`+documentDateFrom+`&documentDateTo=`+documentDateTo+`&documentNumberFrom=`+params.DocumentNumberFrom+`&documentNumberTo=`+params.DocumentNumberTo)
}

export async function exportToExcel(params, upstream) {
  const token = await refreshAccessToken();
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/Invoice/ExportToExcel`,
    headers: {
      upstream: upstream,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    responseType: 'arraybuffer',
    data: params
  };
  return axios(config);
}
  
export function markAsVoid(params) {
  return apiInstance.patch(`/Invoice/MarkAsVoid`, params);
}

export function generatePDF(params) {
  const endpoint = params.subsDimesionID == 1 ? 
  apiInstance.post(`/Invoice/GenerateTaiwanPDF`, params) :
  apiInstance.post(`/Invoice/GenerateKoreanPDF`, params);

  return endpoint;
}

export async function printPDFInvoice(params, upstream) {
  const token = await refreshAccessToken();
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/Invoice/DownloadFileFromAzureBlob`,
    headers: {
      upstream: upstream,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      accept: '*/*'
    },
    responseType: 'arraybuffer',
    data: params
  };
  return axios(config);
}
