import {
	Checkbox,
	ChoiceGroup,
	Label,
	Stack,
	TextField
} from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';

import s from './styles.module.scss';
import classNames from 'classnames';
import FormGroupButton from '../../../../components/FormButtonGroup';
import { isNullOrWhitespace } from '../../../../utils';
import LeftPanel from '../../../../components/LeftPanel';

function AddForm({ roles, open, onClose, onSave, subsDimensionID }) {
	const defaultValue = {
		subsDimensionID: subsDimensionID,
		firstName: '',
		lastName: '',
		email: '',
		roles: []
	};

	const options =
		subsDimensionID === 2
			? [{ key: 'SAP', text: 'SAP' }]
			: [
					{ key: 'SAP', text: 'SAP' },
					{ key: 'Premier', text: 'Premier' },
					{ key: 'MCS', text: 'MCS' }
			  ];

	const upstreams =
		subsDimensionID === 2
			? {
					SAP: 'SAP'
			  }
			: {
					SAP: 'SAP',
					MCS: 'MCS',
					PREMIER: 'Premier'
			  };

	const [form, setForm] = useState(defaultValue);
	const [emailError, setEmailError] = useState(false);
	const roleMap = useMemo(() => {
		return new Map(roles.map((i) => [i.name.toLowerCase(), i.id]));
	}, [roles]);

	const isValid = useCallback((form) => {
		if (isNullOrWhitespace(form.firstName)) {
			return false;
		}
		if (isNullOrWhitespace(form.lastName)) {
			return false;
		}
		if (isNullOrWhitespace(form.email)) {
			return false;
		}
		if (isNullOrWhitespace(form.roles)) {
			return false;
		}

		return true;
	}, []);

	const onCheckChange = useCallback((form, value, checked) => {
		const temp = _.cloneDeep(form);
		if (!temp.roles && checked) {
			temp.roles = [
				{
					roleID: value,
					upstreamName: upstreams.SAP
				}
			];
		} else if (temp.roles.some((role) => role.roleID === value) && !checked) {
			temp.roles = temp.roles.filter((role) => role.roleID !== value);
		} else if (!temp.roles.some((role) => role.roleID === value) && checked) {
			temp.roles.push({
				roleID: value,
				upstreamName: upstreams.SAP
			});
		}

		setForm(temp);
	}, []);

	const changeField = useCallback((field, value, form) => {
		const formTemp = _.cloneDeep(form);
		formTemp[field] = value;
		setForm(formTemp);
	}, []);

	const changeUpstreamField = useCallback(
		(form, checked, roleID, upstreamName) => {
			const x = _.cloneDeep(form);
			if (!x.roles && checked) {
				x.roles = [{ roleID: roleID, upstreamName: upstreamName }];
			} else if (
				x.roles.some(
					(role) => role.roleID === roleID && role.upstreamName === upstreamName
				) &&
				!checked
			) {
				x.roles = x.roles.filter(
					(role) =>
						!(role.roleID === roleID && role.upstreamName === upstreamName)
				);
			} else if (
				!x.roles.some(
					(role) => role.roleID === roleID && role.upstreamName === upstreamName
				) &&
				checked &&
				roleID !== roleMap.get('operation')
			) {
				x.roles.push({
					roleID: roleID,
					upstreamName: upstreamName
				});
			} else if (roleID === roleMap.get('operation')) {
				x.roles = x.roles.filter(
					(role) => role.roleID !== roleMap.get('operation')
				);
				x.roles.push({
					roleID: roleID,
					upstreamName: upstreamName
				});
			}

			setForm(x);
		},
		[roleMap]
	);

	return (
		<LeftPanel open={open} onClose={onClose} hasCloseIcon={true}>
			<Stack
				role='form'
				className={s.root}
				horizontal={false}
				verticalFill={true}
				verticalAlign={'space-between'}
			>
				<div className={s.group}>
					<h2 className={s.label}>Add New User</h2>
				</div>
				<div className={s.group}>
					<TextField
						id={useId('firstname')}
						label='First Name'
						ariaLabel='First Name'
						value={form.firstName}
						onChange={(e) => {
							changeField('firstName', e.target.value, form);
						}}
						required
					/>
				</div>
				<div className={s.group}>
					<TextField
						id={useId('lastname')}
						label='Last Name'
						ariaLabel='Last Name'
						value={form.lastName}
						onChange={(e) => {
							changeField('lastName', e.target.value, form);
						}}
						required
					/>
				</div>
				<div className={s.group}>
					<TextField
						id={useId('email')}
						label='Microsoft Email'
						ariaLabel='Microsoft Email'
						value={form.email}
						onChange={(e) => {
							changeField('email', e.target.value, form);
						}}
						required
						errorMessage={emailError ? 'Please provide a valid email' : ''}
					/>
				</div>

				<div className={classNames(s.group, s.checkboxes)}>
					<Label
						className={s.fieldLabel}
						htmlFor={useId('finance')}
						tabindex='0'
					>
						Roles
					</Label>
					<Checkbox
						id={useId('finance')}
						className={s.checkbox}
						label='Finance'
						ariaLabel='Roles Finance 1 of 4'
						checked={
							form.roles &&
							form.roles.find((x) => x.roleID === roleMap.get('finance'))
						}
						onChange={(e, checked) => {
							onCheckChange(form, roleMap.get('finance'), checked);
						}}
					/>
					{form.roles &&
						form.roles.find((x) => x.roleID === roleMap.get('finance')) && (
							<Stack className={s.finance} horizontal horizontalAlign={'start'}>
								{subsDimensionID === 2 ? (
									<Checkbox
										label='SAP'
										ariaLabel='SAP 1 of 1'
										checked={form.roles.find(
											(x) =>
												x.roleID === roleMap.get('finance') &&
												x.upstreamName === upstreams.SAP
										)}
										onChange={(e, checked) => {
											changeUpstreamField(
												form,
												checked,
												roleMap.get('finance'),
												upstreams.SAP
											);
										}}
									/>
								) : (
									<>
										<Checkbox
											label='SAP'
											ariaLabel='SAP 1 of 3'
											checked={form.roles.find(
												(x) =>
													x.roleID === roleMap.get('finance') &&
													x.upstreamName === upstreams.SAP
											)}
											onChange={(e, checked) => {
												changeUpstreamField(
													form,
													checked,
													roleMap.get('finance'),
													upstreams.SAP
												);
											}}
										/>
										<Checkbox
											label='Premier'
											ariaLabel='Premier 2 of 3'
											checked={form.roles.find(
												(x) =>
													x.roleID === roleMap.get('finance') &&
													x.upstreamName === upstreams.PREMIER
											)}
											onChange={(e, checked) => {
												changeUpstreamField(
													form,
													checked,
													roleMap.get('finance'),
													upstreams.PREMIER
												);
											}}
										/>
										<Checkbox
											label='MCS'
											ariaLabel='MCS 3 of 3'
											checked={form.roles.find(
												(x) =>
													x.roleID === roleMap.get('finance') &&
													x.upstreamName === upstreams.MCS
											)}
											onChange={(e, checked) => {
												changeUpstreamField(
													form,
													checked,
													roleMap.get('finance'),
													upstreams.MCS
												);
											}}
										/>
									</>
								)}
							</Stack>
						)}
					<Checkbox
						className={s.checkbox}
						label='Operation'
						ariaLabel='Operation 2 of 4'
						checked={
							form.roles &&
							form.roles.find((x) => x.roleID === roleMap.get('operation'))
						}
						onChange={(e, checked) => {
							onCheckChange(form, roleMap.get('operation'), checked);
						}}
					/>
					{form.roles &&
						form.roles.find((x) => x.roleID === roleMap.get('operation')) && (
							<ChoiceGroup
								className={s.choice}
								options={options}
								selectedKey={
									form.roles.find((x) => x.roleID === roleMap.get('operation'))
										.upstreamName
								}
								onChange={(e, option) => {
									changeUpstreamField(
										form,
										true,
										roleMap.get('operation'),
										option.key
									);
								}}
							/>
						)}
					<Checkbox
						className={s.checkbox}
						label='Admin'
						ariaLabel='Admin 3 of 4'
						checked={
							form.roles &&
							form.roles.find((x) => x.roleID === roleMap.get('admin'))
						}
						onChange={(e, checked) => {
							onCheckChange(form, roleMap.get('admin'), checked);
						}}
					/>
					<Checkbox
						className={s.checkbox}
						label='OOW'
						ariaLabel='OOW 4 of 4'
						checked={
							form.roles &&
							form.roles.find((x) => x.roleID === roleMap.get('oow'))
						}
						onChange={(e, checked) => {
							onCheckChange(form, roleMap.get('oow'), checked);
						}}
					/>
				</div>
				<FormGroupButton
					disabled={!isValid(form)}
					className={s.buttons}
					handleCancel={onClose}
					handleSave={() => {
						if (form.email.includes('@microsoft.com')) {
							onSave(form);
							setForm(defaultValue);
						} else {
							setEmailError(true);
						}
					}}
					position={'start'}
				/>
			</Stack>
		</LeftPanel>
	);
}

export default AddForm;
