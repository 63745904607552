import { EventType, PublicClientApplication } from "@azure/msal-browser";
import _ from "lodash";
import { loginRequest, msalConfig } from "./authConfig";
import { getUpstream } from "./services/upstream";

export const isNullOrWhitespace = (str) => {
  return _.isUndefined(str) || _.isNull(str) || str.length === 0;
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getRoleString = (roles) => {
  const roleParts = [];
  const grouped = _.groupBy(roles, "roleName");
  const keys = Object.keys(grouped);
  keys.forEach((x) => {
    const upstreams = grouped[x].map((y) => y.upstreamName).filter((y) => !!y);
    roleParts.push(
      `${x}${upstreams && upstreams.length > 0 ? ":" : ""}${upstreams.join(
        ","
      )}`
    );
  });

  return roleParts.join(";");
};

export const refreshAccessToken = async () => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    const token = await msalInstance.acquireTokenSilent({
      account,
      scopes: loginRequest.scopes,
    });
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          return resp.accessToken;
        })
        .catch((err) => {
          msalInstance.logoutRedirect();
        });
    } else {
      msalInstance.logoutRedirect();
    }
  }
};

export const getUserUpstream = async () => {
  try {
    const upstreams = await getUpstream();
    return upstreams.data[0].upstreamName;
  } catch (error) {
    return null;
  }
};
