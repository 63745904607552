/**
 * Reducer for Upstream
 */

import { ACTION_TYPE, UPSTREAM_INITIAL_STATE } from "../constants";

const upstreamReducer = (state = UPSTREAM_INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPE.STORE_UPSTREAM: {
      const u = state.upstreams.map((x) => ({
        ...x,
        selected: false
      }))
      u.forEach((x) => {
        x.selected = x.upStreamId === action.payload;
      })
      sessionStorage.setItem("USER_UPSTREAM", JSON.stringify(u));
      return {
          ...state,
          upstreams: u,
        };
    }
    case ACTION_TYPE.LOAD_UPSTREAM_SUCCESS: {
      const upstreams = action.payload;
      const firstName = upstreams.sort((a, b) => a.upStreamId - b.upStreamId)[0]?.upstreamName;
      const upstreamToBeStored = upstreams.map((x) => ({
          ...x,
          selected: firstName === x.upstreamName,
        }))
      sessionStorage.setItem("USER_UPSTREAM", JSON.stringify(upstreamToBeStored));
      return {
        ...state,
        upstreams: upstreamToBeStored,
      };
    }
    case ACTION_TYPE.STORE_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    default:
      return state;
  }
};

export default upstreamReducer;