import React, { useCallback, useEffect, useState } from 'react';
import {
	DefaultButton,
	PrimaryButton,
	Stack,
	TextField
} from '@fluentui/react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';

import s from './styles.module.scss';
import classNames from 'classnames';
import { isNullOrWhitespace } from '../../../../utils';
import _ from 'lodash';
import { saveGUIDetails } from '../../../../services/guiNumber';
import { useSelector } from 'react-redux';

const upstreamStyles = {
	fieldGroup: {
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: '#c6c6c6',
		borderRadius: '2px'
	},
	prefix: {
		width: '45px',
		borderRightStyle: 'solid',
		borderRightWidth: 1,
		borderRightColor: '#c6c6c6',
		padding: '0px',
		justifyContent: 'center',
		color: '#171717'
	},
	root: {
		width: '100px'
	}
};

const textfieldStyles = {
	fieldGroup: {
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: '#c6c6c6',
		borderRadius: '2px'
	},
	prefix: {
		width: '45px',
		borderRightStyle: 'solid',
		borderRightWidth: 1,
		borderRightColor: '#c6c6c6',
		padding: '0px',
		justifyContent: 'center',
		color: '#171717'
	},
	root: {
		width: '125px;'
	}
};

function Form({
	isNew,
	formData,
	header,
	setIsNew,
	setShowMessage,
	isModified,
	setIsModified
}) {
	const [isEditing, setIsEditing] = useState(false);
	const [data, setData] = useState(null);
	const [message, setMessage] = useState('');
	const [editBtnAria, setEditBtnAria] = useState('');
	const [error, setError] = useState([false, false, false]);

	const upstream = useSelector((state) => state.upstream);
	const currentUpstream = upstream?.upstreams?.find(
		(x) => x.selected
	)?.upstreamName;

	const dt = new Date();
	const segID =
		dt.getMonth() == (0 || 1)
			? 1
			: dt.getMonth() == (2 || 3)
			? 2
			: dt.getMonth() == (4 || 5)
			? 3
			: dt.getMonth() == (6 || 7)
			? 4
			: dt.getMonth() == (8 || 9)
			? 5
			: 6;

	useEffect(() => {
		if (formData) {
			console.log(formData);
			setData(formData);
			setEditBtnAria(
				`label ${header}, ${formData?.name}:${formData?.prefix} From:${formData?.from} To:${formData?.to} Edit`
			);
		}
	}, [formData]);

	const changeValue = (field, value) => {
		if (field === 'from' || field === 'to') {
			const reg = new RegExp(/^\d*\.?\d*$/);
			if (!reg.test(value)) {
				return;
			}
		}

		const tempData = _.cloneDeep(data);
		tempData[field] = value;
		setData(tempData);
	};

	const onCancel = () => {
		setData(formData);
		if(isEditing === false)
		{
			setMessage('No changes made. Click on edit to make changes');
			setTimeout(() => {
				setMessage('');
			}, 1000);
		}
		else{
			setIsEditing(false);
			setMessage('changes cancelled successfully');
			setTimeout(() => {
				setMessage('');
			}, 1000);
		}
		
	};

	function checkErrors() {
		const errors = [...error];
		//debugger;
		errors[0] = data.prefix === null;
		errors[1] = data.prefix === '';
		errors[2] = data.from === '';
		errors[3] = data.to === '';
		errors[4] = Number(data.from) <= 0;
		errors[5] = Number(data.to) <= 0;
		errors[6] = Number(data.from) > Number(data.to);

		setError(errors);
	}

	const onSave = (event) => {
		if (isEditing || isNew) {
			if (
				data.prefix !== null &&
				data.prefix !== '' &&
				data.to !== '' &&
				data.from !== '' &&
				Number(data.from) > 0 &&
				Number(data.to) > 0 &&
				Number(data.from) < Number(data.to)
			) {
				checkErrors();
				setShowMessage(true);
				setTimeout(() => {
					setShowMessage(false);
				}, 4000);
				setMessage('alert');
				setEditBtnAria('');
				setTimeout(() => {
					setEditBtnAria(
						`label ${header}, ${data?.name}:${data?.prefix} From:${data?.from} To:${data?.to} Edit`
					);
				}, 500);

				const guiData = {
					subsDimensionID: data.subsDimensionID,
					taxFlag: data.taxFlag,
					prefix: data.prefix,
					guiYear: data.guiYear,
					rangeFrom: data.from,
					rangeTo: data.to,
					userName: data.userName,
					guiSegmentRowNum: data.guiSegmentRowNum,
					isOOW: data.isOOW
				};
				saveGUIDetails(guiData, currentUpstream)
					.then((res) => {
						setIsModified(!isModified);
					})
					.catch((e) => console.log(e));
				setIsNew(false);
				setIsEditing(false);
			} else {
				checkErrors();
				//console.log(error);
				//console.log(data);
				if(error[0] || error[1])
				{
					setTimeout(() => {
						document.getElementById('txt_' + data?.guiSegmentRowNum + '_' + header +'_' + formData?.name).focus();
					},1000);
				}
				else if(error[3] || error[5] || error[6]){
					setTimeout(() => {
						document.getElementById('txt2_' + data?.guiSegmentRowNum + '_' + header +'_' + formData?.name).focus();
					},500);
				}
				else if(error[2] || error[4] || error[6]){
					setTimeout(() => {
						document.getElementById('txt1_' + data?.guiSegmentRowNum + '_' + header +'_' + formData?.name).focus();
					},500);
				}
			}
		} else {
 			setMessage('to edit details');
			setIsEditing(true);
			console.log(data);
			setTimeout(() => {
				document.getElementById('txt_' + data?.guiSegmentRowNum + '_' + header +'_' + formData?.name).focus();
			},500);
			
		}
	};

	return (
		<div className={s.root}>
			<TextField
				id={`txt_${data?.guiSegmentRowNum}_${header}_${formData?.name}`}
				styles={upstreamStyles}
				prefix={data?.name}
				value={data?.prefix}
				ariaLabel={`${data?.name} name text box`}
				disabled={
					(!isEditing && !isNew) ||
					data?.guiYear < dt.getFullYear() ||
					(data?.guiYear == dt.getFullYear() && data?.guiSegmentRowNum < segID)
				}
				className={classNames(s.textbox)}
				onChange={(e) => changeValue('prefix', e.target.value)}
				required
				errorMessage={error[0] || error[1] ? 'Value missing' : ''}
			/>
			<Stack horizontal={true}>
				<TextField
					id={`txt1_${data?.guiSegmentRowNum}_${header}_${formData?.name}`}
					styles={textfieldStyles}
					prefix={'From'}
					value={data?.from}
					ariaLabel='From text box'
					disabled={
						(!isEditing && !isNew) ||
						data?.guiYear < dt.getFullYear() ||
						(data?.guiYear == dt.getFullYear() &&
							data?.guiSegmentRowNum < segID)
					}
					className={classNames(s.textbox)}
					onChange={(e) => changeValue('from', e.target.value)}
					required
					errorMessage={
						error[2]
							? 'Value missing'
							: error[4]
							? 'Value should be greater than 0'
							: error[6]
							? 'from value should be less than to value'
							: ''
					}
					maxLength='8'
				/>
				<TextField
					id={`txt2_${data?.guiSegmentRowNum}_${header}_${formData?.name}`}
					styles={textfieldStyles}
					prefix={'To'}
					value={data?.to}
					ariaLabel='To text box'
					disabled={
						(!isEditing && !isNew) ||
						data?.guiYear < dt.getFullYear() ||
						(data?.guiYear == dt.getFullYear() &&
							data?.guiSegmentRowNum < segID)
					}
					className={classNames(s.textbox)}
					onChange={(e) => changeValue('to', e.target.value)}
					required
					errorMessage={
						error[3]
							? 'Value missing'
							: error[5]
							? 'Value should be greater than 0'
							: error[6]
							? 'to value should be greater than from value'
							: ''
					}
					maxLength='8'
				/>
			</Stack>
			<Stack horizontal={true} className={classNames(s.buttons)}>
				<LiveAnnouncer>
					<LiveMessage message={message} aria-live='polite' />
					<PrimaryButton
						className={s.save}
						onClick={onSave}
						ariaLabel={isEditing || isNew ? 'Save' : editBtnAria}
					>
						{isEditing || isNew ? 'Save' : 'Edit'}
					</PrimaryButton>
					<DefaultButton
						className={s.cancel}
						ariaLabel={`label ${header}, ${data?.name}:${data?.prefix} From:${data?.from} To:${data?.to} Cancel`}
						onClick={onCancel}
					>
						Cancel
					</DefaultButton>
				</LiveAnnouncer>
			</Stack>
		</div>
	);
}

export default Form;
