/**
 * Root Redux Reducer
 */
import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import upstreamReducer from "./upstreamReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  upstream: upstreamReducer,
  loader: loaderReducer,
  user: userReducer,

});

export default rootReducer;
