import React from 'react';
import { Label, Link, Separator, Stack } from '@fluentui/react';

import s from './styles.module.scss';
import classNames from 'classnames';

import FileSaver from 'file-saver';

function About({ open, onClose }) {
	const downloadManual = () => {
		const file =
			process.env.PUBLIC_URL + '/aboutPage/KTIPT VNext_User Manual.docx';
		FileSaver.saveAs(file, 'KTIPT VNext_User Manual.docx');
	};
	return (
		<Stack
			className={s.root}
			horizontal={false}
			verticalFill={true}
			verticalAlign={'start'}
		>
			<Label className={s.subHeader}>Microsoft</Label>
			<Label className={s.subHeader}>Version 1.0.12.0</Label>
			<div className={s.text}>
				The IPT tool is going to be used by the Taiwan and Korea subsidiaries
				for printing Invoices and Credit memo. The objective of this project is
				to build a tool to support robust error handling and high reliability.
			</div>
			<Separator className={s.separator} />
			<Label>Featured Help</Label>
			<Link className={s.label} href='/raiseticket'>
				Raise a Ticket
			</Link>
			<Link className={s.label} onClick={downloadManual}>
				Functional Specification
			</Link>
			<Link
				className={classNames(s.label, s.support)}
				href='mailto:iptitsupport@microsoft.com'
			>
				Support
			</Link>
			<Link
				className={s.label}
				href='https://privacy.microsoft.com/en-us/privacystatement'
			>
				Privacy Statement
			</Link>
			<Label className={classNames(s.copyright)}>
				<span dangerouslySetInnerHTML={{ __html: '&copy;' }} />
				Microsoft {new Date().getFullYear()}
			</Label>
		</Stack>
	);
}

export default About;
