import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import {
	Checkbox,
	ChoiceGroup,
	IconButton,
	Stack,
	TooltipHost
} from '@fluentui/react';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import s from './styles.module.scss';
import FormGroupButton from '../../../../components/FormButtonGroup';
import Confirm from '../../../../components/Confirm';
import { useSelector } from 'react-redux';
import { registerIcons } from '@fluentui/react/lib/Styling';

function TableForm({
	roles,
	data,
	onSave,
	onDelete,
	onUpdate,
	subsDimensionID
}) {
	const upstreams = {
		SAP: 'SAP',
		MCS: 'MCS',
		PREMIER: 'Premier'
	};
	const upstream = useSelector((state) => state.upstream);

	const [formData, setFormData] = useState([]);
	const [items, setItems] = useState([]);
	const [updated, setUpdated] = useState(false);

	registerIcons({
		icons: {
			Trash: <TrashIcon />
		}
	});

	const trashIcon = { iconName: 'Trash' };
	const updateIcon = { iconName: 'PencilReply' };

	const roleMap = useMemo(() => {
		return new Map(roles.map((i) => [i.name.toLowerCase(), i.id]));
	}, [roles]);

	const hasChange = useCallback((data, formData) => {
		setUpdated(formData && formData.length > 0 && !_.isEqual(data, formData));
	}, []);

	const onCheckChange = useCallback((form, id, value, checked) => {
		const temp = _.cloneDeep(form);
		temp.forEach((x) => {
			if (x.userID === id) {
				if ((!x.roles || x.roles.length === 0) && checked) {
					x.roles = [
						{
							roleID: value,
							upstreamName: 'SAP'
						}
					];
				} else if (x.roles.some((role) => role.roleID === value) && !checked) {
					x.roles = x.roles.filter((role) => role.roleID !== value);
				} else if (!x.roles.some((role) => role.roleID === value) && checked) {
					x.roles.push({
						roleID: value,
						upstreamName: 'SAP'
					});
				}
			}
		});

		setFormData(temp);
	}, []);

	const changeUpstreamField = useCallback(
		(id, checked, form, roleID, upstreamName, clear = false) => {
			const temp = _.cloneDeep(form);
			temp.forEach((x) => {
				if (x.userID === id) {
					if (!x.roles && checked) {
						x.roles = [{ roleID: roleID, upstreamName: upstreamName }];
					} else if (
						x.roles.some(
							(role) =>
								role.roleID === roleID && role.upstreamName === upstreamName
						) &&
						!checked
					) {
						x.roles = x.roles.filter(
							(role) =>
								!(role.roleID === roleID && role.upstreamName === upstreamName)
						);
					} else if (
						!x.roles.some(
							(role) =>
								role.roleID === roleID && role.upstreamName === upstreamName
						) &&
						checked &&
						roleID !== roleMap.get('operation')
					) {
						x.roles.push({
							roleID: roleID,
							upstreamName: upstreamName
						});
					} else if (roleID === roleMap.get('operation')) {
						x.roles = x.roles.filter(
							(role) => role.roleID !== roleMap.get('operation')
						);
						x.roles.push({
							roleID: roleID,
							upstreamName: upstreamName
						});
					}
				}
			});

			setFormData(temp);
		},
		[roleMap]
	);

	const generateDocuments = useCallback((formdata) => {
		setItems(
			formdata.map((x) => {
				return {
					...x,
					name: `${x.firstName} ${x.lastName}`,
					finance: x.roles?.finance,
					operation: x.roles?.operation,
					admin: x.roles?.admin,
					oow: x.roles?.oow
				};
			})
		);
	}, []);

	useEffect(() => {
		if (data && data.length > 0 && roles && roles.length > 0) {
			data.forEach((x) => {
				//x.roles = x.roles ? _.uniqBy(x.roles, 'roleID') : null;
				x.roles?.forEach((y) => {
					if (
						(y.roleID === roleMap.get('finance') ||
							y.roleID === roleMap.get('operation')) &&
						!y.upstreamName
					) {
						y.upstreamName = upstream?.upstreams?.find(
							(x) => x.selected
						).upstreamName;
					}
				});
			});
			setFormData(data);
		}
	}, [data, roles]);

	useEffect(() => {
		if (formData && formData.length > 0) {
			generateDocuments(formData);
			hasChange(data, formData);
		}
	}, [formData]);

	return (
		<>
			<div className={s.root} role='form'>
				<div className={s.tableContainer}>
					<table>
						<thead>
							<tr>
								<th>User Name</th>
								<th>Finance</th>
								<th>Operation</th>
								<th>Admin</th>
								<th>OOW</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{items.map((x, i) => (
								<Fragment key={i}>
									<tr>
										<td data-column='User Name'>{`${x.firstName} ${x.lastName}`}</td>
										<td data-column='Finance'>
											<Checkbox
												ariaLabel={`chk-${i}-finance for ${x.firstName} ${x.lastName}`}
												className={s.checkbox}
												checked={
													!!x.roles &&
													x.roles.filter(
														(role) => role.roleID === roleMap.get('finance')
													).length > 0
												}
												onChange={(e, checked) => {
													onCheckChange(
														formData,
														x.userID,
														roleMap.get('finance'),
														checked
													);
												}}
											/>
										</td>
										<td data-column='Operation'>
											<Checkbox
												ariaLabel={`chk-${i}-operation for ${x.firstName} ${x.lastName}`}
												className={s.checkbox}
												checked={
													!!x.roles &&
													x.roles.filter(
														(role) => role.roleID === roleMap.get('operation')
													).length > 0
												}
												onChange={(e, checked) => {
													onCheckChange(
														formData,
														x.userID,
														roleMap.get('operation'),
														checked
													);
												}}
											/>
										</td>
										<td data-column='Admin'>
											<Checkbox
												ariaLabel={`chk-${i}-admin for ${x.firstName} ${x.lastName}`}
												className={s.checkbox}
												checked={
													!!x.roles &&
													x.roles.filter(
														(role) => role.roleID === roleMap.get('admin')
													).length > 0
												}
												onChange={(e, checked) => {
													onCheckChange(
														formData,
														x.userID,
														roleMap.get('admin'),
														checked
													);
												}}
											/>
										</td>
										<td data-column='OOW'>
											<Checkbox
												ariaLabel={`chk-${i}-oow for ${x.firstName} ${x.lastName}`}
												className={s.checkbox}
												checked={
													!!x.roles &&
													x.roles.filter(
														(role) => role.roleID === roleMap.get('oow')
													).length > 0
												}
												onChange={(e, checked) => {
													onCheckChange(
														formData,
														x.userID,
														roleMap.get('oow'),
														checked
													);
												}}
											/>
										</td>
										<td data-column='Action'>
											<Stack
												horizontalAlign='center'
												horizontal={true}
												verticalAlign
											>
												<TooltipHost content='Delete Row'>
													<IconButton
														iconProps={trashIcon}
														aria-label='Delete Row'
														onClick={() => {
															onDelete(x);
														}}
													/>
												</TooltipHost>
												<TooltipHost content='Update Row'>
													<IconButton
														iconProps={updateIcon}
														aria-label='Update Row'
														onClick={() => {
															onUpdate(x);
														}}
													/>
												</TooltipHost>
											</Stack>
										</td>
									</tr>
									{!!x.roles &&
										(x.roles.some(
											(role) => role.roleID === roleMap.get('finance')
										) ||
											x.roles.some(
												(role) => role.roleID === roleMap.get('operation')
											)) && (
											<tr key={`${i}_test`}>
												<td className={s.blank} data-column='User Name'>blank</td>
												<td data-column='Finance'>
													{!!x.roles &&
														x.roles.find(
															(role) => role.roleID === roleMap.get('finance')
														) && (
															<Stack
																className={s.finance}
																horizontal={false}
																horizontalAlign={'start'}
															>
																{subsDimensionID === 2 ? (
																	<Checkbox
																		ariaLabel={`SAP checkbox for ${x.firstName} ${x.lastName}`}
																		key='SAP'
																		label='SAP'
																		checked={
																			!!x.roles.find(
																				(role) =>
																					role.roleID ===
																						roleMap.get('finance') &&
																					role.upstreamName === upstreams.SAP
																			)
																		}
																		onChange={(e, checked) => {
																			changeUpstreamField(
																				x.userID,
																				checked,
																				formData,
																				roleMap.get('finance'),
																				upstreams.SAP
																			);
																		}}
																	/>
																) : (
																	<>
																		<Checkbox
																			ariaLabel={`SAP checkbox for ${x.firstName} ${x.lastName}`}
																			key='SAP'
																			label='SAP'
																			checked={
																				!!x.roles.find(
																					(role) =>
																						role.roleID ===
																							roleMap.get('finance') &&
																						role.upstreamName === upstreams.SAP
																				)
																			}
																			onChange={(e, checked) => {
																				changeUpstreamField(
																					x.userID,
																					checked,
																					formData,
																					roleMap.get('finance'),
																					upstreams.SAP
																				);
																			}}
																		/>
																		<Checkbox
																			ariaLabel={`Premier checkbox for ${x.firstName} ${x.lastName}`}
																			key='Premier'
																			label='Premier'
																			checked={
																				!!x.roles.find(
																					(role) =>
																						role.roleID ===
																							roleMap.get('finance') &&
																						role.upstreamName ===
																							upstreams.PREMIER
																				)
																			}
																			onChange={(e, checked) => {
																				changeUpstreamField(
																					x.userID,
																					checked,
																					formData,
																					roleMap.get('finance'),
																					upstreams.PREMIER
																				);
																			}}
																		/>
																		<Checkbox
																			ariaLabel={`MCS checkbox for ${x.firstName} ${x.lastName}`}
																			key='MCS'
																			label='MCS'
																			checked={
																				!!x.roles.find(
																					(role) =>
																						role.roleID ===
																							roleMap.get('finance') &&
																						role.upstreamName === upstreams.MCS
																				)
																			}
																			onChange={(e, checked) => {
																				changeUpstreamField(
																					x.userID,
																					checked,
																					formData,
																					roleMap.get('finance'),
																					upstreams.MCS
																				);
																			}}
																		/>
																	</>
																)}
															</Stack>
														)}
												</td>
												<td data-column='Operation'>
													{!!x.roles &&
														x.roles.find(
															(role) => role.roleID === roleMap.get('operation')
														) && (
															<ChoiceGroup
																className={s.choice}
																options={
																	subsDimensionID === 2
																		? [
																				{
																					key: 'SAP',
																					text: 'SAP',
																					ariaLabel: `SAP radio button for ${x.firstName} ${x.lastName}`
																				}
																		  ]
																		: [
																				{
																					key: 'SAP',
																					text: 'SAP',
																					ariaLabel: `SAP radio button for ${x.firstName} ${x.lastName}`
																				},
																				{
																					key: 'Premier',
																					text: 'Premier',
																					ariaLabel: `Premier radio button for ${x.firstName} ${x.lastName}`
																				},
																				{
																					key: 'MCS',
																					text: 'MCS',
																					ariaLabel: `MCS radio button for ${x.firstName} ${x.lastName}`
																				}
																		  ]
																}
																selectedKey={
																	x.roles.find(
																		(role) =>
																			role.roleID === roleMap.get('operation')
																	).upstreamName
																}
																onChange={(e, option) => {
																	changeUpstreamField(
																		x.userID,
																		true,
																		formData,
																		roleMap.get('operation'),
																		option.key,
																		true
																	);
																}}
															/>
														)}
												</td>
												<td className={s.blank} data-column='Admin'>blank</td>
												<td className={s.blank} data-column='OOW'>blank</td>
												<td className={s.blank} data-column='Delete'>blank</td>
											</tr>
										)}
								</Fragment>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}

export default TableForm;
