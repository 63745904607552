import { getRoleString } from "../utils";
import { apiInstance } from "./requestInterceptor";
import axios from 'axios';
import { refreshAccessToken } from '../utils';

/**
 * Get the users by dimention.
 * @param {string} dimentionId the sub dimention id
 * @returns the collection of user.
 */
export function getUsers(dimentionId) {
  return apiInstance.get(
    `/User/GetUsersWithRoles?SubsDimensionID=${dimentionId}`
  );
}

/**
 * Delete user
 * @param {{userID, subsDimensionID, userName}} data
 * @returns null.
 */
export function deleteUsers({ userID, subsDimensionID, userName }) {
  return apiInstance.post(`/User/DeleteUser`, {
    userID,
    subsDimensionID,
    userName,
  });
}

/**
 * Create an user
 * @param {{firstName, lastName, subsDimensionID, userName, email, roles}} data
 * @returns null.
 */
export function createUsers({
  firstName,
  lastName,
  subsDimensionID,
  userName,
  email,
  roles,
}) {
  return apiInstance.post(`/User/AddUser`, {
    firstName,
    lastName,
    subsDimensionID,
    userName,
    email,
    roles: getRoleString(roles),
  });
}

/**
 * Create an user
 * @param {{userID, subsDimensionID, userName, roles}} data
 * @returns null.
 */
 
export function updateRoles({  subsDimensionID, userName, roles,userID }) {
  return apiInstance.post(`/User/UpdateUserRole`, {
    userID,
    subsDimensionID,
    userName,
    roles: getRoleString(roles),
  });
}

const roles = [
  {
    id: 1,
    name: "Admin",
  },
  {
    id: 2,
    name: "Operation",
  },
  {
    id: 3,
    name: "Finance",
  },
  {
    id: 4,
    name: "OOW",
  },
];

/**
 * Get all the roles.
 * @returns role collections.
 */
export function getRoles() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: roles });
    }, 300);
  });
}

/**
 * Get current user with roles.
 * @returns current user information.
 */

 export async function getUserWithRoles(userAlias) {
  const token = await refreshAccessToken()
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/User/GetUserWithRoles`,
    // `https://iptapiuat.azurewebsites.net/api/v1.0/User/GetUserWithRoles`,
    headers: {
      upstream: 'SAP',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}