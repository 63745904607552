import React from "react";
import { Label } from "@fluentui/react";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import s from "./styles.module.scss";

function AccordionHeader({ header }) {
  return (
    <div className={s.root}>
      <CalendarIcon /> <Label>{header}</Label>
    </div>
  );
}

export default AccordionHeader;
