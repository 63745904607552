import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomers } from '../../../services/customers';

import { Stack, DatePicker, defaultDatePickerStrings } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { TextField } from '@fluentui/react/lib/TextField';
import { ComboBox, SelectableOptionMenuItemType } from '@fluentui/react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import { hideLoader, showLoader } from '../../../actions/loaderAction';

const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

const DocumentsFormCredit = ({
	documentTypeID,
	setDocumentTypeID,
	submitData,
	setSubmitData,
	itemCountMessage,
	subDimensionID,
	submitBtnClicked,
	setSubmitBtnClicked,
	setShowTable,
	setAllExcludeList,
	setCurrentPageExcludeList
}) => {
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [customerOptions, setCustomerOptions] = useState([]);
	const [currentCustomerOptions, setCurrentCustomerOptions] = useState([]);
	const [paramCustomerCodes, setParamCustomerCodes] = useState([]);
	const [documentNumberFrom, setDocumentNumberFrom] = useState(
		Object.keys(submitData).length === 0 ? '' : submitData.DocumentNumberFrom
	);
	const [documentNumberTo, setDocumentNumberTo] = useState(
		Object.keys(submitData).length === 0 ? '' : submitData.DocumentNumberTo
	);
	const [params, setParams] = useState(
		Object.keys(submitData).length === 0
			? {
					DocumentStatus: 'Completed',
					PrintStatus: 'Unprinted',
					DocumentDateFrom: null,
					DocumentDateTo: null,
					DocumentNumberFrom: '',
					DocumentNumberTo: ''
			  }
			: {
					DocumentStatus: submitData.DocumentStatus,
					PrintStatus: submitData.PrintStatus,
					DocumentDateFrom: submitData.DocumentDateFrom,
					DocumentDateTo: submitData.DocumentDateTo,
					DocumentNumberFrom: '',
					DocumentNumberTo: ''
			  }
	);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState('');
	const [currentTyped, setCurrentTyped] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		setDocumentTypeID(documentTypeID);
	}, []);

	useEffect(() => {
		getCustomerNames();
	}, [subDimensionID]);

	useEffect(() => {
		setMessage(itemCountMessage);
	}, [itemCountMessage]);

	useEffect(() => {
		if (Object.keys(submitData).length === 0) {
			setSelectedKeys([-100, ...selectableOptions.map((o) => o.key)]);
			setParamCustomerCodes([...selectableOptions.map((o) => o.customerCode)]);
		} else {
			setSelectedKeys(submitData.SelectedCustomers);
			setParamCustomerCodes(submitData.paramCustomerCodes);
		}
	}, [customerOptions]);

	const comboBoxStyles = {
		root: { width: 240 },
		optionsContainer: { maxHeight: 302 }
	};

	const getCustomerNames = async () => {
		dispatch(showLoader());
		await getCustomers(subDimensionID)
			.then((res) => {
				const data = res.data.map((i, index) => ({
					key: index,
					text: `${i.customerName}-${i.customerCode}`,
					customerCode: `${i.customerCode}`
				}));

				const uniqueNames = data.reduce((acc, current) => {
					const x = acc.find((item) => item.text === current.text);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, []);

				uniqueNames.sort((a, b) => (a.text > b.text ? 1 : -1));

				const options = [
					{
						key: 'Header',
						text: 'Customer Names',
						itemType: SelectableOptionMenuItemType.Header
					},
					{
						key: -100,
						text: 'Select All',
						itemType: SelectableOptionMenuItemType.SelectAll
					},
					...uniqueNames
				];
				setCustomerOptions(options);
				setCurrentCustomerOptions(options);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	const selectableOptions = customerOptions.filter(
		(option) =>
			(option.itemType === SelectableOptionMenuItemType.Normal ||
				option.itemType === undefined) &&
			!option.disabled
	);

	const handleClear = () => {
		setMessage('Data cleared');
		setParams({
			DocumentStatus: 'Completed',
			PrintStatus: 'Unprinted',
			DocumentDateFrom: null,
			DocumentDateTo: null,
			DocumentNumberFrom: '',
			DocumentNumberTo: ''
		});
		setDocumentNumberFrom('');
		setDocumentNumberTo('');
		setSelectedKeys([-100, ...selectableOptions.map((o) => o.key)]);
		setParamCustomerCodes([...selectableOptions.map((o) => o.customerCode)]);
		setCurrentCustomerOptions(customerOptions);
		setSubmitData({});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let k = params;
		let customerCodes = paramCustomerCodes;
		let p = customerCodes.join();

		k.DocumentNumberFrom = documentNumberFrom;
		k.DocumentNumberTo = documentNumberTo;
		k.SelectedCustomers = selectedKeys;
		k.paramCustomerCodes = paramCustomerCodes;

		if (selectedKeys[0] === -100) {
			if (paramCustomerCodes.length !== 0) {
				k.CustomerCodes = 'All';
				setSubmitData(k);
				setError(false);
				setSubmitBtnClicked(!submitBtnClicked);
				setCurrentPageExcludeList([]);
				setAllExcludeList([]);
				setShowTable(true);
			} else {
				setError(true);
			}
		} else {
			if (paramCustomerCodes.length !== 0) {
				k.CustomerCodes = `${p}`;
				setSubmitData(k);
				setError(false);
				setSubmitBtnClicked(!submitBtnClicked);
				setCurrentPageExcludeList([]);
				setAllExcludeList([]);
				setShowTable(true);
			} else {
				setError(true);
			}
		}
	};

	const onChange = (event, option, index, value) => {
		if (event._reactName !== 'onBlur') {
			const selected = option?.selected;
			const currentSelectedOptionKeys = selectedKeys.filter(
				(key) => key !== -100
			);
			const selectAllState =
				currentSelectedOptionKeys.length === selectableOptions.length;

			if (option) {
				if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
					if (selectAllState) {
						setSelectedKeys([]);
						setParamCustomerCodes([]);
					} else {
						setSelectedKeys([-100, ...selectableOptions.map((o) => o.key)]);
						setParamCustomerCodes([
							...selectableOptions.map((o) => o.customerCode)
						]);
					}
				} else {
					let updatedKeys = [];
					if (selected) {
						updatedKeys =
							(customerOptions, [...currentSelectedOptionKeys, option.key]);
						setParamCustomerCodes([...paramCustomerCodes, option.customerCode]);
					} else {
						updatedKeys = currentSelectedOptionKeys.filter(
							(k) => k !== option.key
						);
						let c = paramCustomerCodes;
						let m = c.filter((k) => k !== option.customerCode);
						setParamCustomerCodes(m);
					}
					if (updatedKeys.length === selectableOptions.length) {
						updatedKeys.push(-100);
						setParamCustomerCodes([
							...selectableOptions.map((o) => o.customerCode)
						]);
					}
					setSelectedKeys(updatedKeys);
				}
			}
		}
	};

	const documentOptions = [
		{ key: 'Completed', text: 'Completed' },
		{ key: 'Pending', text: 'Pending' },
		{ key: 'Cancelled', text: 'Cancelled' },
		{ key: 'Void', text: 'Void' }
	];

	const printOptions = [
		{ key: 'Unprinted', text: 'Unprinted' },
		{ key: 'Printed', text: 'Printed' }
	];

	const onChangeDocumentStatus = (event, item) => {
		let k = params;
		k.DocumentStatus = item.text;
		setParams(k);
	};

	const onChangePrintStatus = (event, item) => {
		let k = params;
		k.PrintStatus = item.text;
		setParams(k);
	};

	const onChangeInvoiceFrom = (event, item) => {
		setDocumentNumberFrom(item);
	};

	const onChangeInvoiceTo = (event, item) => {
		setDocumentNumberTo(item);
	};

	const onFormatDateField = (date) => {
		return !date
			? ''
			: date.getDate() +
					' ' +
					months[date.getMonth()] +
					' ' +
					date.getFullYear();
	};

	const onChangeStartDate = (event) => {
		let k = params;
		k.DocumentDateFrom = event;
		setParams(k);
	};

	const onChangeEndDate = (event) => {
		let k = params;
		k.DocumentDateTo = event;
		setParams(k);
	};

	const filterCustomerOptions = (option, index, value) => {
		if (value !== currentTyped && typeof value !== 'undefined') {
			if (value !== '') {
				const filteredOptions = customerOptions.filter((name) =>
					name.text.toLowerCase().includes(value.toLowerCase())
				);
				setCurrentCustomerOptions(filteredOptions);
			} else {
				setCurrentCustomerOptions(customerOptions);
			}
			setCurrentTyped(value);
		}
	};

	const resetCustomerOptions = () => {
		setCurrentCustomerOptions(customerOptions);
	};

	const dropdownStyles = { dropdown: { width: 133 } };
	const datePickerStyles = { root: { width: 122 } };
	const textStyles = { root: { width: 137 } };

	return (
		<form onSubmit={handleSubmit} className='documents-form'>
			<Stack horizontal horizontalAlign='space-between' wrap>
				<ComboBox
					placeholder='Type or Select Customer Name'
					label='Customer Name'
					multiSelect
					options={currentCustomerOptions}
					allowFreeform
					autoComplete='off'
					onChange={onChange}
					onPendingValueChanged={filterCustomerOptions}
					onMenuDismissed={resetCustomerOptions}
					selectedKey={selectedKeys}
					errorMessage={error ? 'Please provide a name' : ''}
					styles={comboBoxStyles}
					required
				/>
				<Dropdown
					label='Document Status'
					defaultSelectedKey={params.DocumentStatus}
					options={documentOptions}
					styles={dropdownStyles}
					required
					onChange={onChangeDocumentStatus}
				/>
				<Dropdown
					label='Printing Status'
					defaultSelectedKey={params.PrintStatus}
					options={printOptions}
					styles={dropdownStyles}
					required
					onChange={onChangePrintStatus}
				/>
				<DatePicker
					label='Start Date'
					placeholder='Select a date'
					ariaLabel='Select a start date'
					strings={defaultDatePickerStrings}
					onSelectDate={onChangeStartDate}
					value={params.DocumentDateFrom}
					styles={datePickerStyles}
					formatDate={onFormatDateField}
				/>

				<DatePicker
					label='End Date'
					placeholder='Select a date'
					ariaLabel='Select an end date'
					onSelectDate={onChangeEndDate}
					value={params.DocumentDateTo}
					strings={defaultDatePickerStrings}
					styles={datePickerStyles}
					formatDate={onFormatDateField}
				/>
				<TextField
					label='Credit Memo From'
					onChange={onChangeInvoiceFrom}
					styles={textStyles}
					value={documentNumberFrom}
				/>
				<TextField
					label='Credit Memo To'
					onChange={onChangeInvoiceTo}
					styles={textStyles}
					value={documentNumberTo}
				/>
			</Stack>
			<div className='form-btns'>
				<LiveAnnouncer>
					<LiveMessage message={message} aria-live='polite' />
					<PrimaryButton className='form-btn' text='Submit' type='submit' />
					<DefaultButton
						className='form-btn'
						text='Clear'
						onClick={handleClear}
					/>
				</LiveAnnouncer>
			</div>
		</form>
	);
};

export default DocumentsFormCredit;
