
import { ACTION_TYPE, USER_INITIAL_STATE } from "../constants";

const userReducer = (state = USER_INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.STORE_USER_SUCCESS: {
            const userData = action.payload;
            return {
                ...state,
                user: userData,
              };
        }
        default:
      return state;
    }
};

export default userReducer;


