import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as LogIcon } from '../../assets/icons/log.svg';
import { ReactComponent as GuiIcon } from '../../assets/icons/gui-number.svg';
import { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

import { useHistory, withRouter } from 'react-router-dom';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import { ROUTES } from '../../constants';
import { useSelector } from 'react-redux';
import { CoherenceNav } from '@coherence-design-system/controls';
import { registerIcons } from '@fluentui/react/lib/Styling';
import s from './styles.module.scss';
import classNames from 'classnames';

function Sidebar() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [isOperation, setIsOperation] = useState(false);
	const [isFinance, setIsFinance] = useState(false);
	const [selectedMessage, setSelectedMessage] = useState('');

	const [docSelected, setDocselected] = useState(true);
	const [custSelected, setCustselected] = useState(false);
	const [logSelected, setLogselected] = useState(false);
	const [guiSelected, setGuiselected] = useState(false);
	const [userSelected, setUserselected] = useState(false);

	const [selectedTab, setselectedTab] = useState('');

	const [selectedDocArialabl, setselectedDocArialabl] = useState('Documents menu tab');
	const [selectedCustomerArialabl, setselectedCustomerArialabl] = useState('Customers menu tab');
	const [selectedLogArialabl, setselectedLogArialabl] = useState('Logs menu tab');
	const [selectedGuiArialabl, setselectedGuiArialabl] = useState('Gui number menu tab');
	const [selectedUserRoleArialabl, setselectedUserRoleArialabl] = useState('User role menu tab');

	const [myRoles, setMyRoles] = useState([]);
	const upstream = useSelector((state) => state.upstream);
	const user = useSelector((state) => state.user);
	const subDimensionID = user?.user?.userList?.subsDimensionID;
	const currentUpstream = upstream?.upstreams?.find(
		(x) => x.selected
	)?.upstreamName;

	useEffect(() => {
		const roleBucket = [];
		user?.user?.userRoles?.map((item) => roleBucket.push(item.roleName));
		setMyRoles(roleBucket);
		if(localStorage.getItem('menuTab')==="Documents"){
			setselectedCustomerArialabl('Customer menu tab');
			setselectedDocArialabl('Document menu tab selected');
			setselectedGuiArialabl('Gui number menu tab');
			setselectedLogArialabl('Log menu tab');
			setselectedUserRoleArialabl('User role menu tab');

			setDocselected(true);
			setCustselected(false);
			setLogselected(false);
			setGuiselected(false);
			setUserselected(false);
		}
		else if(localStorage.getItem('menuTab')==='Customers'){
			setselectedCustomerArialabl('Customer menu tab selected');
			setselectedDocArialabl('Document menu tab');
			setselectedGuiArialabl('Gui number menu tab');
			setselectedLogArialabl('Log menu tab');
			setselectedUserRoleArialabl('User role menu tab');

			setDocselected(false);
			setCustselected(true);
			setLogselected(false);
			setGuiselected(false);
			setUserselected(false);
		}
		else if(localStorage.getItem('menuTab')==="Logs"){
			setselectedCustomerArialabl('Customer menu tab');
			setselectedDocArialabl('Document menu tab');
			setselectedGuiArialabl('Gui number menu tab');
			setselectedLogArialabl('Log menu tab selected');
			setselectedUserRoleArialabl('User role menu tab');

			setDocselected(false);
			setCustselected(false);
			setLogselected(true);
			setGuiselected(false);
			setUserselected(false);
		}
		else if(localStorage.getItem('menuTab')==="GUI Number"){
			setselectedCustomerArialabl('Customer menu tab');
			setselectedDocArialabl('Document menu tab');
			setselectedGuiArialabl('Gui number menu tab selected');
			setselectedLogArialabl('Log menu tab');
			setselectedUserRoleArialabl('User role menu tab');

			setDocselected(false);
			setCustselected(false);
			setLogselected(false);
			setGuiselected(true);
			setUserselected(false);
		}
		else if(localStorage.getItem('menuTab')==="User Roles"){
			setselectedCustomerArialabl('Customer menu tab');
			setselectedDocArialabl('Document menu tab');
			setselectedGuiArialabl('Gui number menu tab');
			setselectedLogArialabl('Log menu tab');
			setselectedUserRoleArialabl('User role menu tab selected');

			setDocselected(false);
			setCustselected(false);
			setLogselected(false);
			setGuiselected(false);
			setUserselected(true);
		}
		
	}, [user]);

	const taiwanGroup = [
		{
			key: 'group1',
			links: []
		}
	];

	let koreaGroup = [
		{
			key: 'group1',
			links: []
		}
	];

	let docs = {
		name: 'Documents',
		icon: 'file',
		key: 'documents',
		ariaLabel: selectedDocArialabl,
		isSelected: docSelected,
		onClick: () => handleClick(ROUTES.documents, 'Documents')
	};

	let customers = {
		name: 'Customers',
		icon: 'customers',
		key: 'customers',
		ariaLabel: selectedCustomerArialabl,
		isSelected: custSelected,
		onClick: () => handleClick(ROUTES.customers, 'Customers')
	};

	let logs = {
		name: 'Logs',
		icon: 'log',
		key: 'logs',
		ariaLabel: selectedLogArialabl,
		isSelected: logSelected,
		onClick: () => handleClick(ROUTES.logs, 'Logs')
	};

	let gui = {
		name: 'GUI Number',
		icon: 'gui',
		key: 'gui',
		ariaLabel: selectedGuiArialabl,
		isSelected: guiSelected,
		onClick: () => handleClick(ROUTES.guiNumbers, 'GUI Number')
	};

	let users = {
		name: 'User Roles',
		icon: 'roles',
		key: 'roles',
		ariaLabel: selectedUserRoleArialabl,
		isSelected: userSelected,
		onClick: () => handleClick(ROUTES.userRoles, 'User Roles')
	};

	function handleNavLinks() {
		if (
			myRoles.includes('Operation') ||
			myRoles.includes('Finance') ||
			myRoles.includes('OOW')
		) {
			koreaGroup[0]?.links?.unshift(docs);
			taiwanGroup[0]?.links?.unshift(docs);
		}
		if (myRoles.includes('Operation')) {
			koreaGroup[0]?.links?.push(customers);
			taiwanGroup[0]?.links?.push(customers);

			koreaGroup[0]?.links?.push(logs);
			taiwanGroup[0]?.links?.push(logs);
		}
		if (myRoles.includes('Finance')) {
			// no gui in korea
			taiwanGroup[0]?.links?.push(gui);
		}
		if (myRoles.includes('Admin')) {
			koreaGroup[0]?.links?.push(users);
			taiwanGroup[0]?.links?.push(users);
		}

		let navLinkGroups = subDimensionID == 1 ? taiwanGroup : koreaGroup;
		if (currentUpstream !== 'SAP') {
			navLinkGroups[0].links = navLinkGroups[0].links.filter(
				(x) => x.key !== 'customers'
			);
		}
		return navLinkGroups;
	}

	const getUserRoles = async () => {
		user?.userRoles?.map((x) => {
			if (x.roleName === 'Admin') {
				setIsAdmin(true);
				let isAdminn = true;
			}
			if (x.roleName === 'Operation') {
				setIsOperation(true);
			}
			if (x.roleName === 'Finance') {
				setIsFinance(true);
			}
		});
	};

	useEffect(() => {
		getUserRoles();
	}, []);
	useEffect(() => {}, [isAdmin, isOperation, isFinance]);

	const history = useHistory();

	const handleClick = useCallback(
		(path, name) => {
			history.push(path);
			// setSelectedMessage(`${name} selected`);
			// debugger;
			setselectedTab(name);
			localStorage.setItem('menuTab',name);
			if(name==="Documents"){
				setselectedCustomerArialabl('Customer menu tab');
				setselectedDocArialabl('Document menu tab selected');
				setselectedGuiArialabl('Gui number menu tab');
				setselectedLogArialabl('Log menu tab');
				setselectedUserRoleArialabl('User role menu tab');

				setDocselected(true);
				setCustselected(false);
				setLogselected(false);
				setGuiselected(false);
				setUserselected(false);
			}
			else if(name==='Customers'){
				setselectedCustomerArialabl('Customer menu tab selected');
				setselectedDocArialabl('Document menu tab');
				setselectedGuiArialabl('Gui number menu tab');
				setselectedLogArialabl('Log menu tab');
				setselectedUserRoleArialabl('User role menu tab');

				setDocselected(false);
				setCustselected(true);
				setLogselected(false);
				setGuiselected(false);
				setUserselected(false);
			}
			else if(name==="Logs"){
				setselectedCustomerArialabl('Customer menu tab');
				setselectedDocArialabl('Document menu tab');
				setselectedGuiArialabl('Gui number menu tab');
				setselectedLogArialabl('Log menu tab selected');
				setselectedUserRoleArialabl('User role menu tab');

				setDocselected(false);
				setCustselected(false);
				setLogselected(true);
				setGuiselected(false);
				setUserselected(false);
			}
			else if(name==="GUI Number"){
				setselectedCustomerArialabl('Customer menu tab');
				setselectedDocArialabl('Document menu tab');
				setselectedGuiArialabl('Gui number menu tab selected');
				setselectedLogArialabl('Log menu tab');
				setselectedUserRoleArialabl('User role menu tab');

				setDocselected(false);
				setCustselected(false);
				setLogselected(false);
				setGuiselected(true);
				setUserselected(false);
			}
			else if(name==="User Roles"){
				setselectedCustomerArialabl('Customer menu tab');
				setselectedDocArialabl('Document menu tab');
				setselectedGuiArialabl('Gui number menu tab');
				setselectedLogArialabl('Log menu tab');
				setselectedUserRoleArialabl('User role menu tab selected');

				setDocselected(false);
				setCustselected(false);
				setLogselected(false);
				setGuiselected(false);
				setUserselected(true);
			}
		},
		[history]
	);

	registerIcons({
		icons: {
			file: <FileIcon />,
			log: <LogIcon />,
			gui: <GuiIcon />,
			customers: <CustomerIcon />,
			roles: <UserIcon />
		}
	});

	const navStyles = {

		root: {
			height: '100%',
			position: 'static',
			overflow: 'hidden'
		},
		showNav: {
			zIndex: '10000',
			height: '100%'
		}
	};

	return (
		<LiveAnnouncer>
			<LiveMessage message={selectedMessage} aria-live='polite' />
			<div className={s.navBorder}>
				<CoherenceNav
				defaultIsNavCollapsed={true}
				appName={'Korea - Invoice Print Tool'}
				groups={handleNavLinks()}
				toggleNavAriaLabel={'Hamburger menu'}
				toggleNavTooltip={{
					expand: 'Expand navigation',
					collapse: 'Collapse navigation'
				}}
				styles={navStyles}
			/>
			</div>
			
		</LiveAnnouncer>
	);
}

export default withRouter(Sidebar);
