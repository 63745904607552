import { DefaultButton, Dropdown, Separator, Stack } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import s from './styles.module.scss';

const options = [
	{ key: '2021', text: '2021' },
	{ key: '2020', text: '2020' },
	{ key: '2019', text: '2019' }
];

function ActionBar({ years, year, onChange, onAdd, addNewBtnMessage }) {
	return (
		<Stack className={s.root} horizontal={false} verticalFill>
			<Stack
				className={s.actionContainer}
				horizontal
				horizontalAlign={'space-between'}
				wrap
			>
				<Dropdown
					className='ms-lg2 ms-sm5'
					label='Select Year'
					onChange={(e, option) => {
						onChange(option);
					}}
					placeholder='Select an option'
					options={years}
					selectedKey={`${year}`}
				/>
				<LiveAnnouncer>
					<LiveMessage message={addNewBtnMessage} aria-live='polite' />
					<DefaultButton
						role='button'
						aria-label='Add New year'
						className={s.button}
						onClick={onAdd}
					>
						<PlusIcon />
						Add New Year
					</DefaultButton>
				</LiveAnnouncer>
			</Stack>
			<Separator className={s.separator} />
		</Stack>
	);
}

export default ActionBar;
